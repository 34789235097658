/* eslint-disable camelcase */
import axios from 'axios';

const qs = require('qs');

export default {
  async LOAD_user({ commit, state }) {
    const { userId } = state;
    if (!userId) {
      return undefined;
    }
    const { data } = await axios.get(`api/account/user/${userId}`);
    commit('SET_user', data);
    return data;
  },
  async UPDATE_user({ commit, dispatch }, data) {
    const result = await axios.put('api/account/user', data);
    commit('SET_user', result.data);
    dispatch('LOAD_user');
    return result;
  },
  // eslint-disable-next-line no-unused-vars
  async CHECK_chatUsername({ commit }, username) {
    const { data } = await axios.get(`api/account/chat-username-available/${username}`);
    return data;
  },
  INIT_auth({ commit }, userOptions) {
    const defaults = {
      client_id: '',
      client_secret: '',
    };
    const options = Object.assign(defaults, userOptions);
    if (options.url.length === 0) {
      throw options;
    }
    commit('SET_options', options);
  },
  async LOGIN_auth({ commit, state }, data) {
    const { username, password } = data;
    const { client_id, client_secret } = state.options;
    const result = await this.$http.post('/token', qs.stringify({
      username,
      password,
      client_id,
      client_secret,
      grant_type: 'password',
    }));

    commit('SET_token', result.data.token);
    commit('SET_username', result.data.userName);
    commit('SET_roles', result.data.roles ?? '');
    commit('SET_refresh_token', result.data.refresh_token);
    commit('SET_token_expires', result.data['.expires']);
    commit('SET_token_expires_in', result.data.expires_in);
    commit('SET_userId', result.data.userId);
    commit('SET_claims', result.data.claims);
    return result.data;
  },
  // eslint-disable-next-line no-unused-vars
  async REGISTER_auth({ state }, data) {
    const {
      displayName, email, password, birthDate, name, surname, province, country,
    } = data;
    const result = await this.$http.post('/api/account/register', qs.stringify({
      DisplayName: displayName,
      Email: email,
      FirstName: name,
      LastName: surname,
      Password: password,
      BirthDate: birthDate,
      Province: province,
      Country: country,
    }));
    return result;
  },
  async REFRESH_auth({ commit, state }) {
    // REFRESH_auth started
    // eslint-disable-next-line no-unused-vars
    const { token_expires, last_token_refresh_request } = state;
    // const date1 = new Date(token_expires);
    const date2 = new Date();
    // const expires = Math.abs(date2 - date1);
    const lastRefresh = Math.abs(new Date(last_token_refresh_request) - date2);
    // check if token expires in the next 10 minutes
    // and no other request was made in the last 3 seconds
    if (lastRefresh > 5000) {
      const { client_id, client_secret } = state.options;
      const { refresh_token } = state;
      const result = await this.$http.post('/token', qs.stringify({
        grant_type: 'refresh_token',
        refresh_token,
        client_id,
        client_secret,
      }));

      commit('SET_token', result.data.access_token);
      commit('SET_refresh_token', result.data.refresh_token);
      commit('SET_token_expires', result.data['.expires']);
      commit('SET_token_expires_in', result.data.expires_in);
      commit('SET_last_token_refresh_request', new Date());
    }
  },
  LOGOUT_auth({ commit }) {
    commit('SET_token', undefined);
    commit('SET_username', undefined);
    commit('SET_roles', '');
    commit('SET_refresh_token', undefined);
    commit('SET_token_expires', undefined);
    commit('SET_token_expires_in', undefined);
    commit('SET_userId', undefined);
  },
  async CHANGE_PASSWORD_auth({ commit }, data) {
    const result = await this.$http.post('/api/account/reset-password', {
      email: data.email,
      token: data.token,
      password: data.password,
    });
    commit('SET_token', result.data.token);
    return result;
  },
  // eslint-disable-next-line no-unused-vars
  async FORGOT_PASSWORD_auth({ commit }, localEmail) {
    const result = await this.$http.post('/api/account/forgotpassword', {
      Email: localEmail,
    });
    return result;
  },
  // eslint-disable-next-line no-unused-vars
  async ADMIN_REGISTER_auth({ commit }, data) {
    // strange quirk where the baseURL is not persisted
    const { username, email, password } = data;
    const result = await this.$http.post('/join/admin-register', {
      phoneNumber: username,
      email,
      password,
    });
    return result;
  },
};
