<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-autocomplete
          v-model="selectedCountry"
          dense
          :items="countryList"
          label="Select a country"
          item-text="name"
          item-value="name"
          outlined
          return-object
          @change="countryChosen"
          v-bind="attrs"
          v-on="on"
        ></v-autocomplete>
      </template>
      <span>We ask for your country to understand where all our listeners are from</span>
    </v-tooltip>
    <v-autocomplete
      v-if="selectedCountry === 'South Africa'"
      v-model="selectedProvince"
      dense
      :items="provinceList"
      outlined
      label="Select a Province"
      @change="provinceChosen"
    ></v-autocomplete>
  </div>
</template>

<script>
import countries from 'countries-list/dist/countries.json';

export default {
  name: 'RegionSelector.vue',
  mounted() {
    this.countryList = Object.keys(countries).map((key) => countries[key].name);
  },
  data() {
    return {
      countries,
      countryList: [],
      provinceList: [
        'Eastern Cape',
        'Free State',
        'Gauteng',
        'KwaZulu-Natal',
        'Limpopo',
        'Mpumalanga',
        'Northern Cape',
        'North West',
        'Western Cape',
      ],
      selectedCountry: '',
      selectedProvince: '',
    };
  },
  methods: {
    countryChosen() {
      // Emit the selected country
      this.$emit('country-selected', this.selectedCountry);
    },
    provinceChosen() {
      // Emit the selected province
      this.$emit('province-selected', this.selectedProvince);
    },
  },
};
</script>
