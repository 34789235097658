export default {
  SET_options(state, value) {
    state.options = value;
  },
  SET_token(state, value) {
    state.token = value;
  },
  SET_userId(state, value) {
    state.userId = value;
  },
  SET_username(state, value) {
    state.username = value;
  },
  SET_refresh_token(state, value) {
    state.refresh_token = value;
  },
  SET_token_expires_in(state, value) {
    state.token_expires_in = value;
  },
  SET_last_token_refresh_request(state, value) {
    state.last_token_refresh_request = value;
  },
  SET_token_expires(state, value) {
    state.token_expires = value;
  },
  SET_claims(state, value) {
    state.claims = value;
  },
  SET_roles(state, value) {
    if (value) {
      state.roles = value?.split(',');
    } else {
      state.roles = [];
    }
  },
  SET_user(state, value) {
    state.user = value;
  },
};
