<template>
  <v-row class="fill-height">
  <v-col>
    <v-sheet height="64">
      <v-toolbar
        flat
      >
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
        >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            {{ mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            {{ mdiChevronRight }}
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          Event Schedule
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                {{ mdiMenuDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="100vh">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="calendarEvents"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @click:time="create"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card
          color="grey lighten-4"
          min-width="350px"
          flat
        >
          <v-toolbar
            :color="selectedEvent.color"
          >
            <v-btn icon>
              <v-icon>{{ mdiPencil }}</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>{{ mdiHeart }}</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details"></span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="selectedOpen = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <EventScheduleModal
        :event="event"
        :dialog="editDialog"
        formTitle="Edit Broadcast Schedule"
        @close="editDialog = false"
        @save="saveEvent"
      />
    </v-sheet>
  </v-col>
</v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiHeart, mdiPencil, mdiChevronLeft, mdiChevronRight, mdiMenuDown,
} from '@mdi/js';
import EventScheduleModal from './EventScheduleModal.vue';

export default {
  components: { EventScheduleModal },
  name: 'EventsCalendar',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiDotsVertical,
      mdiHeart,
      mdiPencil,
      mdiChevronLeft,
      mdiChevronRight,
      mdiMenuDown,
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      event: {},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      editDialog: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    };
  },
  computed: {
    ...mapState('scheduledEvents', ['scheduledEvents']),
    /*
   * Returns the formatted event for the calendar
   */
    calendarEvents() {
      return this.events.map((event) => ({
        ...event,
        start: this.formatDateTime(event.start),
        end: this.formatDateTime(event.end),
        color: 'blue',
      }));
    },
  },
  async mounted() {
    await this.refresh();
    this.$refs.calendar.checkChange();
  },
  watch: {
    '$vuetify.breakpoint.mobile': {
      handler(newVal) {
        this.setTypeBasedOnBreakpoint(newVal);
      },
      immediate: true, // Trigger the handler immediately when the component is created
    },
  },
  methods: {
    ...mapActions('scheduledEvents', [
      'LOAD_scheduledEvents',
      'CREATE_scheduledEvent',
      'UPDATE_scheduledEvent',
      'DELETE_scheduledEvent',
    ]),
    setTypeBasedOnBreakpoint(isMobile) {
      this.type = isMobile ? 'day' : 'week';
    },
    /*
   * Create a new event and open the edit dialog
   */
    create({ date, time }) {
    // Don't allow creating events if the component is disabled
      if (this.disabled) return;

      this.event = {
        start: `${date} ${time}`,
        end: `${date} ${time}`,
      };
      this.editDialog = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    /*
   * Refresh the events array from the api
   */
    async refresh() {
      await this.LOAD_scheduledEvents();
      this.events = this.scheduledEvents.map((event) => ({
        name: event.eventName,
        details: event.summary,
        start: event.eventDateStart,
        end: event.eventDateEnd,
        color: event.color,
      }));
    },
    /*
   * Save the event to the api
   */
    async saveEvent(event) {
      await this.CREATE_scheduledEvent(event);
      await this.refresh();
      this.$refs.calendar.checkChange();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
      // requestAnimationFrame(requestAnimationFrame(this.selectedOpen = true));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
      // requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>
