<template>
  <div>
    <v-text-field
      close-on-content-click="false"
      v-model="currentValue"
      @input="updateValue"
      @click="timePicker = true"
      v-bind="$props"
      v-on="$listeners"
      v-mask="mask"
    >
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
    <v-menu
      v-model="timePicker"
      transition="scale-transition"
      offset-y
    >
      <v-time-picker
        v-model="currentValue"
        @input="updateValue"
        no-title
        format="24hr"
      />
    </v-menu>
  </div>
</template>

<script>
/**
 * A Vuetify component that includes a text field for inputting time
 * in the format "HH:MM", and a time picker for selecting values.
 *
 * @component
 */
import { VTextField } from 'vuetify/lib';

export default {
  name: 'TimePicker',
  extends: VTextField,
  data() {
    return {
      timePicker: false,
      currentValue: this.value,
    };
  },
  watch: {
    /**
     * Update the current value when the value changes.
     */
    value(newValue) {
      this.updateValue(newValue);
    },
  },
  methods: {
    /**
     * Update the value of the text field and emit an `input` event with the new value.
     *
     * @param {String} newValue - The new value of the text field.
     */
    updateValue(newValue) {
      this.currentValue = newValue;
      this.$emit('input', this.currentValue);
    },
  },
  computed: {
    /**
     * The mask to use for the text field.
     *
     * @returns {String} The mask string.
     */
    mask() {
      return '##:##';
    },
  },
};
</script>
