<template>
  <v-container fluid>
    <v-card v-if="article">
      <v-card-title primary-title>
        {{ article.title }}
      </v-card-title>
      <v-card-subtitle primary-title>
        {{ article.subtitle }}
      </v-card-subtitle>
      <v-card-text v-html="article.content" />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

/**
 * Vue component representing an article.
 * @name Article
 * @component
 */
export default {
  /**
   * Data properties of the component.
   * @returns {object} Object containing the properties of the component.
   */
  data() {
    return {
      /**
       * The article object.
       * @type {object|undefined}
       */
      article: undefined,
      /**
       * The ID of the article to load.
       * @type {string}
       */
      id: this.$route.query.id,
    };
  },
  /**
   * Lifecycle hook called when the component is mounted.
   */
  async mounted() {
    await this.refresh();
  },
  /**
   * Methods of the component.
   */
  methods: {
    /**
     * Vuex mapActions helper to load an article by ID.
     * @method LOAD_article
     * @memberof Article
     */
    ...mapActions('articles', ['LOAD_article']),
    /**
     * Refreshes the article data by loading it from the API.
     * @method refresh
     * @memberof Article
     * @async
     */
    async refresh() {
      this.article = await this.LOAD_article(this.id);
    },
  },
};

</script>
