<template>
  <div class="stream-container">
    <div class="video" v-if="purchase">
      <div v-if="isPlayAudio && !audioOverride">
        <v-alert type="info">
          <span>Audio is playing, click the play button below to play the stream</span>
        </v-alert>
        <v-btn color="success" @click="audioOverride = true">
          <v-icon class="mr-2">{{ mdiPlayCircle }}</v-icon>
          Play
        </v-btn>
      </div>
      <VideoPlayer v-if="!isPlayAudio || audioOverride" :url="purchase.streamUrl" />
      <div v-if="!isPlayAudio || audioOverride" class="video-button" >
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          @click="togglePanel"
          >
          <v-icon
            v-if="!panelVisible">
            {{ mdiArrowExpandLeft }}
          </v-icon>
          <v-icon v-else>{{ mdiArrowExpandRight }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiEmoticon,
  mdiArrowRightCircle,
  mdiCloseCircle,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiPlayCircle,
} from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import CastrVideoPlayer from '../components/CastrVideoPlayer.vue';

export default {
  name: 'Stream',
  module: 'Stream',
  components: {
    VideoPlayer: CastrVideoPlayer,
  },
  computed: {
    ...mapState('app', ['isPlayAudio']),
    ...mapState('purchases', ['purchase']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    screenSize() {
      if (this.$vuetify.breakpoint.mobile) {
        return 12;
      }
      return 8;
    },
  },
  data() {
    return {
      mdiArrowRightCircle,
      mdiEmoticon,
      mdiCloseCircle,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      mdiPlayCircle,
      audioOverride: false,
      marker: true,
      showEmoji: false,
      panelVisible: true,
      user: '',
      text: '',
      connection: null,
      menuOpen: false,
      offset: true,
    };
  },
  async mounted() {
    await this.LOAD_purchase(this.$route.query.purchaseId);
  },
  methods: {
    ...mapActions('purchases', ['LOAD_purchase']),
    togglePanel() {
      this.panelVisible = !this.panelVisible;
    },
  },
};
</script>
<style>
  /* .stream-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .video-button {
    position: absolute;
    top: 50px;
    right: 5px;
    margin: 12px;
    opacity: 0.8;
  }
  .video {
    flex: 5;
    padding: 12px;
    height: 100%;
    position: relative;
  }
  .users {
    flex: 1;
    padding: 12px;
    height: 100%;
  }
  @media (max-width: 767px) {
    .stream-container {
      display: flex;
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      height: 100%;
    }
    .video {
      order: 1;
      flex-grow: 0;
    }
    .chat {
      order: 2;
    }
    .users {
      display: none;
    }
  } */
</style>
