<template>
  <iframe
    :src="url"
    width="100%"
    height="100%"
    frameborder="0"
    scrolling="no"
    allow="autoplay"
    allowfullscreen
    webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
</template>

<script>
export default {
  name: 'CastrVideoPlayer',
  props: {
    url: {
      type: String,
      default: 'https://player.castr.com/live_43bac4c0812511edb24427eebfba19c9',
    },
  },
};
</script>
