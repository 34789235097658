import axios from 'axios';

const actions = {
  async CREATE_scheduledEvent({ commit }, scheduledEvent) {
    const response = await axios.post('/api/scheduledEvents', scheduledEvent);
    commit('SET_scheduledEvent', response.data);
    return response.data;
  },

  async LOAD_scheduledEvent({ commit }, scheduledEventId) {
    const response = await axios.get(`/api/scheduledEvents/${scheduledEventId}`);
    commit('SET_scheduledEvent', response.data);
    return response.data;
  },

  async LOAD_scheduledEvents({ commit }) {
    const response = await axios.get('/api/scheduledEvents');
    commit('SET_scheduledEvents', response.data);
    return response.data;
  },

  async DELETE_scheduledEvent({ commit }, scheduledEventId) {
    const response = await axios.delete(`/api/scheduledEvents/${scheduledEventId}`);
    commit('SET_scheduledEvent', null);
    return response.data;
  },
  async UPDATE_scheduledEvent({ commit }, scheduledEvent) {
    const response = await axios.put(`/api/scheduledEvents/${scheduledEvent.id}`, scheduledEvent);
    commit('SET_scheduledEvent', response.data);
    return response.data;
  },
};

export default { ...actions };
