import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=275e6ae5"
import script from "./Chat.vue?vue&type=script&lang=js"
export * from "./Chat.vue?vue&type=script&lang=js"
import style0 from "./Chat.vue?vue&type=style&index=0&id=275e6ae5&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports