<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-btn @click="$router.push({ name: 'app-FeaturedEvents' })">
          Return to gallery
        </v-btn>
      </v-col>
    </v-row>
    <ImagesGallery :images="eventImages" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImagesGallery from '../components/ImagesGallery.vue';
import loading from '../mixins/loading';

/**
 * Event Images component for displaying and uploading images.
 * @vue/component
 */
export default {
  name: 'EventImages',
  mixins: [loading],
  components: {
    ImagesGallery,
  },
  computed: {
    /**
     * @type {import('vuex').Getter}
     */
    ...mapState('eventImages', ['eventImages']),
    /**
     * Base URL for the API.
     * @returns {string}
     */
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /**
     * @type {import('vuex').Action}
     */
    ...mapActions('eventImages', ['LOAD_eventImagesByEvent']),
    /**
     * Refreshes the list of event images.
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_eventImagesByEvent(this.$route.query.eventId);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.card1 {
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
</style>
