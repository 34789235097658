<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <ImageUpload/>
      </v-col>
    </v-row>
    <PhotoGallery />
  </v-container>
</template>

<script>
import ImageUpload from '../components/ImageUpload.vue';
import PhotoGallery from '../components/PhotoGallery.vue';

export default {
  name: 'Gallery',
  components: {
    ImageUpload,
    PhotoGallery,
  },
};
</script>
<style scoped>
.card1 {
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
</style>
