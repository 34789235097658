<template>
  <v-btn
    class="mx-2"
    fab
    x-small
    @click="$emit('click')"
    color="default"
  >
    <v-icon color="black">{{ mdiReply }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiReply } from '@mdi/js';

export default {
  name: 'ReplyButton',
  data() {
    return {
      mdiReply,
    };
  },
};
</script>
