<template>

  <SplitLayout :rightPanelVisible="panelVisible">
    <template v-slot:left>
      <div class="panel-container">
        <div v-if="isPlayAudio && !audioOverride">
          <v-alert type="info">
            <span>Audio is playing, click the play button below to play the stream</span>
          </v-alert>
          <v-btn color="success" @click="audioOverride = true" title="Cinema Mode">
            <v-icon class="mr-2">{{ mdiPlayCircle }}</v-icon>
            Play
          </v-btn>
        </div>
        <VideoPlayer v-if="!isPlayAudio || audioOverride">
          <div v-if="!isPlayAudio || audioOverride" class="video-button" >
            <v-btn
            v-if="!$vuetify.breakpoint.mobile"
            @click="togglePanel"
            >
              <v-icon
                v-if="!panelVisible">
                {{ mdiArrowExpandLeft }}
              </v-icon>
              <v-icon v-else>{{ mdiArrowExpandRight }}</v-icon>
            </v-btn>
          </div>
        </VideoPlayer>
      </div>
    </template>
    <template v-if="panelVisible" v-slot:right>
      <div class="panel-container">
        <div class="fill-height chat">
          <div class="chat-box">
            <Chat />
          </div>
        </div>
      </div>
    </template>
  </SplitLayout>
</template>

<script>
import {
  mdiEmoticon,
  mdiArrowRightCircle,
  mdiCloseCircle,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiPlayCircle,
} from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';
import SplitLayout from '../layouts/SplitLayout.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import Chat from '../components/Chat.vue';
import UsersWidget from '../components/UsersWidget.vue';

export default {
  name: 'Stream',
  module: 'Stream',
  components: {
    SplitLayout,
    VideoPlayer,
    Chat,
    // eslint-disable-next-line vue/no-unused-components
    UsersWidget,
  },
  computed: {
    ...mapState('app', ['isPlayAudio']),
    ...mapState('auth', ['userId', 'username', 'claims']),
    ...mapState('users', ['allUsers']),
    ...mapGetters('auth', ['hasClaim']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    screenSize() {
      if (this.$vuetify.breakpoint.mobile) {
        return 12;
      }
      return 8;
    },
  },
  data() {
    return {
      mdiArrowRightCircle,
      mdiEmoticon,
      mdiCloseCircle,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      mdiPlayCircle,
      audioOverride: false,
      marker: true,
      showEmoji: false,
      panelVisible: true,
      user: '',
      text: '',
      connection: null,
      menuOpen: false,
      offset: true,
    };
  },
  async mounted() {
    await this.LOAD_user();
  },
  methods: {
    ...mapActions('auth', ['LOAD_user']),
    togglePanel() {
      this.panelVisible = !this.panelVisible;
    },
  },
};
</script>
<style>
  .video-button {
    position: absolute;
    top: 25vh;
    right: 5px;
    margin: 12px;
    opacity: 0.2;
    transition: opacity 0.3s;
  }

  .video-button:hover {
    opacity: 0.8;
  }
  .video {
    flex: 5;
    padding: 12px;
    height: 100%;
    position: relative;
  }
  .chat {
    padding: 12px;
    border-radius: 25px;
    border-left: 4px solid #000000;
    border-top: 4px solid #000000;
    border-bottom: 4px solid #000000;
    border-right: 4px solid #000000;
    height: 100%;
    background-color: #f5f5f5;
  }

  .chat-box {
    height: 100%;
    overflow-y: auto;
  }

  .panel-container {
    height: 100%;
    padding: 0.8em;
  }

  @media screen and (max-width: 768px) {
    .video-button {
      display: none;
    }
    .panel-container {
      padding: 0.2em;
      /* @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
      }
      @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 20px) !important;
      } */
    }
  }
</style>
