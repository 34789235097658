<template>
  <AdminCard label="Manage the users and their permissions">
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="5"
    >
      <template v-slot:top>
        <ExportToExcel :data="users" fileName="users" />
      </template>
      <template v-slot:items="props">
        <td>{{ props.item.username }}</td>
        <td>{{ props.item.email }}</td>
        <td>
          <v-btn small @click="edit(props.item)">Edit</v-btn>
          <v-btn small color="error" @click="remove(props.item)">Delete</v-btn>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
        <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showEditDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedUser ? "Edit User" : "Create User" }}</span>
        </v-card-title>
        <v-card-text>
          <EditUser
            v-if="selectedUser"
            :user="selectedUser"
            @user-updated="userUpdated"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="showEditDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </AdminCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPencil, mdiDelete } from '@mdi/js';
import EditUser from '../modules/admin/components/EditUser.vue';
import ExportToExcel from './ExportToExcel.vue';
import loading from '../mixins/loading';

export default {
  name: 'AdminUsers',
  components: {
    EditUser,
    ExportToExcel,
  },
  mixins: [loading],
  computed: {
    ...mapState('manageUsers', ['users', 'claims']),
  },
  data() {
    return {
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selectedUser: null,
      showEditDialog: false,
      mdiDelete,
      mdiPencil,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('manageUsers', ['LOAD_users', 'LOAD_claims', 'DELETE_user']),
    /**
      * Refresh the user data
      */
    async refresh() {
      this.isLoading = true;
      try {
        await Promise.all([
          (async () => {
            await this.LOAD_claims();
          })(),
          (async () => {
            await this.LOAD_users();
          })(),
        ]);
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
      * Edit the user
      * @param {object} user - The user object to edit
      */
    edit(user) {
      this.selectedUser = user; // Set the selectedUser to the provided user
      this.showEditDialog = true; // Show the edit dialog
    },

    /**
      * Remove a user
      * @param {object} user - The user object to remove
      */
    async remove(user) {
      this.isLoading = true;
      const res = await this.$confirm('Are you sure you want to delete this user?');

      if (res) {
        try {
          await this.DELETE_user(user.id); // Dispatch DELETE_user action to delete the user
          this.refresh(); // Refresh the user data
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    /**
      * Handle user update
      * Called when the user is updated in the EditUser component
      */
    userUpdated() {
      this.refresh(); // Fetch the updated user data
      this.selectedUser = null; // Clear the selectedUser
      this.showEditDialog = false; // Hide the edit dialog
    },
  },
};
</script>
