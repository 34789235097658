<template>
    <div>
      <BroadcastCalendar :disabled="true"/>
    </div>
</template>

<script>
import BroadcastCalendar from '../components/BroadcastCalendar.vue';

export default {
  name: 'BroadcastSchedule',
  module: 'BroadcastSchedule',
  components: {
    BroadcastCalendar,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
