import axios from 'axios';

export default {
  async CREATE_purchase({ commit }, purchases) {
    const { data } = await axios.post('/api/purchases', purchases);
    commit('SET_purchase', data);
  },
  async LOAD_purchases({ commit }) {
    const { data } = await axios.get('/api/purchases');
    commit('SET_purchases', data);
  },
  async LOAD_purchase({ commit }, id) {
    const { data } = await axios.get(`/api/purchases/${id}`);
    commit('SET_purchase', data);
  },
  async UPDATE_purchase({ dispatch }, purchase) {
    await axios.put(`/api/purchases/${purchase.id}`, purchase);
    dispatch('LOAD_purchases');
  },
  async DELETE_purchase({ dispatch }, purchase) {
    await axios.delete(`/api/purchases/${purchase.id}`);
    dispatch('LOAD_purchases');
  },
};
