<template>
    <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              {{ mdiChevronLeft }}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
            Broadcast Schedule
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  {{ mdiMenuDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="100vh">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="calendarEvents"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @click:time="create"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
            >
              <v-btn icon>
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>{{ mdiHeart }}</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>{{ mdiDotsVertical }}</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <BroadcastScheduleModal
          :broadcastSchedule="selectedEvent"
          :event="event"
          :dialog="editDialog"
          formTitle="Edit Broadcast Schedule"
          @close="editDialog = false"
          @remove="remove"
          @refresh="refresh"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import {
  mdiDotsVertical, mdiHeart, mdiPencil, mdiChevronLeft, mdiChevronRight, mdiMenuDown,
} from '@mdi/js';
import BroadcastScheduleModal from './BroadcastScheduleModal.vue';

export default {
  components: { BroadcastScheduleModal },
  name: 'BroadcastCalendar',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiDotsVertical,
      mdiHeart,
      mdiPencil,
      mdiChevronLeft,
      mdiChevronRight,
      mdiMenuDown,
      type: 'week',
      focus: '',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      event: {},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      editDialog: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    };
  },
  computed: {
    ...mapState('broadcastSchedules', ['broadcastSchedules']),
    /*
     * Returns the formatted event for the calendar
     */
    calendarEvents() {
      return this.events.map((event) => ({
        ...event,
        start: this.formatDateTime(event.start),
        end: this.formatDateTime(event.end),
        color: 'blue',
      }));
    },

    events() {
      return this.broadcastSchedules.map((event) => ({
        id: event.id,
        name: event.name,
        start: event.start,
        end: event.end,
        color: event.color,
      }));
    },
  },
  async mounted() {
    await this.refresh();
    this.$refs.calendar.checkChange();
  },
  watch: {
    '$vuetify.breakpoint.mobile': {
      handler(newVal) {
        this.setTypeBasedOnBreakpoint(newVal);
      },
      immediate: true, // Trigger the handler immediately when the component is created
    },
  },
  methods: {
    ...mapActions('broadcastSchedules', [
      'LOAD_broadcastSchedules',
      'CREATE_broadcastSchedule',
      'UPDATE_broadcastSchedule',
      'DELETE_broadcastSchedule',
    ]),
    setTypeBasedOnBreakpoint(isMobile) {
      this.type = isMobile ? 'day' : 'week';
    },
    /*
     * Create a new event and open the edit dialog
     */
    create({ date, time }) {
      // Don't allow creating events if the component is disabled
      if (this.disabled) return;

      const dateTime = `${date} ${time}`;
      // const start = `${date} ${dayjs(time).format('HH:00:00')}`;
      // const end = `${date} ${dayjs(time).format('HH:00:00')}`;
      // set start to the rounded down nearest hour
      const start = dayjs(dateTime)
        .startOf('hour')
        .format('YYYY-MM-DD HH:mm:ss');
      // set end to the rounded up nearest hour
      const end = dayjs(dateTime).endOf('hour').format('YYYY-MM-DD HH:mm:ss');
      this.selectedEvent = {
        start,
        end,
      };
      this.editDialog = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async remove(id) {
      this.editDialog = false;
      const result = await this.$confirm('Are you sure you want to delete this schedule?');
      if (!result) return;
      await this.DELETE_broadcastSchedule(id);
      await this.refresh();
    },
    /*
     * Refresh the events array from the api
     */
    async refresh() {
      await this.LOAD_broadcastSchedules();
    },
    /*
     * Save the event to the api
     */
    showEvent({ nativeEvent, event }) {
      // check if disabled, if it is disabled, show event detail, otherwise show edit modal
      if (this.disabled) {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        return;
      }
      this.selectedEvent = event;
      this.editDialog = true;
      nativeEvent.stopPropagation();
    },
  },
};
</script>
