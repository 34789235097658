<template>
<div>
  <v-menu
    v-model="datePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
  >
    <template #activator="{ on }">
      <v-text-field
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        @input="updateValue"
        @click="openPicker"
        v-bind="$attrs"
        v-on="Object.assign({}, on, $listeners)"
        v-mask="mask"
      />
    </template>
    <v-date-picker
      v-model="currentValue"
      @input="updateValue"
    ></v-date-picker>
  </v-menu>
  <v-menu
    v-model="timePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
  >
    <template #activator="{ on }">
      <v-text-field
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        @input="updateValue"
        @click="openPicker"
        v-bind="$attrs"
        v-on="Object.assign({}, on, $listeners)"
        v-mask="mask"
      />
    </template>
    <v-time-picker
      v-model="currentValue"
      @input="updateValue"
    ></v-time-picker>
  </v-menu>
</div>
</template>

<script>
/**
 * A Vuetify component that includes a text field for inputting date and time
 * in the format "YYYY-MM-DD HH:MM" and date/time pickers for selecting values.
 *
 * @component
 * @prop {String} value - The value of the text field.
 * @prop {String} label - The label for the text field.
 * @prop {String} placeholder - The placeholder text for the text field.
 * @prop {Array} rules - An array of validation rules for the text field.
 */
export default {
  name: 'DateTimePicker',
  props: {
    value: String,
    label: String,
    placeholder: String,
    rules: Array,
  },
  data() {
    return {
      datePicker: false,
      timePicker: false,
      currentValue: this.value,
    };
  },
  methods: {
    /**
     * Update the value of the text field and emit an `input` event with the new value.
     *
     * @param {String} newValue - The new value of the text field.
     */
    updateValue(newValue) {
      this.currentValue = newValue;
      this.$emit('input', this.currentValue);
    },
    /**
     * Open the date or time picker when the text field is clicked.
     */
    openPicker() {
      const dateRegex = /^\d{4}-\d{2}-\d{2}/;
      const timeRegex = /\d{2}:\d{2}$/;
      if (dateRegex.test(this.currentValue)) {
        this.datePicker = true;
      } else if (timeRegex.test(this.currentValue)) {
        this.timePicker = true;
      }
    },
  },
  watch: {
  /**
   * Hide the date and time pickers when the input value matches the date/time format.
   *
   * @param {String} newValue - The new value of the text field.
   */
    currentValue(newValue) {
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
      if (dateTimeRegex.test(newValue)) {
        this.datePicker = false;
        this.timePicker = false;
      }
    },
  },
  computed: {
    /**
    * The mask to use for the text field.
    *
    * @returns {String} The mask string.
    */
    mask() {
      return '####-##-## ##:##';
    },
  },
};
</script>
