import axios from 'axios';

const customActions = {
  // eslint-disable-next-line no-unused-vars
  async CREATE_gallery({ commit }, image) {
    const formData = new FormData();
    formData.append('Gallery', image);
    await axios.post('api/gallery', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async LOAD_images({ commit }) {
    const { data } = await axios.get('api/gallery');
    commit('SET_images', data);
  },
};

export default { ...customActions };
