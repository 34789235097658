import axios from 'axios';

const actions = {
  async CREATE_article({ commit }, article) {
    const response = await axios.post('/api/articles', article);
    commit('SET_article', response.data);
    return response.data;
  },

  async LOAD_article({ commit }, articleId) {
    const response = await axios.get(`/api/articles/${articleId}`);
    commit('SET_article', response.data);
    return response.data;
  },

  async LOAD_articles({ commit }) {
    const response = await axios.get('/api/articles');
    commit('SET_articles', response.data);
    return response.data;
  },

  async DELETE_article({ commit }, articleId) {
    const response = await axios.delete(`/api/articles/${articleId}`);
    commit('SET_article', null);
    return response.data;
  },
  async UPDATE_article({ commit }, article) {
    const response = await axios.put(`/api/articles/${article.id}`, article);
    commit('SET_article', null);
    return response.data;
  },
};

export default { ...actions };
