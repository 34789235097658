<template>
  <div
    class="html-textarea"
    contenteditable="true"
    @keydown="onKeyDown"
    @focus="onFocus"
    @blur="onBlur"
    ref="content"
  ></div>
</template>

<script>
export default {
  name: 'HtmlTextarea',
  emits: ['update:content', 'focus', 'blur'],
  props: {
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
    text(newValue) {
      const parsedText = this.parseText(newValue);
      if (parsedText !== this.$refs.content.innerHTML) {
        this.$refs.content.innerHTML = newValue;
        // set the caret at the end of the content
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(this.$refs.content, 1);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
  },
  mounted() {
    this.text = this.modelValue;
  },
  methods: {
    onKeyDown(event) {
      if (event.key === '@') {
        return;
      }
      event.preventDefault();
      if (event.key === 'Backspace') {
        this.text = this.text.slice(0, -1);
      }
      // check if the key is a letter or number
      if (event.key.match(/[a-zA-Z0-9\s]/) && event.key.length === 1) {
        this.text += event.key;
      }
    },
    onInput(event) {
      const text = event.target.innerHTML;
      const parsedText = this.parseText(text);
      if (parsedText !== text) {
        this.$emit('update:content', parsedText);
        // update html content
        this.$refs.content.innerHTML = parsedText;
      }
      // const { items } = this;
      // // check if a @user id exists and insert a the name wrapped with a div
      // items.forEach((item) => {
      //   const regex = new RegExp(`@${item.value}`, 'g');
      //   this.$refs.content.innerHTML = this.$refs.content.innerHTML.replace(
      //     regex,
      //     `<div class="mention" data-id="${item.value}">@${item.label}</div>`,
      //   );
      // });
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    parseText(text) {
      const regex = /@([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/g;
      const userIdToUsername = this.items.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
      }, {});
      const updatedText = text.replace(regex, (match, p1) => `@${userIdToUsername[p1] || 'UnknownUser'}`);
      return updatedText;
    },
  },
};
</script>

<style scoped>
.html-textarea {
  padding: 10px;
  min-height: 100px;
  overflow: auto;
  white-space: pre-wrap;
}
</style>
