<template>
  <v-row>
    <v-col
      v-for="(image, index) in images"
      :key="index"
      cols="12" md="4" lg="3" xl="2"
    >
        <v-img
        class="fade-in"
        :style="{ animationDelay: `${index * 250}ms` }"
        width="100%"
        height="400"
        stretch
        :src="image.imageUrl"
        />
    </v-col>
  </v-row>
</template>

<script>
/**
 * A photo gallery component for displaying a collection of images.
 *
 * @component
 * @example
 * <PhotoGallery :images="['http://www.example/image1.jpg', 'http://www.example/image2.jpg'" />
 */
export default {
  /**
   * The name of the component.
   *
   * @type {string}
   */
  name: 'PhotoGallery',

  /**
   * The props for the component.
   *
   * @prop {Array<string>} images - The array of image URLs to display in the gallery.
   * @default []
   */
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
