import axios from 'axios';

const customActions = {
  // eslint-disable-next-line no-unused-vars
  async LOAD_allUsers({ commit }, image) {
    const { data } = await axios.get('api/account/users');
    commit('SET_allUsers', data);
  },
  async UPDATE_user({ commit }, updatedUserData) {
    const { data } = await axios.put('api/account/user', updatedUserData);
    commit('SET_user', data);
  },
};

export default { ...customActions };
