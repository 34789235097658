<template>
  <v-row class="mt-3" justify="center" no-gutters>
    <v-col cols="12">
      <v-card flat>
        <v-card-text>
          <Register />
          <v-container>
              <v-row class="mt-0" justify="center">
                <h4 class="mb-5" justify="center">
                  <span>{{ $t('login.or') }}</span>
                </h4>
              </v-row>
              <v-row class="mt-0" justify="center">
                <v-btn text class="title" :to="{ name: 'auth-Login' }">
                  {{ $t('register.login') }}
                </v-btn>
              </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js';
import Register from '../components/Register.vue';

export default {
  name: 'RegisterView',
  components: {
    Register,
  },
  data() {
    return {
      mdiOpenInNew,
      counterInterval: undefined,
      backgroundName: 'main-background1',
    };
  },
};
</script>
