<!-- eslint-disable max-len -->
<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on">
        Upload
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Upload a new Schedule</span>
      </v-card-title>
      <v-card-text>
        <v-file-input accept=".xlsx" label="Excel File" :icon="mdiUpload" @change="uploadSchedule" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Close</v-btn>
        <v-spacer />
        <v-btn color="success" :disabled="!formData" @click="save" :loading="isSaving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiUpload } from '@mdi/js';
import axios from 'axios';

export default {
  name: 'UploadSchedule',
  data() {
    return {
      mdiUpload,
      dialog: false,
      isSaving: false,
      formData: undefined,
      file: undefined,
    };
  },
  methods: {
    uploadSchedule(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formData = new FormData();
      this.formData.append('File', event, event.name);
    },
    async save() {
      this.isSaving = true;
      try {
        const { formData } = this;
        await axios.post('api/BroadcastSchedules/upload', formData);
        this.dialog = false;
        this.$emit('uploaded');
      } catch (error) {
        this.$root.$emit('error', error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
