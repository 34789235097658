<template>
  <v-container fluid class="containerImage">
    <v-form class="form">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
                <h1>Drop a Line</h1>
            </v-col>
              <v-col cols="12">
                <v-select
                  :items="items"
                  label="Who would you like to call?"
                ></v-select>
              </v-col>
              <v-col cols="12">
                  <v-text-field
                  v-model="message1"
                  label="Your Name"
                  clearable
              ></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-text-field
                  v-model="message1"
                  label="Your Email"
                  clearable
              ></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-textarea
                  clearable
                  clear-icon="mdi-close-circle"
                  label="Your Message"
                  v-model="message2"
                  ></v-textarea>
              </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <VueRecaptcha :sitekey="sitekeyData"/>
          <v-spacer></v-spacer>
          <v-btn :color="$vuetify.theme.isDark ? 'primary' : 'secondary'">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'ContactUs',
  module: 'ContactUs',
  computed: {
    sitekeyData() {
      return process.env.VUE_APP_GOOGLE_API_KEY;
    },
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      message1: '',
      message2: '',
      items: [
        'Customer Care',
        'Technical issues',
        'Contact the Presenter',
      ],
    };
  },

};
</script>

<style lang="scss" scoped>
.form {
  display: block;
  margin: 0 auto;
  max-width: 500px;
}
.containerImage {
  object-fit: fill;
  height: 100%;
  background-image: url('../assets/ContactUsPic2.jpg') !important;
  background-size: 100% 100%;
}
@media screen and (max-width: 600px) {
  .form {
  display: block;
  margin: 0 auto;
  max-width: 400px;
  }
  .containerImage {
    min-width: 400px;
    min-height: 650px;
  }
}
</style>
