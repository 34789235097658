import {
  mdiEmoticonHappyOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isInRole']),
    menuItems() {
      const userSettings = [];
      let adminSettings = [];
      if (this.isInRole('Administrators')) {
        adminSettings = [{
          name: 'menu.feedbackItems', pathName: 'admin-FeedbackItems', icon: mdiEmoticonHappyOutline, topMenu: false,
        }];
      }
      return [...userSettings, ...adminSettings];
    },
  },
};
