<template>
  <v-row>
      <v-col
        v-for="(image, index) in images"
        :key="image"
        cols="12"
        xs="12"
        sm="4"
      >
          <v-img
          class="slide-in-left"
          :style="{ animationDelay: `${index * 100}ms` }"
          max-height="300px"
          max-width="300px"
          :src="`${baseUrl}/api/gallery/${image}`"
          />
      </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '../mixins/loading';

export default {
  name: 'PhotoGallery',
  mixins: [loading],
  computed: {
    ...mapState('galleries', ['images']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('galleries', ['LOAD_images']),
    async refresh() {
      try {
        await this.LOAD_images();
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
