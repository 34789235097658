<template>
  <v-form
    ref="form"
    style="width: 100%;"
    @keydown.enter="submit"
    @submit.prevent="login"
    data-cy="form-login"
  >
    <slot name="username">
      <v-text-field
        id="userName"
        :label="$t('common.username')"
        outlined
        :prepend-inner-icon="mdiAccount"
        v-model="form.username"
        :rules="rules.required"
        data-cy="login-username"
        autocomplete="username"
      ></v-text-field>
    </slot>
    <slot name="password">
      <PasswordField
        id="password"
        :label="$t('common.password')"
        outlined
        :rules="rules.password"
        v-model="form.password"
        data-cy="login-password"
        autocomplete="current-password"
      ></PasswordField>
    </slot>
    <v-alert type="error" v-for="error in errors" :key="error">
      {{ error }}
    </v-alert>
    <slot name="loginButton">
      <v-btn class="mt-2 mb-1" text small :to="{ name: 'app-privacyPolicy' }">
        Read our Privacy Policy
      </v-btn>
      <ButtonProgress
        height="50"
        class="primary mb-2 rounded-lg text-capitalize d-block"
        style="width: 100%"
        :loading="isLoading"
        data-cy="login-buttonLogin"
        type="submit"
      >
          {{ $t('common.buttons.login') }}
      </ButtonProgress>
    </slot>
    <slot name="forgotPassword">
      <v-container v-if="showForgotPassword">
          <ForgotPasswordModal :email="form.username" :label="$t('common.forgotPasswordHeading')" />
      </v-container>
    </slot>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiAccount, mdiLock } from '@mdi/js';
import ButtonProgress from '@codehq/aurora-app-core/src/components/ButtonProgress.vue';
import auth from '@codehq/aurora-app-core/src/mixins/auth';
import { authHub } from '@codehq/aurora-app-core/src';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ForgotPasswordModal from './ForgotPasswordModal.vue';
import PasswordField from './PasswordField.vue';

export default {
  name: 'Login',
  components: {
    ButtonProgress,
    ForgotPasswordModal,
    PasswordField,
  },
  mixins: [auth, loading],
  props: {
    labelUsername: {
      type: String,
      default: 'Username',
    },
    labelPassword: {
      type: String,
      default: 'Password',
    },
    showForgotPassword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiAccount,
      mdiLock,
      form: {
        username: this.$route.query.username ?? '',
        password: '',
      },
      rules: {
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        required: [
          (v) => !!v || 'This field is required',
        ],
      },
      tab: null,
    };
  },
  methods: {
    ...mapActions('auth', ['LOGIN_auth']),
    async login() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          const { username, password } = this.form;
          this.$log.info(`Logging on ${username}`);
          await this.LOGIN_auth({ username, password });
          this.$root.$emit('auth:login');
          authHub.$emit('auth:login');
        } catch (error) {
          this.$log.error(error);
          this.errors = [error.response.data.error_description];
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
   width: 100%;
   text-align: center;
   border-bottom: 1px solid #000;
   line-height: 0.1em;
   margin: 10px 0 20px;
}

h4 span {
    background:#fff;
    padding:0 10px;
}
</style>
