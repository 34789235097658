<template>
    <div class="cardContainer slide-in-bottom delay-3">
      <div
        v-for="(event, i) in events"
        :key="i"
        class="card-container"
      >
      <v-card class="card-event">
        <v-img
          class="event-image"
          :src="event.featuredImageUrl"
          height="400px"
          width="400px"
        >
        </v-img>
        <v-card-title class="cardText">
          {{ event.eventName }}
        </v-card-title>
        <v-card-text>
          <p class="">
            {{ event.summary }}
          </p>
        </v-card-text>
        <v-card-subtitle>
          <p class="">
            {{ event.formattedDate }}
          </p>
        </v-card-subtitle>
        <v-card-actions
          >
            <v-btn
              v-if="event.facebookLink"
              inline-block
              fab
              :href="event.facebookLink"
              target="_blank"
            >
              <v-icon>
                {{ mdiFacebook }}
              </v-icon>
            </v-btn>
            <v-btn
              v-if="event.instagramLink"
              inline-block
              fab
              :href="event.instagramLink"
              target="_blank"
            >
              <v-icon>
                {{ mdiInstagram }}
              </v-icon>
            </v-btn>
            <v-btn
              v-if="event.twitterLink"
              inline-block
              fab
              :href="event.twitterLink"
              target="_blank"
            >
              <v-icon>
                {{ mdiTwitter }}
              </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      </div>
    </div>
</template>

<script>
import {
  mdiFacebook, mdiInstagram, mdiTwitter, mdiPlusCircle,
} from '@mdi/js';
import { mapState, mapActions, mapGetters } from 'vuex';
import * as dayjs from 'dayjs';

export default {
  name: 'EventsDisplay',
  computed: {
    ...mapState('scheduledEvents', ['scheduledEvents']),
    ...mapGetters('scheduledEvents', ['currentAndNextScheduleEvent']),
  },
  data() {
    return {
      dialog: false,
      mdiPlusCircle,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      events: [],
      overlay: false,
    };
  },
  mounted() {
    this.refreshEvents();
    // TODO: another trigger method.
    this.$root.$on('refresh-events', () => {
      this.refreshEvents();
    });
  },
  destroyed() {
    this.$root.$off('refresh-events');
  },
  methods: {
    ...mapActions('scheduledEvents', ['LOAD_scheduledEvents']),
    async refreshEvents() {
      await this.LOAD_scheduledEvents();
      this.events = this.currentAndNextScheduleEvent.map((event) => {
        // eslint-disable-next-line no-param-reassign
        event.formattedDate = dayjs(event.eventDateStart).format('MMMM D');
        return event;
      });
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardContainer {
  display: flex; /* display children elements in a row */
  flex-wrap: nowrap; /* prevent wrapping of children elements */
  overflow-x: auto; /* enable horizontal scrolling */
  margin: 5px;
}

.card-container {
  display: flex; /* display all cards at same height */
  margin: 5px;
}

.card-event {
  display: flex;
  display: table-cell;
  margin: 5px;
  border-left: 5px;
  overflow-x: visible;
}
.card-event .event-image {
  flex: 1 0 auto; /* grow to fill available space */
}
.card-event .v-card-actions {
  align-self: flex-end; /* align to bottom */
}
@media only screen and (max-width: 600px) {
  .cardContainer {
  display: flex; /* display children elements in a row */
  flex-wrap: nowrap; /* prevent wrapping of children elements */
  overflow-x: auto; /* enable horizontal scrolling */
  margin: 5px;
}
  .card-container {
    display: flex; /* display children elements in a row */
    flex-wrap: nowrap; /* prevent wrapping of children elements */
    overflow-x: visible; /* enable horizontal scrolling */
  }
  .event-image{
    height: 200px !important;
    width: 200px !important;
  }
  .card-event {
    display: flex;
    display: table-cell;
    margin: 5px;
    border-left: 5px;
    overflow-x: visible;
  }
  .card-event .v-card-actions {
    align-self: flex-end; /* align to bottom */
  }
}
</style>
