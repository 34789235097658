<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" class="ml-2">
        <v-icon>{{ mdiHelpCircle }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Help Video
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <video :src="videoSrc" controls style="height: 100%; width: 100%"></video>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiHelpCircle, mdiClose } from '@mdi/js';

export default {
  data() {
    return {
      mdiClose,
      mdiHelpCircle,
      dialog: false,
      videoSrc: '/bright-future-extension-help.mkv',
    };
  },
  methods: {
    openDialog(videoSrc) {
      this.videoSrc = videoSrc;
      this.dialog = true;
    },
  },
};
</script>
