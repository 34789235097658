import axios from 'axios';

export default {
  async CREATE_product({ commit }, products) {
    const { data } = await axios.post('/api/products', products);
    commit('SET_product', data);
  },
  async LOAD_products({ commit }) {
    const { data } = await axios.get('/api/products');
    commit('SET_products', data);
  },
  async LOAD_product({ commit }, id) {
    const { data } = await axios.get(`/api/products/${id}`);
    commit('SET_product', data);
  },
  async UPDATE_product({ dispatch }, product) {
    await axios.put(`/api/products/${product.id}`, product);
    dispatch('LOAD_products');
  },
  async DELETE_product({ dispatch }, product) {
    await axios.delete(`/api/products/${product.id}`);
    dispatch('LOAD_products');
  },
};
