<template>
  <v-container fluid>
      <v-card>
        <v-card-title primary-title>
          Terms and Conditions
        </v-card-title>
        <v-card-text>
          <div v-html="content"></div>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
import showdown from 'showdown';
import Terms from '../Terms.md';

showdown.setFlavor('original');

export default {
  computed: {
    content() {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(Terms);
      return html;
    },
  },
};
</script>

<style scoped>

</style>
