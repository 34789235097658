<template>
  <v-btn
    :color="isSuccess ? 'success' : 'primary'"
    :loading="isLoading"
    @click="submit"
  >
    <transition name="fade">
      <v-icon v-if="showSuccess" left>
        {{ mdiCheck }}
      </v-icon>
    </transition>
    <slot v-if="!isSuccess">
      Save
    </slot>
    <slot v-if="isSuccess" name="success">
      Saved
    </slot>
  </v-btn>
</template>

<script>
import { mdiCheck } from '@mdi/js';

export default {
  name: 'SubmitButton',
  props: {
    call: {
      type: Function,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      mdiCheck,
      showSuccess: false,
      isLoading: false,
      isSuccess: false,
    };
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    toggleSuccess() {
      this.showSuccess = true;
      const timeout = this.timeout || 3000;
      setTimeout(() => {
        this.showSuccess = false;
        this.$emit('update:success', false);
      }, timeout);
    },
    async submit() {
      try {
        this.isLoading = true;
        await this.call(this.params);
        this.toggleSuccess();
        this.$emit('update:success', true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$emit('error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.success {
  background-color: green;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
