<template>
    <AdminCard label="Manage image and video galleries">
      <v-data-table
        elevation-1
        :headers="headers"
        :items="events"
        :search="search"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 30],
          'items-per-page-text': 'Rows per page'
        }"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
      >
      <template v-slot:top>
        <v-btn color="primary" @click="create" small>Create Gallery</v-btn>
        <ModalAlbum
          :event="event"
          :show="showAlbumModal"
          @close="showAlbumModal = false"
          @refresh="refresh"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <TableGallery :eventId="item.id" />
        </td>
      </template>
      <template v-slot:item.featuredImageUrl="{ item }">
        <div style="padding: 5px">
          <v-img
            :src="item.featuredImageUrl"
            width="100"
            height="100"
            contain
          ></v-img>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
        <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
      </template>
      </v-data-table>
    </AdminCard>
  </template>

<script>
/**
   * The AdminGalleryCard component.
   * @module AdminGalleryCard
   */

import { mapState, mapActions } from 'vuex';
import { mdiPencil, mdiDelete } from '@mdi/js';
import loading from '../mixins/loading';
import ModalAlbum from './EditAlbum.vue';
import TableGallery from './TableGallery.vue';

export default {
  name: 'AdminGalleryCard',

  mixins: [loading],

  components: {
    ModalAlbum,
    TableGallery,
  },

  computed: {
    ...mapState('events', ['events']),
  },

  data() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'name',
          sortable: true,
          width: '30%',
          divider: true,
        },
        {
          text: 'Album Cover Image',
          value: 'featuredImageUrl',
          sortable: false,
          width: '60%',
          divider: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '10%',
          divider: true,
        },
      ],
      expanded: [],
      singleExpand: false,
      event: {},
      search: '',
      showAlbumModal: false,
      mdiPencil,
      mdiDelete,
    };
  },

  methods: {
    ...mapActions('events', ['LOAD_events', 'DELETE_event']),
    create() {
      this.event = {
        name: '',
        featuredImageUrl: '',
      };
      this.showAlbumModal = true;
    },
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_events();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
    async remove(event) {
      const result = await this.$confirm('Are you sure you want to delete this Album?');

      if (!result) {
        // return if the user caneclled the delete
        return;
      }
      try {
        await this.DELETE_event(event.id);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    edit(event) {
      this.event = event;
      this.showAlbumModal = true;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
};
</script>
