<template>
  <v-dialog v-model="localShow" fullscreen>
    <v-container fluid>
      <v-form @submit.prevent="submit" ref="form">
        <v-card>
          <v-card-title primary-title>
            <v-text-field
              v-model="form.title"
              label="Title"
              outlined
              dense
              :rules="[rules.required]"
            />
          </v-card-title>
          <v-card-subtitle>
            <v-text-field
              v-model="form.subtitle"
              label="Subtitle"
              outlined
              dense
              :rules="[rules.required]"
            />
          </v-card-subtitle>
          <v-card-text>
            <v-textarea
              v-model="form.summary"
              label="Summary"
              outlined
              dense
              :rules="[rules.required]"
            />
            <ImageUpload @input="featuredImageUploaded" />
            <v-img
              v-if="form.featuredImageUrl && form.featuredImageUrl.length > 0"
              :src="form.featuredImageUrl"
              height="200px"
              contain
            >
            </v-img>
            <client-only>
              <VueEditor v-model="form.content" />
            </client-only>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isSaving" color="primary" @click="submit">
              {{ mode }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions } from 'vuex';
import ImageUpload from '../components/ImageUpload.vue';
import loading from '../mixins/loading';
import validation from '../components/validation';

export default {
  name: 'EditArticle',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    article: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    ImageUpload,
    VueEditor,
  },
  mixins: [loading, validation],
  data() {
    return {
      form: {
        id: undefined,
        title: '',
        subtitle: '',
        summary: '',
        featuredImageUrl: '',
        content: '',
      },
      localShow: this.show,
    };
  },
  computed: {
    mode() {
      return this.article?.id ? 'Update' : 'Create';
    },
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  watch: {
    article: {
      immediate: true,
      deep: true,
      handler() {
        this.updateLocalEvent();
      },
    },
    localShow: {
      immediate: true,
      handler() {
        this.$emit('update:show', this.localShow);
      },
    },
    show: {
      immediate: true,
      handler() {
        this.localShow = this.show;
      },
    },
  },
  methods: {
    ...mapActions('articles', ['CREATE_article', 'UPDATE_article']),
    updateLocalEvent() {
      this.form.id = this.article?.id ?? undefined;
      this.form.title = this.article?.title ?? undefined;
      this.form.subtitle = this.article?.subTitle ?? undefined;
      this.form.summary = this.article?.summary ?? undefined;
      this.form.content = this.article?.content ?? undefined;
      this.form.featuredImageUrl = this.article?.featuredImageUrl ?? undefined;
    },
    featuredImageUploaded(file) {
      this.form.featuredImageUrl = `${this.baseUrl}/api/gallery/${file.id}`;
    },

    async submit() {
      // validate form data
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        const { form } = this;
        const article = {
          id: form.id,
          title: form.title,
          subTitle: form.subtitle,
          summary: form.summary,
          content: form.content,
          featuredImageUrl: form.featuredImageUrl,
        };
        if (this.form.id) {
        // Update the existing broadcast schedule
          await this.UPDATE_article(article);
        } else {
        // Create a new broadcast schedule
          await this.CREATE_article(article);
        }
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
