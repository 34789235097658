import axios from 'axios';

const actions = {
  async CREATE_event({ commit }, event) {
    const response = await axios.post('/api/events', event);
    commit('SET_event', response.data);
    return response.data;
  },

  async LOAD_event({ commit }, eventId) {
    const response = await axios.get(`/api/events/${eventId}`);
    commit('SET_event', response.data);
    return response.data;
  },

  async LOAD_events({ commit }) {
    const response = await axios.get('/api/events');
    commit('SET_events', response.data);
    return response.data;
  },
  async UPDATE_event({ commit }, event) {
    const response = await axios.put(`/api/events/${event.id}`, event);
    commit('SET_event', null);
    return response.data;
  },
  async DELETE_event({ commit }, eventId) {
    const response = await axios.delete(`/api/events/${eventId}`);
    commit('SET_event', null);
    return response.data;
  },
};

export default { ...actions };
