<template>
  <v-img :src="thumbnail">
  </v-img>
</template>

<script>
export default {
  name: 'YouTubeThumbnail',
  props: {
    youTubeUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    videoId() {
      const url = new URL(this.youTubeUrl);
      return url.searchParams.get('v');
    },
    thumbnail() {
      return `https://img.youtube.com/vi/${this.videoId}/0.jpg`;
    },
  },
};
</script>
