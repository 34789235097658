<template>
  <div>
    <h2>{{ user ? 'Edit User' : 'Create User' }}</h2>
    <v-form @submit.prevent="saveUser">
      <v-text-field v-model="formData.username" label="Username" required></v-text-field>
      <v-text-field v-model="formData.email" label="Email" required></v-text-field>
      <v-text-field v-model="formData.password" label="Password" required></v-text-field>
      <v-btn type="submit">{{ user ? 'Update' : 'Create' }}</v-btn>
    </v-form>

    <h2 class="mb-5">Claims</h2>
    <v-list dense>
      <v-list-item v-for="claim in formData.claims" :key="claim.id">
        <v-list-item-title>{{ claim.displayName }}</v-list-item-title>
        <v-list-item-action>
          <v-btn @click="remove(claim)">Remove</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-form @submit.prevent="create">
      <v-select
        v-model="newClaim"
        return-object
        :items="claims"
        item-text="displayName"
        label="Claim"
        required
      ></v-select>
      <v-btn type="submit">Add Claim</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['user'],
  data() {
    return {
      formData: {
        username: '',
        email: '',
        password: '',
        claims: [],
      },
      newClaim: {
        claimType: '',
        claimValue: '',
        displayName: '',
      },
    };
  },
  computed: {
    ...mapState('manageUsers', ['claims']),
  },
  created() {
    if (this.user) {
      this.formData.username = this.user.username;
      this.formData.email = this.user.email;
      this.formData.claims = this.user.claims;
    }
  },
  methods: {
    ...mapActions('manageUsers', ['DELETE_claim', 'CREATE_claim']),
    async create() {
      const { newClaim } = this;
      await this.CREATE_claim({
        userId: this.user.id,
        claimType: newClaim.claimType,
        claimValue: newClaim.claimValue,
      });
      this.formData.claims.push({
        ...newClaim,
      });
      this.newClaim.claimType = '';
      this.newClaim.claimValue = '';
      this.newClaim.displayName = '';
    },
    async remove(claim) {
      await this.DELETE_claim(claim);
      this.formData.claims = this.formData.claims.filter((c) => c.id !== claim.id);
    },
  },
};
</script>
