<template>
  <div data-cy="FileUpload-input">
    <div class="container">
      <form @submit.prevent="onSubmit(this)" enctype="multipart/form-data">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-icon color="error" v-bind="attrs" v-on="on">{{ mdiExclamationThick }}</v-icon>
          </template>
          <span>After an image has been selected, Please click the 'Upload' button.</span>
        </v-tooltip>
        <div class="form-group">
          <v-file-input
            id="FileUpload_FormFile"
            name="FileUpload.FormFile"
            multiple
            @change="onFileUpload"
            label="Upload multiple new photo's"
            truncate-length="15"
            :accept="accept" />
        </div>
        <div class="form-group">
          <v-btn color="secondary" @click="onSubmit">Upload</v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mdiCamera, mdiExclamationThick } from '@mdi/js';
import axios from 'axios';

export default {
  name: 'MultiImageUpload',
  props: {
  /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: 'image/*',
    },
    /** Error message for max file size. Defaults to File is larger than the limit of 2Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 5Mb',
    },
    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: mdiCamera,
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Image',
    },
    /** Max file size. Defaults to 5000000 (5Mb).
    */
    maxFileSize: {
      type: Number,
      default: 5000000,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  data() {
    return {
      errors: [], // array to store errors
      file: [], // file array
      name: '', // file name
      mdiExclamationThick,
    };
  },
  methods: {
    // called when file is selected by the input control
    onFileUpload(event) {
      if (event.length > 0) {
        // add each file to the files array
        this.files.push(event);
      }
    },
    // called when submit is clicked
    async onSubmit() {
      const fileElement = document.getElementById('FileUpload_FormFile');
      // upload files
      const formData = new FormData();
      for (let i = 0; i < fileElement.files.length; i += 1) {
        formData.append('file', fileElement.files[i]);
      }

      const { data } = await axios.post('api/gallery/PostMultipleFile', formData);
      this.$emit('input', data);
    },
  },
};
</script>
