<template>
  <video
    data-matomo-title="live_43bac4c0812511edb24427eebfba19c9"
    title="live_43bac4c0812511edb24427eebfba19c9"
    data-matomo-resource="https://stream.castr.com/63908c929c405b3ccfb17c7e/live_43bac4c0812511edb24427eebfba19c9/rewind-3600.m3u8"
    style="position:absolute;pointer-events:none;opacity:0;"></video>
</template>

<script>
export default {
  name: 'CastrAudioPlayer',
};
</script>
