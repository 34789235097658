<template>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card v-if="isCompleted">
        <v-card-title class="headline">{{ $t('common.phrases.changePasswordTitle') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="isLoading"
            @click.native="finish">
              {{ $t('common.ok') }}
            </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="headline">{{ $t('common.phrases.passwordReset') }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('common.newPassword')"
            v-model="newPassword"
            :append-icon="show1 ? mdiEye : mdiEyeOff"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            :label="$t('common.confirmPassword')"
            v-model="confirmPassword"
            :append-icon="show2 ? mdiEye : mdiEyeOff"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="closeRedirect">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" :loading="isLoading"
            @click="changePassword">
              {{ $t('common.phrases.changePassword') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

<script>
import { mapActions } from 'vuex';
import validation from '@codehq/aurora-app-core/src/components/validation';
import { mdiEye, mdiEyeOff } from '@mdi/js';

export default {
  name: 'ResetPassword',
  mixins: [validation],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: true,
      isLoading: false,
      isCompleted: false,
      newPassword: '',
      confirmPassword: '',
      show1: false,
      show2: false,
      mdiEye,
      mdiEyeOff,
    };
  },
  methods: {
    ...mapActions('auth', ['CHANGE_PASSWORD_auth']),
    async changePassword() {
      this.isLoading = true;
      const { newPassword, confirmPassword } = this;
      if (newPassword === confirmPassword) {
        const decodedEmail = decodeURIComponent(this.$route.query.email);
        await this.CHANGE_PASSWORD_auth({
          email: decodedEmail,
          token: this.$route.query.token,
          password: this.newPassword,
        });
        this.isLoading = false;
        this.isCompleted = true;
        this.closeRedirect();
      }
    },
    finish() {
      this.dialog = false;
      this.isCompleted = false;
    },
    closeRedirect() {
      this.dialog = false;
      this.$router.push({ name: 'auth-Login' });
    },
  },
};
</script>
