<template>
  <v-form
    ref="form"
    style="width: 100%;"
    @keydown.enter="submit"
    @submit.prevent="register"
    data-cy="form-register"
  >
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          :label="$t('common.email')"
          id="userName"
          dense
          outlined
          v-model="form.email"
          :rules="rules.email"
          data-cy="register-email"
          :prepend-inner-icon="mdiEmail"
          autocomplete="username"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="$t('common.name')"
          dense
          outlined
          v-model="form.name"
          data-cy="register-name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="$t('common.surname')"
          dense
          outlined
          v-model="form.surname"
          data-cy="register-surname"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :label="$t('common.displayName')"
          dense
          outlined
          v-model="form.displayName"
          data-cy="register-displayName"
          :prepend-inner-icon="mdiAccount"
          autocomplete="username"
        />
      </v-col>
      <v-col cols="12" md="6">
        <RegionSelect
          @country-selected="countrySelect"
          @province-selected="provinceSelect"
        />
      </v-col>
      <v-col cols="12" md="6">
        <DateOfBirth  @save-birthday="saveBirthday"/>
      </v-col>
      <v-col cols="12" md="6">
        <PasswordField
          dense
          id="password"
          :label="$t('common.password')"
          :rules="rules.password"
          v-model="form.password"
          outlined
          data-cy="register-password"
          autocomplete="new-password"
          class="mb-0"
        />
      </v-col>
      <v-col cols="12" md="6">
        <!-- Repeat -->
        <PasswordField
          dense
          id="password-confirm"
          :prepend-inner-icon="mdiLockOutline"
          :label="$t('common.confirmPassword')"
          :rules="rules.repeat"
          v-model="form.repeat"
          outlined
          filled
          autocomplete="new-password"
          data-cy="register-confirm-password"
        />
      </v-col>
    </v-row>
    <v-alert type="error" v-for="error in errors" :key="error">
      {{ error }}
    </v-alert>
    <v-btn class="mt-2 mb-1" text small :to="{ name: 'app-privacyPolicy' }">
      Read our Privacy Policy
    </v-btn>
    <ButtonProgress
      height="50"
      class="primary mb-2 rounded-lg text-capitalize d-block"
      style="width: 100%"
      :loading="isLoading"
      type="submit"
      data-cy="register-buttonRegister"
    >
      {{ $t('common.register') }}
    </ButtonProgress>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiAccount, mdiEmail, mdiLock, mdiLockOutline,
} from '@mdi/js';
import auth from '@codehq/aurora-app-core/src/mixins/auth';
import { authHub } from '@codehq/aurora-app-core/src';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ButtonProgress from '@codehq/aurora-app-core/src/components/ButtonProgress.vue';
import PasswordField from './PasswordField.vue';
import DateOfBirth from './DateOfBirth.vue';
import RegionSelect from './RegionSelector.vue';

export default {
  name: 'Register',
  mixins: [auth, loading],
  components: {
    ButtonProgress,
    PasswordField,
    DateOfBirth,
    RegionSelect,
  },
  data() {
    return {
      mdiAccount,
      mdiEmail,
      mdiLock,
      mdiLockOutline,
      form: {
        email: '',
        name: '',
        surname: '',
        displayName: '',
        birthDate: '',
        country: '',
        province: '',
        password: '',
        repeat: '',
        errors: '',
      },
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        repeat: [
          (v) => !!v || 'This field is required',
          (v) => v === this.form.password || 'Passwords do not match',
        ],
      },
    };
  },
  computed: {
    ...mapState('app', ['loading']),
  },
  methods: {
    ...mapActions('auth', ['LOGIN_auth', 'REGISTER_auth']),
    saveBirthday(date) {
      this.form.birthDate = date;
    },
    provinceSelect(data) {
      this.form.province = data;
    },
    countrySelect(data) {
      this.form.country = data;
    },
    async register() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true;
          const {
            email,
            password,
            displayName,
            birthDate,
            name,
            surname,
            imageUrl,
            province,
            country,
          } = this.form;
          this.$log.info(`Registering ${email}`);
          const registerResult = await this.REGISTER_auth({
            displayName,
            email,
            password,
            birthDate,
            name,
            surname,
            imageUrl,
            province,
            country,
          });
          this.$root.$emit('auth:register', registerResult.data);
          this.$log.info(`Logging on ${email}`);
          const loginResult = await this.LOGIN_auth({ username: email, password });
          this.$root.$emit('auth:login', loginResult.data);
          authHub.$emit('auth:login');
          this.$root.$emit('auth:registerComplete', {
            registerResult: registerResult.data,
            loginResult: loginResult.data,
          });
        } catch (error) {
          this.errors = error.response.data.modelState[''];
          this.$log.error(error.response.data.error_description);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h4 span {
  background: #fff;
  padding: 0 10px;
}
</style>
