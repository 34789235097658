/* eslint-disable no-param-reassign */
import log4Javascript from 'log4javascript';
import { o } from 'odata';
import { authHub, errorHub } from '@codehq/aurora-app-core/src';

// logging
const log = log4Javascript.getLogger();
let logLevel = log4Javascript.Level.WARN;
switch (process.env.VUE_APP_LOGLEVEL) {
  case 'ALL':
    logLevel = log4Javascript.Level.ALL;
    break;
  case 'TRACE':
    logLevel = log4Javascript.Level.TRACE;
    break;
  case 'INFO':
    logLevel = log4Javascript.Level.INFO;
    break;
  case 'DEBUG':
    logLevel = log4Javascript.Level.DEBUG;
    break;
  case 'ERROR':
    logLevel = log4Javascript.Level.ERROR;
    break;
  case 'FATAL':
    logLevel = log4Javascript.Level.FATAL;
    break;
  case 'OFF':
    logLevel = log4Javascript.Level.OFF;
    break;
  case 'WARN':
  default:
    logLevel = log4Javascript.Level.WARN;
    break;
}
log.setLevel(logLevel);

const mixin = {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.from = from;
    });
  },
  data() {
    return {
      /**
       * From path for navigating when cancelled or saved. Default to Home,
       */
      from: { name: 'Home' },
      validation: {
        rules: {
          required: (value) => !!value || this.$t('common.validation.required'),
          email: [
            (v) => !!v || this.$t('common.validation.required'),
            (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || this.$t('common.validation.email'),
          ],
          min: [
            (v) => !!v || this.$t('common.validation.required'),
            (v) => (v.length <= 0 ? this.$t('common.validation.min') : true),
          ],
        },
      },
    };
  },
  computed: {
    /**
    * Get the i18n local prefix based on the module and name of the component
    */
    localePrefix() {
      return `${this.$options.module}.${this.$options.name}`;
    },
  },
  methods: {
    /**
    * Translate for module
    */
    $$t(name, obj) {
      return this.$t(`App.${this.$options.module}.${name}`, obj);
    },
    /**
    * Translate for common
    */
    $$tc(name, obj) {
      return this.$t(`common.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$tp(name, obj) {
      return this.$tc(`App.${this.$options.module}.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$d(name, obj) {
      return this.$d(`App.${this.$options.module}.${name}`, obj);
    },
  },
};

export default {
  /**
  * Install the plugin
  */
  install(Vue, options) {
    const { store } = options;
    if (!store) {
      throw new Error('Vuex store option missing');
    }
    Vue.mixin(mixin);
    Vue.prototype.$log = log;
    // setup logging
    if (process.env.NODE_ENV === 'development') {
      // const popUpAppender = new log4Javascript.PopUpAppender();
      // popUpAppender.setFocusPopUp(true);
      // popUpAppender.setNewestMessageAtTop(true);
      // log.addAppender(popUpAppender);
      const browserConsoleAppender = new log4Javascript.BrowserConsoleAppender();
      log.addAppender(browserConsoleAppender);

      // eslint-disable-next-line max-len
      // const ajaxAppender = new log4Javascript.AjaxAppender(`${options.baseUrl}/Logs/Write`, true);
      // ajaxAppender.setLayout(new log4Javascript.JsonLayout());
      // ajaxAppender.addHeader('Content-Type', 'application/json; charset=utf-8');
      // log.addAppender(ajaxAppender);
      // log.info('logger loaded');
    }

    // setup odata for vuex
    store.$log = log;
    store.$o = {
      version: '0.0.1',
      store,
      client() {
        this.$log.debug('creating odata client');
        const authToken = this.store.getters['auth/getToken'];
        const organization = this.store.getters['organizations/getOrganization'];
        return o(`${process.env.VUE_APP_APIURL}/odata/`, {
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
            organizationid: `${organization?.id}`,
          }),
          onError: (x, e) => {
            if (e.status === 401) {
              authHub.$emit('user.unauthorized', e);
            } else {
              errorHub.$emit('network.error', e);
            }
          },
        });
      },
      parseId: (id) => {
        let i = id;
        if (Number.isNaN(id)) {
          i = `'${id}'`;
        }
        return i;
      },
    };
  },
};
