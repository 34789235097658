<template>
  <AdminCard label="Manage articles">
    <v-btn color="primary" small @click="create" class="mt-2 mb-2">Create Article</v-btn>
    <ModalArticle
      :article="article"
      :show.sync="showArticleModal"
      @close="showArticleModal = false"
      @refresh="refresh"
    />
    <v-data-table
      elevation="5"
      :headers="headers"
      :items="articles"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30],
        'items-per-page-text': 'Rows per page'
      }"
    >
    <template v-slot:item.featuredImageUrl="{ item }">
      <div style="padding: 5px">
        <v-img
          :src="item.featuredImageUrl"
          width="50"
          height="50"
          contain
        ></v-img>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
      <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
    </template>
    </v-data-table>
  </AdminCard>
</template>

<script>
/**
 * The AdminArticlesCard component.
 * @module AdminArticlesCard
 */

import { mapState, mapActions } from 'vuex';
import { mdiPencil, mdiDelete } from '@mdi/js';
import loading from '../mixins/loading';
import ModalArticle from '../views/EditArticle.vue';

export default {
  name: 'AdminArticlesCard',

  mixins: [loading],

  components: {
    ModalArticle,
  },

  computed: {
    ...mapState('articles', ['articles']),
  },

  data() {
    return {
      headers: [
        {
          text: 'Featured Image',
          value: 'featuredImageUrl',
          sortable: false,
          width: '10%',
          divider: true,
        },
        {
          text: 'Title',
          value: 'title',
          sortable: true,
          width: '20%',
          divider: true,
        },
        {
          text: 'Sub Title',
          value: 'subTitle',
          sortable: true,
          width: '20%',
          divider: true,
        },
        {
          text: 'Summary',
          value: 'summary',
          sortable: false,
          width: '40%',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '10%',
          align: 'right',
          filterable: false,
          divider: true,
        },
      ],
      article: {},
      search: '',
      showArticleModal: false,
      mdiPencil,
      mdiDelete,
    };
  },

  methods: {
    ...mapActions('articles', ['LOAD_articles', 'UPDATE_article', 'DELETE_article']),
    create() {
      this.article = {
        title: '',
        subTitle: '',
        featuredImageUrl: '',
        content: '',
        summary: '',
      };
      this.showArticleModal = true;
    },
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_articles();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
    async remove(article) {
      const result = await this.$confirm('Are you sure you want to delete this article?');

      if (!result) {
        // return if the user caneclled the delete
        return;
      }
      try {
        await this.DELETE_article(article.id);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    // update
    edit(article) {
      this.article = article;
      this.showArticleModal = true;
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
};
</script>
