import axios from 'axios';

export default {
  async CREATE_playbackItem({ commit }, playbackItems) {
    const { data } = await axios.post('/api/playbackItems', playbackItems);
    commit('SET_playbackItem', data);
  },
  async LOAD_playbackItems({ commit }) {
    const { data } = await axios.get('/api/playbackItems');
    commit('SET_playbackItems', data);
  },
  async LOAD_playbackItem({ commit }, id) {
    const { data } = await axios.get(`/api/playbackItems/${id}`);
    commit('SET_playbackItem', data);
  },
  async UPDATE_playbackItem({ dispatch }, playbackItem) {
    await axios.put(`/api/playbackItems/${playbackItem.id}`, playbackItem);
    dispatch('LOAD_playbackItems');
  },
  async DELETE_playbackItem({ dispatch }, playbackItem) {
    await axios.delete(`/api/playbackItems/${playbackItem.id}`);
    dispatch('LOAD_playbackItems');
  },
};
