<template>
  <div>
  <v-btn class="slide-in-top" @click="playPause">
    <v-icon v-if="isPlayAudio" class="{ 'mr-2': !$vuetify.breakpoint.mobile }">
      {{ mdiPauseCircle}}
    </v-icon>
    <v-icon v-else :class="{ 'mr-2': !$vuetify.breakpoint.mobile }">{{ mdiPlayCircle }}</v-icon>
    <template v-if="!$vuetify.breakpoint.mobile">
      {{ isPlayAudio ? 'Pause' : 'Play' }}
    </template>
  </v-btn>
  <CastrAudioPlayer v-if="isPlayAudio" />
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { mdiPlayCircle, mdiPauseCircle } from '@mdi/js';
import CastrAudioPlayer from './CastrAudioPlayer.vue';

export default {
  components: {
    CastrAudioPlayer,
  },
  data() {
    return {
      mdiPlayCircle,
      mdiPauseCircle,
    };
  },
  computed: {
    ...mapState('app', ['isPlayAudio']),
  },
  methods: {
    ...mapMutations('app', ['SET_isPlayAudio']),
    playPause() {
      this.SET_isPlayAudio(!this.isPlayAudio);
    },
  },
};
</script>
