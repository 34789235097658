<template>
  <AdminCard label="Manage events and schedules">
    <v-data-table
      elevation-1
      :headers="headers"
      :items="scheduledEvents"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30],
        'items-per-page-text': 'Rows per page'
      }"
    >
    <template v-slot:top>
      <v-btn color="primary" @click="create">Create Event</v-btn>
      <EventScheduleModal
        :event="scheduledEvent"
        :show="showEventModal"
        @close="showEventModal = false"
        @refresh="refresh"
        small
      />
    </template>
    <template v-slot:item.featuredImageUrl="{ item }">
      <div style="padding: 5px">
        <v-img
          :src="item.featuredImageUrl"
          width="50"
          height="50"
          contain
        ></v-img>
      </div>
    </template>
    <template v-slot:item.socials="{ item }">
      <template v-if="item.facebookLink">
        <a :href="item.facebookLink" target="_blank">
          <v-icon>{{ mdiFacebook }}</v-icon>
        </a>
      </template>
      <template v-if="item.instagramLink">
        <a :href="item.instagramLink" target="_blank">
          <v-icon>{{ mdiInstagram }}</v-icon>
        </a>
      </template>
      <template v-if="item.twitterLink">
        <a :href="item.twitterLink" target="_blank">
          <v-icon>{{ mdiTwitter }}</v-icon>
        </a>
      </template>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
      <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
    </template>
    </v-data-table>
  </AdminCard>
</template>

<script>
/**
* The AdminEventsCard component.
* @module AdminEventsCard
*/
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  mdiFacebook,
  mdiInstagram,
  mdiLinkedin,
  mdiTwitter,
  mdiYoutube,
  mdiWeb,
  mdiPencil,
  mdiDelete,
} from '@mdi/js';
import EventScheduleModal from './EventScheduleModal.vue';
import loading from '../mixins/loading';

export default {
  name: 'AdminEventsCard',
  components: {
    EventScheduleModal,
  },
  mixins: [loading],
  computed: {
    ...mapState('scheduledEvents', ['scheduledEvents']),
    ...mapGetters('broadcastSchedules', ['currentAndNextBroadcastSchedule']),
    currentBroadcast() {
      if (this.currentAndNextScheduleEvent.length === 0) return null;
      return this.currentAndNextScheduleEvent[0];
    },
    nextBroadcast() {
      if (this.currentAndNextScheduleEvent.length < 1) return null;
      return this.currentAndNextScheduleEvent[1];
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'featuredImageUrl',
          sortable: false,
          width: '10%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: 'Name',
          value: 'eventName',
          sortable: true,
          width: '10%',
          divider: true,
        },
        {
          text: 'Event Details',
          value: 'summary',
          sortable: false,
          width: '25%',
          divider: true,
        },
        {
          text: 'Event Date Start',
          value: 'eventDateStart',
          sortable: false,
          width: '15%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: 'Event Date End',
          value: 'eventDateEnd',
          sortable: false,
          width: '15%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: 'Socials',
          value: 'socials',
          sortable: false,
          width: '15%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '10%',
          align: 'right',
          filterable: false,
          divider: true,
        },
      ],
      showEventModal: false,
      search: '',
      scheduledEvent: {},
      mdiFacebook,
      mdiInstagram,
      mdiLinkedin,
      mdiTwitter,
      mdiYoutube,
      mdiWeb,
      mdiPencil,
      mdiDelete,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('scheduledEvents', ['LOAD_scheduledEvents', 'DELETE_scheduledEvent']),
    create() {
      this.scheduledEvent = {
        name: '',
        description: '',
        featuredImageUrl: '',
        faceBook: '',
        instagram: '',
        linkedIn: '',
        twitter: '',
      };
      this.showEventModal = true;
    },
    edit(scheduledEvent) {
      this.scheduledEvent = scheduledEvent;
      this.showEventModal = true;
    },
    async remove(scheduledEvent) {
      const result = await this.$confirm(`Are you sure you want to delete ${scheduledEvent.eventName}?`);

      if (!result) {
        // return if the user caneclled the delete
        return;
      }
      try {
        await this.DELETE_scheduledEvent(scheduledEvent.id);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_scheduledEvents();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
