<template>
  <v-container fluid class="containerImage">
    <v-row>
      <v-col cols="12">
        <v-form @submit.prevent="save">
          <v-card>
            <!-- tabs -->
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="tab in tabs" :key="tab.icon">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <v-card-text>

              <!-- tabs item -->
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <AccountInfo />
                </v-tab-item>
                <v-tab-item>
                  <AccountSecurity />
                </v-tab-item>
                <v-tab-item>
                  <Subscription />
                </v-tab-item>
                <v-tab-item>
                  <v-img src="../assets/coming-soon.png" />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiShoppingOutline,
} from '@mdi/js';
import AccountInfo from '../components/AccountInfo.vue';
import AccountSecurity from '../components/AccountSecurity.vue';
import Subscription from '../components/Subscription.vue';

export default {
  name: 'Profile',
  module: 'Profile',
  components: {
    AccountInfo,
    AccountSecurity,
    Subscription,
  },
  data() {
    return {
      tab: null,
      tabs: [
        { title: 'Account', icon: mdiAccountOutline },
        { title: 'Security', icon: mdiLockOpenOutline },
        { title: 'Subscription', icon: mdiInformationOutline },
        { title: 'Purchases', icon: mdiShoppingOutline },
      ],
    };
  },
  mounted() {
    this.tab = this.$route.query.tab || 0;
  },
};
</script>
<style lang="scss" scoped>
.containerImage {
  object-fit: fill;
  background-image: url('../assets/ContactUsPic2.jpg') !important;
  height: 100%;
  width: 100%;
}
</style>
