<template>
    <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <form v-if="addNewRoom" @submit.prevent="createRoom">
      <input v-model="addRoomUsername" type="text" placeholder="Add username" />
    <button type="submit" :disabled="disableForm || !addRoomUsername">
     Create Room
    </button>
    <button class="button-cancel" @click="addNewRoom = false">Cancel</button>
    </form>
    <form v-if="inviteRoomId" @submit.prevent="addRoomUser">
    <input v-model="invitedUsername" type="text" placeholder="Add username" />
    <button type="submit" :disabled="disableForm || !invitedUsername">
      Add User
    </button>
    <button class="button-cancel" @click="inviteRoomId = null">Cancel</button>
    </form>

    <form v-if="removeRoomId" @submit.prevent="deleteRoomUser">
    <select v-model="removeUserId">
     <option default value="">Select User</option>
     <option v-for="user in removeUsers" :key="user.id" :value="user.id">
        {{ user.username }}
     </option>
    </select>
    <button type="submit" :disabled="disableForm || !removeUserId">
     Remove User
    </button>
    <button class="button-cancel" @click="removeRoomId = null">Cancel</button>
    </form>

    <vue-advanced-chat
    ref="chatWindow"
    :height="screenHeight"
    theme="dark"
    :styles="JSON.stringify(styles)"
    :current-user-id="currentUserId"
    :room-id="roomId"
    :rooms="JSON.stringify(loadedRooms)"
    :loading-rooms="loadingRooms"
    :rooms-loaded="roomsLoaded"
    :messages="JSON.stringify(messages)"
    :messages-loaded="messagesLoaded"
    :room-message="roomMessage"
    :room-actions="JSON.stringify(roomActions)"
    :menu-actions="JSON.stringify(menuActions)"
    :message-selection-actions="JSON.stringify(messageSelectionActions)"
    :templates-text="JSON.stringify(templatesText)"
    @fetch-more-rooms="fetchMoreRooms"
    @fetch-messages="fetchMessages($event.detail[0])"
    @send-message="sendMessage($event.detail[0])"
    @edit-message="editMessage($event.detail[0])"
    @delete-message="deleteMessage($event.detail[0])"
    @open-file="openFile($event.detail[0])"
    @open-user-tag="openUserTag($event.detail[0])"
    @add-room="addRoom($event.detail[0])"
    @room-action-handler="menuActionHandler($event.detail[0])"
    @menu-action-handler="menuActionHandler($event.detail[0])"
    @message-selection-action-handler="
     messageSelectionActionHandler($event.detail[0])
    "
    @send-message-reaction="sendMessageReaction($event.detail[0])"
    @typing-message="typingMessage($event.detail[0])"
    @toggle-rooms-list="$emit('show-demo-options', $event.detail[0].opened)"
    >
    <!-- <div
     v-for="message in messages"
     :slot="'message_' + message.id"
     :key="message.id"
    >
     New message container
    </div> -->
    </vue-advanced-chat>
    <CastrVideoPlayer />
    </div>
</template>

<script>
import logoAvatar from '@/assets/logo.png';
import { register } from 'vue-advanced-chat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CastrVideoPlayer from '../components/CastrVideoPlayer.vue';

dayjs.extend(utc);
register();
export default {
  components: {
    CastrVideoPlayer,
  },
  props: {
    currentUserId: { type: String },
    theme: { type: String },
    isDevice: { type: Boolean },
  },
  emits: ['show-demo-options'],
  data() {
    return {
      logoAvatar,
      roomsPerPage: 15,
      rooms: [
        {
          roomId: '1',
          roomName: 'Room 1',
          avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj',
          users: [
            { _id: '1234', username: 'John Doe' },
            { _id: '4321', username: 'John Snow' },
          ],
        },
      ],
      roomId: '',
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [
        {
          _id: '6R0MijpK6M4AIrwaaCY2',
          username: 'Luke',
          avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj',
        },
        {
          _id: 'SGmFnBZB4xxMv9V4CVlW',
          username: 'Leia',
          avatar: 'https://avatarfiles.alphacoders.com/184/thumb-184913.jpg',
        },
        {
          _id: '6jMsIXUrBHBj7o2cRlau',
          username: 'Yoda',
          avatar:
          'https://vignette.wikia.nocookie.net/teamavatarone/images/4/45/Yoda.jpg/revision/latest?cb=20130224160049',
        },
      ],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: 0,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      menuActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      messageSelectionActions: [{ name: 'deleteMessages', title: 'Delete' }],
      styles: { container: { borderRadius: '4px' } },
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help',
        },
        {
          tag: 'action',
          text: 'This is the action',
        },
        {
          tag: 'action 2',
          text: 'This is the second action',
        },
      ],
      // ,dbRequestCount: 0
    };
  },
  computed: {
    loadedRooms() {
      return this.rooms.slice(0, this.roomsLoadedCount);
    },
    screenHeight() {
      return this.isDevice ? `${window.innerHeight}px` : 'calc(100vh - 80px)';
    },
  },
  mounted() {
    // this.addCss();
    this.fetchRooms();
    // this.updateUserOnlineStatus(this.currentUserId);
  },
  methods: {
    // async addCss() {
    //   if (import.meta.env.MODE === 'development') {
    //     const styles = await import('../../src/styles/index.scss');
    //     const style = document.createElement('style');
    //     style.innerHTML = styles.default;
    //     this.$refs.chatWindow.shadowRoot.appendChild(style);
    //   }
    // },
    resetRooms() {
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = true;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      this.resetMessages();
    },
    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.listeners.forEach((listener) => listener());
      this.listeners = [];
    },
    fetchRooms() {
      this.resetRooms();
      // this.fetchMoreRooms();
    },
    async fetchMoreRooms() {
      if (this.endRooms && !this.startRooms) {
        this.roomsLoaded = true;
        return;
      }
      const query = this.roomsQuery(
        this.currentUserId,
        this.roomsPerPage,
        this.startRooms,
      );
      const { data, docs } = await this.getRooms(query);
      // this.incrementDbCounter('Fetch Rooms', data.length)
      this.roomsLoaded = data.length === 0 || data.length < this.roomsPerPage;
      if (this.startRooms) this.endRooms = this.startRooms;
      this.startRooms = docs[docs.length - 1];
      const roomUserIds = [];
      data.forEach((room) => {
        room.users.forEach((userId) => {
          const foundUser = this.allUsers.find((user) => user?.id === userId);
          if (!foundUser && roomUserIds.indexOf(userId) === -1) {
            roomUserIds.push(userId);
          }
        });
      });
      // this.incrementDbCounter('Fetch Room Users', roomUserIds.length)
      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = this.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
      const roomList = {};
      data.forEach((room) => {
        roomList[room.id] = { ...room, users: [] };
        room.users.forEach((userId) => {
          const foundUser = this.allUsers.find((user) => user?.id === userId);
          if (foundUser) roomList[room.id].users.push(foundUser);
        });
      });
      const formattedRooms = [];
      Object.keys(roomList).forEach((key) => {
        const room = roomList[key];
        const roomContacts = room.users.filter(
          (user) => user.id !== this.currentUserId,
        );
        room.roomName = roomContacts.map((user) => user.username).join(', ') || 'Myself';
        const roomAvatar = roomContacts.length === 1 && roomContacts[0].avatar
          ? roomContacts[0].avatar
          : logoAvatar;
        formattedRooms.push({
          ...room,
          roomId: key,
          avatar: roomAvatar,
          index: room.lastUpdated.seconds,
          lastMessage: {
            content: 'Room created',
            timestamp: dayjs(
              new Date(room.lastUpdated.seconds),
              room.lastUpdated,
            ),
          },
        });
      });
      this.rooms = this.rooms.concat(formattedRooms);
      formattedRooms.forEach((room) => this.listenLastMessage(room));
      if (!this.rooms.length) {
        this.loadingRooms = false;
        this.roomsLoadedCount = 0;
      }
      this.listenUsersOnlineStatus(formattedRooms);
      this.listenRooms(query);
      // setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
    },
    listenLastMessage(room) {
      const listener = this.listenLastMessage(
        room.roomId,
        (messages) => {
          // this.incrementDbCounter('Listen Last Room Message', messages.length)
          messages.forEach((message) => {
            const lastMessage = this.formatLastMessage(message, room);
            const roomIndex = this.rooms.findIndex(
              (r) => room.roomId === r.roomId,
            );
            this.rooms[roomIndex].lastMessage = lastMessage;
            this.rooms = [...this.rooms];
          });
          if (this.loadingLastMessageByRoom < this.rooms.length) {
            this.loadingLastMessageByRoom += 1;
            if (this.loadingLastMessageByRoom === this.rooms.length) {
              this.loadingRooms = false;
              this.roomsLoadedCount = this.rooms.length;
            }
          }
        },
      );
      this.roomsListeners.push(listener);
    },
    formatLastMessage(message, room) {
      if (!message.timestamp) return;
      let { content } = message;
      if (message.files?.length) {
        const file = message.files[0];
        content = `${file.name}.${file.extension || file.type}`;
      }
      const username = message.senderid !== this.currentUserId
        ? room.users.find((user) => message.senderid === user.id)?.username
        : '';
      // eslint-disable-next-line consistent-return
      return {
        ...message,
        ...{
          id: message.id,
          content,
          senderId: message.senderid,
          timestamp: dayjs(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp,
          ),
          username,
          distributed: true,
          seen: message.senderid === this.currentUserId ? message.seen : null,
          new:
        message.senderid !== this.currentUserId
        && (!message.seen || !message.seen[this.currentUserId]),
        },
      };
    },
    fetchMessages({ room, options = {} }) {
      this.$emit('show-demo-options', false);
      if (options.reset) {
        this.resetMessages();
      }
      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        this.messagesLoaded = true;
        return;
      }
      this.selectedRoom = room.roomId;
      this.getMessages(room.roomId, this.messagesPerPage, this.lastLoadedMessage)
        .then(({ data, docs }) => {
          // this.incrementDbCounter('Fetch Room Messages', messages.length)
          if (this.selectedRoom !== room.roomId) return;
          if (data.length === 0 || data.length < this.messagesPerPage) {
            setTimeout(() => {
              this.messagesLoaded = true;
            }, 0);
          }
          if (options.reset) this.messages = [];
          data.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            this.messages.unshift(formattedMessage);
          });
          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];
          this.listenMessages(room);
        });
    },
    listenMessages(room) {
      const listener = this.listenMessages(
        room.roomId,
        this.lastLoadedMessage,
        this.previousLastLoadedMessage,
        (messages) => {
          messages.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            const messageIndex = this.messages.findIndex(
              (m) => m.id === message.id,
            );
            if (messageIndex === -1) {
              this.messages = this.messages.concat([formattedMessage]);
            } else {
              this.messages[messageIndex] = formattedMessage;
              this.messages = [...this.messages];
            }
            this.markMessagesSeen(room, message);
          });
        },
      );
      this.listeners.push(listener);
    },
    markMessagesSeen(room, message) {
      if (
        message.senderid !== this.currentUserId
     && (!message.seen || !message.seen[this.currentUserId])
      ) {
        this.updateMessage(room.roomId, message.id, {
          [`seen.${this.currentUserId}`]: new Date(),
        });
      }
    },
    formatMessage(room, message) {
      // const senderUser = room.users.find(user => user.id === message.senderid)
      const formattedMessage = {
        ...message,
        ...{
          senderId: message.senderid,
          id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: dayjs(message.timestamp, 'HH:mm'),
          date: dayjs(message.timestamp, 'DD MMMM YYYY'),
          username: room.users.find((user) => message.senderid === user.id)
            ?.username,
          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true,
        },
      };
      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.senderid,
          },
        };
      }
      return formattedMessage;
    },
    async sendMessage({
      content, roomId, files, replyMessage,
    }) {
      const message = {
        senderid: this.currentUserId,
        content,
        timestamp: new Date(),
      };
      if (files) {
        message.files = this.formattedFiles(files);
      }
      if (replyMessage) {
        message.replyMessage = {
          id: replyMessage.id,
          content: replyMessage.content,
          senderid: replyMessage.senderId,
        };
        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files;
        }
      }
      const { id } = await this.addMessage(roomId, message);
      if (files) {
        for (let index = 0; index < files.length; index += 1) {
          this.uploadFile({ file: files[index], messageId: id, roomId });
        }
      }
      this.updateRoom(roomId, { lastUpdated: new Date() });
    },
    async editMessage({
      messageId, newContent, roomId, files,
    }) {
      const newMessage = { edited: new Date() };
      newMessage.content = newContent;
      if (files) {
        newMessage.files = this.formattedFiles(files);
      } else {
        newMessage.files = this.deleteDbField;
      }
      await this.updateMessage(roomId, messageId, newMessage);
      if (files) {
        for (let index = 0; index < files.length; index += 1) {
          if (files[index]?.blob) {
            this.uploadFile({ file: files[index], messageId, roomId });
          }
        }
      }
    },
    async deleteMessage({ message, roomId }) {
      await this.updateMessage(roomId, message.id, {
        deleted: new Date(),
      });
      const { files } = message;
      if (files) {
        files.forEach((file) => {
          this.deleteFile(this.currentUserId, message.id, file);
        });
      }
    },
    async uploadFile({ file, messageId, roomId }) {
      return new Promise((resolve) => {
        let type = file.extension || file.type;
        if (type === 'svg' || type === 'pdf') {
          type = file.type;
        }
        this.listenUploadImageProgress(
          this.currentUserId,
          messageId,
          file,
          type,
          (progress) => {
            this.updateFileProgress(messageId, file.localUrl, progress);
          },
          // (_error) => {
          //   resolve(false);
          // },
          async (url) => {
            const message = await this.getMessage(roomId, messageId);
            message.files.forEach((f) => {
              if (f.url === file.localUrl) {
                // eslint-disable-next-line no-param-reassign
                f.url = url;
              }
            });
            await this.updateMessage(roomId, messageId, {
              files: message.files,
            });
            resolve(true);
          },
        );
      });
    },
    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find((m) => m.id === messageId);
      if (!message || !message.files) return;
      message.files.find((file) => file.url === fileUrl).progress = progress;
      this.messages = [...this.messages];
    },
    formattedFiles(files) {
      const formattedFiles = [];
      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        };
        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }
        formattedFiles.push(messageFile);
      });
      return formattedFiles;
    },
    openFile({ file }) {
      window.open(file.file.url, '_blank');
    },
    async openUserTag({ user }) {
      let roomId;
      this.rooms.forEach((room) => {
        if (room.users.length === 2) {
          const userId1 = room.users[0].id;
          const userId2 = room.users[1].id;
          if (
            (userId1 === user.id || userId1 === this.currentUserId)
        && (userId2 === user.id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId;
          }
        }
      });
      if (roomId) {
        this.roomId = roomId;
        return;
      }
      const query1 = await this.getUserRooms(
        this.currentUserId,
        user.id,
      );
      if (query1.data.length) {
        this.loadRoom(query1);
      }
      const query2 = await this.getUserRooms(
        user.id,
        this.currentUserId,
      );
      if (query2.data.length) {
        this.loadRoom(query2);
      }
      const users = user.id === this.currentUserId
        ? [this.currentUserId]
        : [user.id, this.currentUserId];
      const room = await this.addRoom({
        users,
        lastUpdated: new Date(),
      });
      this.roomId = room.id;
      this.fetchRooms();
    },
    async loadRoom(query) {
      query.forEach(async (room) => {
        if (this.loadingRooms) return;
        await this.updateRoom(room.id, { lastUpdated: new Date() });
        this.roomId = room.id;
        this.fetchRooms();
      });
    },
    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        default:
        case 'inviteUser':
          return this.inviteUser(roomId);
        case 'removeUser':
          return this.removeUser(roomId);
        case 'deleteRoom':
          return this.deleteRoom(roomId);
      }
    },
    messageSelectionActionHandler({ action, messages, roomId }) {
      switch (action.name) {
        case 'deleteMessages':
          messages.forEach((message) => {
            this.deleteMessage({ message, roomId });
          });
          break;
        default:
      }
    },
    async sendMessageReaction({
      reaction, remove, messageId, roomId,
    }) {
      this.updateMessageReactions(
        roomId,
        messageId,
        this.currentUserId,
        reaction.unicode,
        remove ? 'remove' : 'add',
      );
    },
    typingMessage({ message, roomId }) {
      if (roomId) {
        if (message?.length > 1) {
          this.typingMessageCache = message;
          return;
        }
        if (message?.length === 1 && this.typingMessageCache) {
          this.typingMessageCache = message;
          return;
        }
        this.typingMessageCache = message;
        this.updateRoomTypingUsers(
          roomId,
          this.currentUserId,
          message ? 'add' : 'remove',
        );
      }
    },
    async listenRooms(query) {
      const listener = this.listenRooms(query, (rooms) => {
        // this.incrementDbCounter('Listen Rooms Typing Users', rooms.length)
        rooms.forEach((room) => {
          const foundRoom = this.rooms.find((r) => r.roomId === room.id);
          if (foundRoom) {
            foundRoom.typingUsers = room.typingUsers;
            foundRoom.index = room.lastUpdated.seconds;
          }
        });
      });
      this.roomsListeners.push(listener);
    },
    listenUsersOnlineStatus(rooms) {
      rooms.forEach((room) => {
        room.users.forEach((user) => {
          const listener = this.firebaseListener(
            this.userStatusRef(user.id),
            (snapshot) => {
              if (!snapshot || !snapshot.val()) return;
              const lastChanged = dayjs(
                new Date(snapshot.val().lastChanged),
                new Date(snapshot.val().lastChanged),
              );
              // eslint-disable-next-line no-param-reassign
              user.status = { ...snapshot.val(), lastChanged };
              const roomIndex = this.rooms.findIndex(
                (r) => room.roomId === r.roomId,
              );
              this.rooms[roomIndex] = room;
              this.rooms = [...this.rooms];
            },
          );
          this.roomsListeners.push(listener);
        });
      });
    },
    addRoom() {
      this.resetForms();
      this.addNewRoom = true;
    },
    async createRoom() {
      this.disableForm = true;
      const { id } = await this.addUser({
        username: this.addRoomUsername,
      });
      await this.updateUser(id, { id });
      await this.addRoom({
        users: [id, this.currentUserId],
        lastUpdated: new Date(),
      });
      this.addNewRoom = false;
      this.addRoomUsername = '';
      this.fetchRooms();
    },
    inviteUser(roomId) {
      this.resetForms();
      this.inviteRoomId = roomId;
    },
    async addRoomUser() {
      this.disableForm = true;
      const { id } = await this.addUser({
        username: this.invitedUsername,
      });
      await this.updateUser(id, { id });
      await this.addRoomUser(this.inviteRoomId, id);
      this.inviteRoomId = null;
      this.invitedUsername = '';
      this.fetchRooms();
    },
    removeUser(roomId) {
      this.resetForms();
      this.removeRoomId = roomId;
      this.removeUsers = this.rooms.find((room) => room.roomId === roomId).users;
    },
    async deleteRoomUser() {
      this.disableForm = true;
      await this.removeRoomUser(
        this.removeRoomId,
        this.removeUserId,
      );
      this.removeRoomId = null;
      this.removeUserId = '';
      this.fetchRooms();
    },
    async deleteRoom(roomId) {
      const room = this.rooms.find((r) => r.roomId === roomId);
      if (
        room.users.find((user) => user.id === 'SGmFnBZB4xxMv9V4CVlW')
     || room.users.find((user) => user.id === '6jMsIXUrBHBj7o2cRlau')
      ) {
        await this.$confirm('Nope, for demo purposes you cannot delete this room');
      }
      this.getMessages(roomId).then(({ data }) => {
        data.forEach((message) => {
          this.deleteMessage(roomId, message.id);
          if (message.files) {
            message.files.forEach((file) => {
              this.deleteFile(this.currentUserId, message.id, file);
            });
          }
        });
      });
      await this.deleteRoom(roomId);
      this.fetchRooms();
    },
    resetForms() {
      this.disableForm = false;
      this.addNewRoom = null;
      this.addRoomUsername = '';
      this.inviteRoomId = null;
      this.invitedUsername = '';
      this.removeRoomId = null;
      this.removeUserId = '';
    },
    // ,incrementDbCounter(type, size) {
    //     size = size || 1
    //     this.dbRequestCount += size
    //     console.log(type, size)
    // }
  },
};
</script>
<style lang="scss" scoped>
.window-container {
    width: 100%;
}
.window-mobile {
    form {
    padding: 0 10px 10px;
    }
}
form {
    padding-bottom: 20px;
}
input {
    padding: 5px;
    width: 140px;
    height: 21px;
    border-radius: 4px;
    border: 1px solid #d2d6da;
    outline: none;
    font-size: 14px;
    vertical-align: middle;
    &::placeholder {
    color: #9ca6af;
    }
}
button {
    background: #1976d2;
    color: #fff;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 12px;
    margin-left: 10px;
    border: none;
    font-size: 14px;
    transition: 0.3s;
    vertical-align: middle;
    &:hover {
    opacity: 0.8;
    }
    &:active {
    opacity: 0.6;
    }
    &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
    }
}
.button-cancel {
    color: #a8aeb3;
    background: none;
    margin-left: 5px;
}
select {
    vertical-align: middle;
    height: 33px;
    width: 152px;
    font-size: 13px;
    margin: 0 !important;
}
</style>
