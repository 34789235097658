<template>
  <div data-cy="chat-box" class="custom-textarea">
    <div class="textarea-content">
      <Mentionable
        :keys="['@']"
        :items="items"
        :limit="1000"
        insert-space
        class="mentionable"
        @keydown.enter.prevent
        @open="agentListOpen = true"
        @close="agentListOpen = false"
      >
        <template #no-result>
          <div class="dim">No users online</div>
        </template>
        <!-- <HtmlTextBox ref="inputRef" :items="items" id="chat-input" v-model="text" class="input"
          placeholder="New message" @keydown="onKeydown" autofocus /> -->
        <textarea ref="inputRef" :items="items" id="chat-input" v-model="text" class="input"
        placeholder="New message" autofocus />
        <template #item="{ item }">
          <div class="user">
            <span class="dim">
              {{ item.label }}
            </span>
          </div>
        </template>
      </Mentionable>
    </div>
  </div>
</template>

<script>
import { Mentionable } from 'vue-mention';
import axios from 'axios';
import HtmlTextBox from './HtmlTextBox.vue';

export default {
  name: 'ChatBox',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HtmlTextBox,
    Mentionable,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Type something...',
    },
  },
  data() {
    return {
      text: this.value,
      agentListOpen: false,
      users: [],
    };
  },
  computed: {
    items() {
      return this.users.map((user) => ({
        value: user.chatUsername,
        label: user.displayName,
      }));
    },
  },
  watch: {
    value(newVal) {
      this.text = newVal;
    },
    text(newVal) {
      this.$emit('input', newVal);
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const { data } = await axios.get('/api/messages/chat-users');
      this.users = data;
    },
    onInput(value) {
      this.text = value;
    },
    removeChip(index) {
      this.chips.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.custom-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
}
.textarea-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px;
  min-height: 56px;
}
.input-area {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  resize: none;
  min-height: 36px;
}
.chip {
  margin: 2px;
}
</style>
