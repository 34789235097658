<template>
  <v-list
    nav
    dense
  >
  <div
        v-for="(menuItem, index) in items"
        :key="menuItem.name"
    >
      <v-list-item
        v-if="!menuItem.subLinks"
        class="mb-2"
        color="primary"
        link
        :to="{ name: menuItem.pathName }"
        @click="menuClick(menuItem)"
        :data-cy="menuItem.name"
        :class="getMenuClass(menuItem, index)"
      >
        <v-list-item-icon>
          <v-icon
            v-if="menuItem.icon"
            color="primary"
          >
            {{ menuItem.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="primary--text">
          {{ $t(menuItem.name) }}
        </v-list-item-title>
      </v-list-item>
      <v-list-group
        v-else
        :key="menuItem.name"
        no-action
        :prepend-icon="menuItem.icon"
      >
        <template v-slot:activator>
          <v-list-item-title :color="$vuetify.theme.isDark ? 'primary' : 'secondary'">
            {{ $t(menuItem.name) }}
          </v-list-item-title>
        </template>

        <v-list-item
        :color="$vuetify.theme.isDark ? 'primary' : 'secondary'"
            v-for="subLink in menuItem.subLinks"
            :to="{ name: subLink.pathName }"
            :key="subLink.name"
            link
        >
            <v-list-item-title color="secondary">{{ $t(subLink.name) }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
// import { mdiHome } from '@mdi/js';
import menuItems from '../menuItems';

export default {
  mixins: [menuItems],
  computed: {
    items() {
      const items = this.menuItems;
      return items;
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    getMenuClass(item, index) {
      if (this.isDesktop) {
        return `slide-in-top delay-${index + 1}`;
      }
      return '';
    },
    menuClick(item) {
      if (item.cmd) {
        item.cmd();
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
</style>
