<template>
  <AdminCard label="Manage the DJ's and their info">
    <v-data-table
      elevation-1
      :headers="headers"
      :items="presenters"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30],
        'items-per-page-text': 'Rows per page'
      }"
    >
    <template v-slot:top>
      <v-btn color="primary" @click="create" small>Create DJ</v-btn>
      <ModalPresenter
        :show="showPresenterModal"
        :presenter="presenter"
        @close="showPresenterModal = false"
        @refresh="refresh"
      />
    </template>
    <template v-slot:item.featuredImageUrl="{ item }">
      <div style="padding: 5px">
        <v-img
          :src="item.featuredImageUrl"
          width="50"
          height="50"
          contain
        ></v-img>
      </div>
    </template>
    <template v-slot:item.socials="{ item }">
      <template v-if="item.faceBook">
        <a :href="item.faceBook" target="_blank">
          <v-icon>{{ mdiFacebook }}</v-icon>
        </a>
      </template>
      <template v-if="item.instagram">
        <a :href="item.instagram" target="_blank">
          <v-icon>{{ mdiInstagram }}</v-icon>
        </a>
      </template>
      <template v-if="item.linkedIn">
        <a :href="item.linkedIn" target="_blank">
          <v-icon>{{ mdiLinkedin }}</v-icon>
        </a>
      </template>
      <template v-if="item.twitter">
        <a :href="item.twitter" target="_blank">
          <v-icon>{{ mdiTwitter }}</v-icon>
        </a>
      </template>
      <template v-if="item.youTube">
        <a :href="item.youTube" target="_blank">
          <v-icon>{{ mdiYoutube }}</v-icon>
        </a>
      </template>
    </template>
    <template v-slot:item.website="{ item }">
      <a :href="item.website" target="_blank">
        <v-icon>{{ mdiWeb }}</v-icon>
      </a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
      <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
    </template>
    </v-data-table>
</AdminCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiFacebook,
  mdiInstagram,
  mdiLinkedin,
  mdiTwitter,
  mdiYoutube,
  mdiWeb,
  mdiPencil,
  mdiDelete,
} from '@mdi/js';
import ModalPresenter from './ModalPresenter.vue';
import loading from '../mixins/loading';

/**
 * @typedef {Object} AdminPresenterCard
 */

export default {
  components: {
    ModalPresenter,
  },
  mixins: [loading],
  data() {
    return {
      headers: [
        {
          value: 'featuredImageUrl',
          sortable: false,
          width: '10%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: 'Name',
          value:
        'name',
          sortable: true,
          width: '15%',
          divider: true,
        },
        {
          text: 'Bio',
          value: 'description',
          sortable: true,
          width: '45%',
          divider: true,
        },
        {
          text: 'Socials',
          value: 'socials',
          sortable: false,
          width: '20%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '10%',
          align: 'right',
          filterable: false,
          divider: true,
        },
      ],
      search: '',
      presenter: {},
      showPresenterModal: false,
      mdiFacebook,
      mdiInstagram,
      mdiLinkedin,
      mdiTwitter,
      mdiYoutube,
      mdiWeb,
      mdiPencil,
      mdiDelete,
    };
  },
  computed: {
    ...mapState('presenters', ['presenters']),
  },
  methods: {
    ...mapActions('presenters', ['LOAD_presenters', 'DELETE_presenter']),
    create() {
      this.presenter = {
        name: '',
        description: '',
        featuredImageUrl: '',
        faceBook: '',
        instagram: '',
        linkedIn: '',
        twitter: '',
        youTube: '',
        website: '',
      };
      this.showPresenterModal = true;
    },
    edit(presenter) {
      this.presenter = presenter;
      this.showPresenterModal = true;
    },
    async remove(presenter) {
      const result = await this.$confirm(`Are you sure you want to delete ${presenter.name}?`);

      if (!result) {
        // return if the user caneclled the delete
        return;
      }
      try {
        await this.DELETE_presenter(presenter.id);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_presenters();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
