<template>
  <v-container fluid>
    <h2>P-Fore Online Admin</h2>
    <v-tabs>
      <v-tab v-for="(tab, index) in tabs" :key="index" :disabled="!tab.isActive">
        {{ tab.name }}
      </v-tab>
      <v-tab-item v-for="(tab, index) in tabs" :key="index" style="padding: 10px">
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminArticlesCard from '../components/AdminArticlesCard.vue';
import AdminBroadcastSchedulesCard from '../components/AdminBroadcastSchedulesCard.vue';
import AdminEventsCard from '../components/AdminEventsCard.vue';
import AdminPresentersCard from '../components/AdminPresentersCard.vue';
import AdminUsers from '../components/AdminUsers.vue';
import AdminGalleryCard from '../components/AdminGalleryCard.vue';
import AdminProducts from '../components/AdminProducts.vue';
import AdminPlayback from '../components/AdminPlayback.vue';

export default {
  name: 'AppAdmin',

  components: {
    AdminProducts,
    AdminArticlesCard,
    AdminGalleryCard,
    AdminBroadcastSchedulesCard,
    AdminEventsCard,
    AdminPlayback,
    AdminPresentersCard,
    AdminUsers,
  },

  computed: {
    ...mapGetters('auth', ['hasClaimType']),
    tabs() {
      return [
        {
          name: 'Articles',
          component: 'AdminArticlesCard',
          isActive: this.hasClaimType('articles'),
        },
        {
          name: 'Gallery',
          component: 'AdminGalleryCard',
          isActive: this.hasClaimType('gallery-events'),
        },
        {
          name: 'Events',
          component: 'AdminEventsCard',
          isActive: this.hasClaimType('scheduled-events'),
        },
        {
          name: 'Broadcast Schedules',
          component: 'AdminBroadcastSchedulesCard',
          isActive: this.hasClaimType('broadcast'),
        },
        {
          name: 'Playback',
          component: 'AdminPlayback',
          isActive: this.hasClaimType('playback'),
        },
        {
          name: 'Presenters',
          component: 'AdminPresentersCard',
          isActive: this.hasClaimType('presenters'),
        },
        {
          name: 'Products',
          component: 'AdminProducts',
          isActive: true,
        },
        {
          name: 'Users',
          component: 'AdminUsers',
          isActive: this.hasClaimType('users'),
        },
      ];
    },
  },
};
</script>
