<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title>{{ mode }} Presenter</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="form.name"
            label="Name"
            required
          ></v-text-field>
          <v-textarea
            v-model="form.description"
            label="Bio"
            required
          ></v-textarea>
          <ImageUpload @input="featuredImageUploaded"/>
          <v-img
            v-if="form.featuredImageUrl && form.featuredImageUrl.length > 0"
            :src="form.featuredImageUrl"
            height="200px"
            contain
          />
          <v-text-field
            v-model="form.faceBook"
            label="Facebook URL"
          ></v-text-field>
          <v-text-field
            v-model="form.instagram"
            label="Instagram URL"
          ></v-text-field>
          <v-text-field
            v-model="form.linkedIn"
            label="LinkedIn URL"
          ></v-text-field>
          <v-text-field
            v-model="form.twitter"
            label="Twitter URL"
          ></v-text-field>
          <v-text-field
            v-model="form.youTube"
            label="YouTube URL"
          ></v-text-field>
          <v-text-field
            v-model="form.website"
            label="Website URL"
          ></v-text-field>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isSaving" color="primary" type="submit">
              {{ mode }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ImageUpload from './ImageUpload.vue';
import loading from '../mixins/loading';

/**
 * A Vue component that displays a modal for creating/editing a new presenter.
 */
export default {
  name: 'ModalPresenter',
  mixins: [loading],
  components: {
    ImageUpload,
  },
  props: {
    presenter: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        featuredImageUrl: '',
        faceBook: '',
        instagram: '',
        linkedIn: '',
        twitter: '',
        youTube: '',
        website: '',
      },
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    mode() {
      return this.presenter.id ? 'Update' : 'Create';
    },
  },
  watch: {
    presenter: {
      immediate: true,
      deep: true,
      handler() {
        this.form = this.presenter;
      },
    },
  },
  mounted() {
    this.form = this.presenter;
  },
  methods: {
    ...mapActions('presenters', ['CREATE_presenter', 'UPDATE_presenter']),
    featuredImageUploaded(file) {
      this.presenter.featuredImageUrl = `${this.baseUrl}/api/gallery/${file.id}`;
    },
    /**
     * Submit the form data to the backend.
     */
    async submit() {
      // validate form data
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        if (this.presenter.id) {
        // Update the existing presenter
          await this.UPDATE_presenter(this.presenter);
        } else {
        // Create a new presenter
          await this.CREATE_presenter(this.presenter);
        }
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
