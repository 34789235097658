<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title>Create Event</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <ImageUpload @input="featuredImageUploaded" />
          <div v-for="(form, index) in forms" :key="index">
            <v-img
              v-if="form.imageUrl && form.imageUrl.length > 0"
              :src="form.imageUrl"
              height="200px"
              contain
            />
          </div>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isSaving" color="primary" type="submit">
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ImageUpload from './MultiImageUpload.vue';
import loading from '../mixins/loading';

export default {
  name: 'ImageModal',
  mixins: [loading],
  components: {
    ImageUpload,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forms: [],
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  methods: {
    ...mapActions('eventImages', ['CREATE_eventImages']),
    featuredImageUploaded(file) {
      try {
        this.forms = file.map((image) => ({
          imageUrl: `${this.baseUrl}/api/gallery/${image.id}`,
        }));
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    async submit() {
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        const eventImages = []; // Array to store multiple event images
        for (let i = 0; i < this.forms.length; i += 1) {
          eventImages.push({
            eventId: this.eventId,
            imageUrl: this.forms[i].imageUrl,
          });
        }
        await this.CREATE_eventImages(eventImages);
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
