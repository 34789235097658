<template>
  <div>
    <!-- Display the avatar or default icon -->
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-avatar
          size="130"
          color="red"
          @click="dialog = true"
          class="d-flex align-center justify-center"
          >
            <v-progress-circular
              v-if="isSaving"
              indeterminate
              color="primary"
            />
            <img v-if="!hover && avatar" :src="avatar">
            <v-icon v-if="!hover && !avatar" size="60">{{ mdiAccount }}</v-icon>
            <v-icon
              v-if="hover"
              size="30"
              title="Upload a new profile photo"
              @click="onUploadClick"
            >
              {{ mdiCamera }}
            </v-icon>
        </v-avatar>
      </template>
    </v-hover>
    <!-- Hidden file input for avatar upload -->
    <input
      type="file"
      ref="fileInput"
      accept="image/*"
      @change="onFileChange"
      style="display: none"
    >
  </div>
</template>

<script>
import { mdiAccount, mdiCamera } from '@mdi/js';

export default {
  name: 'Avatar',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      hover: false,
      avatar: null,
      isSaving: false,
      mdiAccount,
      mdiCamera,
      file: undefined,
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  watch: {
    value() {
      this.avatar = this.value;
    },
  },
  mounted() {
    this.avatar = this.value;
  },
  methods: {
    onUploadClick() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.file = file;
        this.save();
      }
    },
    // called when submit is clicked
    async save() {
      try {
        this.isSaving = true;
        const formData = new FormData();
        formData.append('Gallery', this.file, this.file.name);
        const { data } = await this.$http.post('api/gallery', formData, {});
        const imageUrl = `${this.baseUrl}/api/gallery/${data.id}`;
        this.$emit('input', imageUrl);
        this.$emit('save');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$root.$emit('toast:error', 'Error uploading image');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
