<template>
  <v-container fluid>
    <section class="bgImage">
      <v-img src="../assets/image-38-copyright-min.jpg">
      </v-img>
    </section>
    <section>
      <DJ/>
    </section>
  </v-container>
</template>

<script>
import DJ from '../components/PresenterDj.vue';

export default {
  name: 'CrewInfo',
  module: 'CrewInfo',
  components: {
    DJ,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .bgImage {
  background-image: url('../assets/image-38-copyright-min.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 200px;
}
}
// desktop view
.bgImage {
  background-image: url('../assets/image-38-copyright-min.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
</style>
