<!-- eslint-disable global-require -->
<template>
  <v-container class="home-background" fluid>
    <section id="banners" class="home-page-banners">
      <v-carousel hide-delimiters height="100%">
        <v-carousel-item
          v-for="(item,i) in images"
          :key="i"
        >
          <router-link :to="{name: 'auth-Register'}">
            <v-img :src="item.src" width="100%" height="100%"
              class="white--background"
              :stretch="!isMobile" :contain="isMobile"
            >
              <v-row class="fill-height">
                <v-col cols="12">
                  <v-btn v-if="!isMobile" large fab class="chatButton">
                    <router-link :to="{name: 'app-Stream'}">
                      <v-icon>{{ mdiPlay }}</v-icon>
                    </router-link>
                  </v-btn>
                  <v-btn v-if="isMobile" fab small class="chatButtonMobile">
                    <router-link :to="{name: 'app-Stream'}">
                      <v-icon>{{ mdiPlay }}</v-icon>
                    </router-link>
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </router-link>
        </v-carousel-item>
      </v-carousel>
    </section>
    <section v-if="currentAndNextScheduleEvent.length > 0" class="eventWrap mt-5">
      <v-card>
        <v-card-title primary-title>
          Upcoming Events
        </v-card-title>
        <v-card-text>
          <EventsDisplay />
        </v-card-text>
      </v-card>
    </section>
    <section class="mt-5">
      <BroadcastCalendar :disabled="true" />
    </section>
  </v-container>
</template>

<script>
import {
  mdiHome, mdiPlay,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapGetters, mapState } from 'vuex';
import EventsDisplay from '../components/EventsDisplay.vue';
import ObserverSection from '../components/ObserverSection.vue';
import BroadcastCalendar from '../components/BroadcastCalendar.vue';
import content from './content';

export default {
  name: 'Home',
  module: 'Home',
  components: {
    EventsDisplay,
    // eslint-disable-next-line vue/no-unused-components
    ObserverSection,
    // eslint-disable-next-line vue/no-unused-components
    BroadcastCalendar,
  },
  computed: {
    ...mapState('broadcastSchedules', ['broadcastSchedules']),
    ...mapGetters('broadcastSchedules', ['currentAndNextBroadcastSchedule']),
    ...mapGetters('scheduledEvents', ['currentAndNextScheduleEvent']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    images() {
      if (this.isMobile) {
        return [
        // eslint-disable-next-line global-require
          { src: require('../assets/banners/banner0-mobile.png') },
        ];
      }
      return [
        // eslint-disable-next-line global-require
        { src: require('../assets/banner-home.png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner1 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner2 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner3 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner4 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner5 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner6 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner7 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner8 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner9 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner10 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner11 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner12 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner13 (Custom).png') },
        // eslint-disable-next-line global-require
        { src: require('../assets/Banner14 (Custom).png') },
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  // mounted() {
  //   const root = document.querySelector(':root');
  // eslint-disable-next-line max-len
  //   root.style.setProperty('--image1', `url(${this.baseUrl}/api/gallery/11a64082-fd57-418e-847e-649cbf17a133)`);
  // eslint-disable-next-line max-len
  //   root.style.setProperty('--image2', `url(${this.baseUrl}/api/gallery/e35cc7e5-1c21-407a-9780-054317a9be22)`);
  // },
  mixins: [content, loading],
  data() {
    return {
      selectedItem: null,
      model: 0,
      mdiHome,
      mdiPlay,
      part: '',
      i: 0,
      offset: 0,
      len: 0,
      forwards: true,
      skip_count: 0,
      skip_delay: 15,
      speed: 70,
      section1: false,
    };
  },
  methods: {
    showImage(i) {
      this.selectedItem = this.activeJobs[i];
    },
  },
};
</script>
<style>
.home-background {
  object-fit: fill;
  background-image: url('../assets/HomePagePic.jpg') !important;
  background-size: 100% 100%;
  padding: 0px;
}
.demoCarousel {
  position: relative;
  margin: auto;
}
.eventWrap {
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 12px;
}
.playButton {
  right: 10%;
  position: absolute;
  margin: 0 0 16px 16px;
}
.mainCard::before {
  content: "";
  background-image: url('../assets/timthumb (2).jpeg') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
  box-shadow: 0px 0px 45px 40px black inset;
}
.secondCard {
  background-color: black !important;

}
.secondCard::before {
  content: "";
  background-image: url('../assets/timthumb.jpeg') !important;
  background-size: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
  box-shadow: 0px 0px 45px 40px black inset;
}
@media only screen and (max-width: 600px) {
  .eventWrap {
    padding: 12px;
  }
  .demoCarousel {
    position: relative;
  }
  .eventHeader {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 15%;
  }
  .eventBody {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .eventDate:hover {
    color: rgb(0, 225, 254);
    cursor: pointer;
  }
  .playButton {
    right: 10%;
    position: absolute;
    margin: 0 0 16px 16px;
  }
  .mainCard::before {
    content: "";
    background-image: url('../assets/timthumb (2).jpeg') !important;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;
    box-shadow: 0px 0px 45px 40px black inset;
  }
  .secondCard {
    background-color: black !important;
  }
  .secondCard::before {
    content: "";
    background-image: url('../assets/timthumb.jpeg') !important;
    background-size: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;
    box-shadow: 0px 0px 45px 40px black inset;
  }
  .chatButtonMobile{
  position: absolute;
  right: 15%;
  bottom: 39%;
  outline-color: azure !important;
}
}
.chatButton{
  position: absolute;
  right: 10%;
  bottom: 50%;
  outline-color: azure !important;
}
@media only screen and (min-width: 600px) {
  .home-page-banners {
    padding: 12px;
  }
}

</style>
