<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          label="Birthday date"
          :prepend-inner-icon="mdiCalendar"
          dense
          outlined
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="(new Date(Date.now() - (new Date())
            .getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js';

export default {
  name: 'DateOfBirthPicker',
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
    mdiCalendar,
  }),
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => { this.activePicker = 'YEAR'; }, 10);
      }
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit('save-birthday', date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
