<template>
  <div data-cy="FileUpload-input">
    <div class="container">
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmit"
        lazy-validation
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-icon color="error" v-bind="attrs" v-on="on">{{ mdiExclamationThick }}</v-icon>
          </template>
          <span>After an image has been selected, Please click the 'Upload' button.</span>
        </v-tooltip>
        <div class="form-group">
          <v-file-input
            label="Upload new photo"
            @change="onFileUpload"
            truncate-length="15"
            :accept="accept"
            :rules="requiredRules"
            />
        </div>
        <div class="form-group">
          <v-btn color="secondary" @click="onSubmit">Upload</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mdiCamera, mdiExclamationThick } from '@mdi/js';
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'ImageUpload',
  props: {
    /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: 'image/*',
    },
    /** Error message for max file size. Defaults to File is larger than the limit of 2Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 5Mb',
    },
    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: mdiCamera,
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Image',
    },
    /** Max file size. Defaults to 5000000 (5Mb).
    */
    maxFileSize: {
      type: Number,
      default: 5000000,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  data() {
    return {
      valid: true, // form validation
      errors: [], // array to store errors
      file: null, // file object
      name: '', // file name
      mdiExclamationThick,
      requiredRules: [
        (v) => !!v || 'This field is required',
      ],
    };
  },
  methods: {
    ...mapActions('galleries', ['LOAD_images']),
    // called when file is selected by the input control
    onFileUpload(event) {
      this.file = event;
    },
    // called when submit is clicked
    async onSubmit() {
      this.$refs.form.validate();
      const formData = new FormData();
      formData.append('Gallery', this.file, this.file.name);
      const { data } = await axios.post('api/gallery', formData, {});
      this.$emit('input', data);
    },
  },
};
</script>
