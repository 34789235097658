<template>
  <div class="chat-container">
    <div ref="messages" class="chat-messages">
      <div
      v-for="message in activeMessages" :key="message.id"
        class="chat-message d-flex mb-2 pa-2 w-full"
        :class="{ 'chat-active': isActiveChat(message)}"
      >
        <div class="d-flex flex-column justify-space-between flex-grow-1">
          <div class="chat-message-user">{{ getName(message) }}</div>
          <div class="chat-message-text">{{ message.data }}</div>
          <div class="chat-message-time">{{ message.processedCreated }}</div>
        </div>
        <div class="d-flex flex-column justify-space-between fill-height chat-buttons">
          <ReplyButton class="hover-button" @click="reply(message)" />
          <LikeButton class="hover-button" :likes="message.likes" @click="addLike(message)" />
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn" class="chat-send pl-2 pr-2">
      <ChatConsentDialog
        v-if="user.chatConsent === null || user.chatConsent === false"
      />
      <ChatRegistration
        v-else-if="user.chatUsername === null || user.chatUsername === ''"
      />
      <div v-else class="w-full d-flex">
        <ChatBox
            v-model="newMessage"
            class="w-100 flex-1"
            placeholder="Type your message here..."
            :users="activeUsers"
            rows="3"
            auto-grow
            shaped
            @keydown.enter="sendMessage"
        />
        <div class="d-flex flex-column align-center justify-center">
          <v-menu top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small>
                    <v-icon color="grey darken-2">{{ mdiEmoticon }}</v-icon>
                </v-btn>
              </template>
              <emoji-picker @emoji-click="insertEmoji"></emoji-picker>
          </v-menu>
          <v-btn rounded icon small @click="sendMessage" :disabled="newMessage?.length < 1">
              <v-icon color="primary">{{ mdiArrowRightCircle }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="chat-send pl-2 pr-2 flex-column text-center p-2">
      <span class="text-subtitle-2">You need to be logged in to send messages</span>
      <router-link to="/register">Click here to register</router-link>
    </div>
    <v-alert
      v-if="showSent"
      class="mt-2 slide-in fade-out"
      rounded
      dense
      text
      type="success"
    >
      <span class="text-subtitle-2">Message sent</span>
    </v-alert>
    <v-alert
      v-if="showError"
      class="mt-2 slide-in fade-out"
      :icon="mdiAlertCircleOutline"
      rounded
      dense
      text
      dismissible
      type="error"
    >
      <span class="text-subtitle-2">Error sending message</span>
  </v-alert>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { uniq, sortBy } from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  mdiEmoticon,
  mdiArrowRightCircle,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiAlertCircleOutline,
  mdiDotsVertical,
} from '@mdi/js';
import * as signalR from '@aspnet/signalr';
import ChatBox from './ChatBox.vue';
import ChatConsentDialog from './ChatConsentDialog.vue';
import ChatRegistration from './ChatRegistration.vue';
import LikeButton from './LikeButton.vue';
import ReplyButton from './ReplyButton.vue';

dayjs.extend(relativeTime);

export default {
  name: 'Chat',
  components: {
    ChatBox,
    ChatConsentDialog,
    ChatRegistration,
    LikeButton,
    ReplyButton,
  },
  data() {
    return {
      mdiArrowRightCircle,
      mdiEmoticon,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      mdiAlertCircleOutline,
      mdiDotsVertical,
      connection: null,
      messages: [],
      newMessage: '',
      showError: false,
      showSent: false,
      isSending: false,
      syncTimer: undefined,
    };
  },
  computed: {
    ...mapState('auth', ['userId', 'user']),
    ...mapGetters('auth', ['isLoggedIn']),
    activeMessages() {
      return this.messages.map((m) => ({
        ...m,
        processedCreated: dayjs(m.created).fromNow(),
      }));
    },
    activeUsers() {
      const users = this.messages.map((m) => ({
        displayName: m.displayName,
        userId: m.userId,
      })).filter((u) => u.userId !== this.userId && u.displayName !== null && u.displayName !== '');

      const userIds = users.map((u) => u.userId);
      const uniqueUserIds = uniq(userIds);

      const uniqueUsers = uniqueUserIds.map((id) => {
        const user = users.find((u) => u.userId === id);
        return user;
      });
      return sortBy(uniqueUsers, 'displayName');
    },
  },
  async created() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_APIURL}/chatHub`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    await this.connection.start();
    this.connection.on('ReceiveMessage', this.handleMessageReceived);
    this.connection.on('UpdatedMessage', this.handleMessageUpdate);
  },
  mounted() {
    this.refresh();
    this.syncTimer = setInterval(this.refresh, 10000);
  },
  beforeDestroy() {
    this.connection.stop();
    clearInterval(this.syncTimer);
  },
  methods: {
    ...mapActions('auth', ['LOAD_user']),
    async addLike(message) {
      await this.$http.post(`api/messages/${message.id}/like`);
      await this.loadMessages();
    },
    isActiveChat(message) {
      const userTag = `@${this.user?.chatUsername}`;
      if (message.data.includes(userTag)) {
        return true;
      }
      return false;
    },
    async reply(message) {
      this.newMessage = `@${message.chatUsername} `;
      this.$refs.newMessage.focus();
    },
    async refresh() {
      await Promise.all([
        (async () => {
          await this.LOAD_user();
        })(),
        (async () => {
          await this.loadMessages();
        })(),
      ]);
    },
    async loadMessages() {
      const { data } = await this.$http.get('api/messages');
      this.messages = data;
      await this.$nextTick();
      this.scrollChat();
    },
    scrollChat() {
      if (!this.$refs.messages) {
        return;
      }
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
    },
    getName(message) {
      if (!message.displayName || message.displayName === '') {
        return message.name;
      }
      return message.displayName;
    },
    handleMessageReceived(message) {
      this.messages.push(message);
      this.$nextTick(() => {
        this.scrollChat();
      });
    },
    handleMessageUpdate(message) {
      const index = this.messages.findIndex((m) => m.id === message.id);
      if (index > -1) {
        this.messages.splice(index, 1, message);
      }
    },
    insertEmoji(event) {
      this.newMessage += event.detail.unicode;
    },

    async sendMessage() {
      this.isSending = true;
      try {
        this.showError = false;
        this.showSent = false;
        await this.$http.post('api/messages', {
          userId: this.userId,
          data: this.newMessage,
          messageType: 'chat',
          chatUsername: this.user.chatUsername,
        });
        this.newMessage = '';
        this.showSent = true;
        setTimeout(() => {
          this.showSent = false;
        }, 1500);
      } catch (error) {
        this.showError = true;
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>

<style>
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  color: #333;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

.chat-messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.chat-message {
  border-radius: 5px;
  color: #333;
  background-color: #fff;
  min-height: 100px;
}

.chat-message-user {
  font-weight: bold;
  margin-bottom: 5px;
}

.chat-message-time {
  font-size: 0.5em;
  color: #666;
}

.chat-send {
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: 25px;
  border: 1px solid #ccc;
  min-height: 80px;
}

.chat-active {
  background-color: #e8b017;
  background-image: linear-gradient(45deg, #dae817 0%, #e8b017 74%);
}
/*
.hover-button {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.hover-button:hover {
  opacity: 1;
} */
</style>
