<template>
  <v-data-table
    :headers="headers"
    :items="photos"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="Photo-button-new"
          @click="$router.push({
            name: 'cms-EditPhoto'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'cms-EditPhoto',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListPhoto',
  module: 'Cms',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('photos', ['photos']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('fileUrl'),
          value: 'fileUrl',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_photos();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('photos', ['LOAD_photos', 'DELETE_photo']),
    /** Delete Photo from the collection
     */
    async remove(photo) {
      this.isLoading = true;
      await this.DELETE_photo(photo.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Photo example

'''js
<Photo />
'''
</docs>
