<template>
  <v-container fluid>
      <v-card>
        <v-card-title primary-title>
          Privacy Policy
        </v-card-title>
        <v-card-text>
          <div v-html="content"></div>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
import showdown from 'showdown';
import PrivacyPolicy from '../PrivacyPolicy.md';

showdown.setFlavor('original');

export default {
  computed: {
    content() {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(PrivacyPolicy);
      return html;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>

</style>
