import axios from 'axios';

const actions = {
  async CREATE_bannedUser({ commit, dispatch }, user) {
    const response = await axios.post('/api/banUser', {
      userId: user.id,
    });
    commit('SET_bannedUser', response.data);
    dispatch('LOAD_bannedUsers');
    return response.data;
  },

  async LOAD_bannedUser({ commit }, userId) {
    const response = await axios.get(`/api/banUser/banUserCheck/${userId}`);
    commit('SET_bannedUser', response.data);
    return response.data;
  },

  async LOAD_bannedUsers({ commit }) {
    const response = await axios.get('/api/banUser');
    commit('SET_bannedUsers', response.data);
    return response.data;
  },

  async DELETE_bannedUser({ dispatch }, userId) {
    const response = await axios.delete(`/api/banUser/${userId}`);
    dispatch('LOAD_bannedUsers');
    return response.data;
  },
};

export default { ...actions };
