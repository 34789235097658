<template>
    <v-dialog v-model="show" fullscreen>
      <v-card>
        <v-card-title class="headline">{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <ImageUpload @input="featuredImageUploaded" />
                <v-img
                :src="form.featuredImageUrl"
                height="200px"
                contain
              />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DatePicker v-model="form.startDate" label="Start Date" :rules="[rules.required]" />
              </v-col>
              <v-col>
                <TimePicker v-model="form.startTime" label="Start Time" :rules="[rules.required]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DatePicker v-model="form.endDate" label="End Date" :rules="[rules.required]" />
              </v-col>
              <v-col>
                <TimePicker v-model="form.endTime" label="End Time" :rules="[rules.required]" />
              </v-col>
            </v-row>
                        <v-row>
            <v-col cols="12">
                Event name:
                <v-text-field
                v-model="form.eventText"
                :rules="[rules.required]"
                >
                </v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
                Details: <v-text-field v-model="form.detailsText"></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
                Facebook Link: <v-text-field v-model="form.facebookText"></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
                Instagram Link: <v-text-field v-model="form.instagramText"></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
                Twitter Link: <v-text-field v-model="form.twitterText"></v-text-field>
            </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="isSaving" color="primary" @click="submit">
          {{ mode }}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import ImageUpload from './ImageUpload.vue';
import loading from '../mixins/loading';
import validation from './validation';

export default {
  name: 'EventScheduleModal',
  mixins: [loading, validation],
  props: {
    dialog: Boolean,
    formTitle: String,
    show: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    ImageUpload,
  },
  data() {
    return {
      form: {
        id: undefined,
        featuredImageUrl: '',
        iconImageUrl: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        eventText: '',
        detailsText: '',
        facebookText: '',
        instagramText: '',
        twitterText: '',
      },
    };
  },
  computed: {
    mode() {
      return this.event?.id ? 'Update' : 'Create';
    },
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  watch: {
    event: {
      immediate: true,
      deep: true,
      handler() {
        this.updateLocalEvent();
      },
    },
  },
  methods: {
    ...mapActions('scheduledEvents', ['CREATE_scheduledEvent', 'UPDATE_scheduledEvent']),
    updateLocalEvent() {
      this.form.id = this.event?.id ?? undefined;
      this.form.featuredImageUrl = this.event?.featuredImageUrl ?? undefined;
      this.form.eventText = this.event?.eventName ?? undefined;
      this.form.detailsText = this.event?.summary ?? undefined;
      this.form.facebookText = this.event?.facebookLink ?? undefined;
      this.form.instagramText = this.event?.instagramLink ?? undefined;
      this.form.twitterText = this.event?.twitterLink ?? undefined;
      this.form.startDate = this.formatDate(this.event?.eventDateStart ?? new Date());
      this.form.startTime = this.formatTime(this.event?.eventDateStart ?? new Date());
      this.form.endDate = this.formatDate(this.event?.eventDateEnd ?? new Date());
      this.form.endTime = this.formatTime(this.event?.eventDateEnd ?? new Date());
    },
    formatTime(date) {
      return dayjs(date).format('HH:mm');
    },
    featuredImageUploaded(file) {
      this.form.featuredImageUrl = `${this.baseUrl}/api/gallery/${file.id}`;
    },
    async submit() {
      // validate form data
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        const { form } = this;
        const event = {
          id: form.id,
          eventName: form.eventText,
          eventDateStart: new Date(`${form.startDate} ${form.startTime}`),
          eventDateEnd: new Date(`${form.endDate} ${form.endTime}`),
          summary: form.detailsText,
          facebookLink: form.facebookText,
          instagramLink: form.instagramText,
          twitterLink: form.twitterText,
          featuredImageUrl: form.featuredImageUrl,
        };
        if (this.form.id) {
        // Update the existing broadcast schedule
          await this.UPDATE_scheduledEvent(event);
        } else {
        // Create a new broadcast schedule
          await this.CREATE_scheduledEvent(event);
        }
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
