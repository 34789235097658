export default {
  data() {
    return {
      /** A boolean property indicating whether data is currently being loaded or not.
       */
      isLoading: false,
      /** A boolean property indicating whether data is currently being saved or not.
        */
      isSaving: false,
    };
  },
};
