<template>
  <v-card>
    <v-card-text>
      <!-- Avatar with upload icon on hover -->
      <EditAvatar v-model="userData.imageUrl" @save="save" />
      <!-- User Profile Fields -->
      <v-text-field outlined dense class="mt-5"
        label="First Name" v-model="userData.firstName" />
      <v-text-field outlined dense
        label="Last Name" v-model="userData.lastName" />
      <v-text-field outlined dense
        label="Email Address" v-model="userData.email" />
      <v-text-field outlined dense
        label="Display Name" v-model="userData.displayName" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <SubmitButton :call="save"></SubmitButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EditAvatar from './EditAvatar.vue';

export default {
  components: {
    EditAvatar,
  },
  computed: {
    ...mapState('auth', ['userId']),
  },
  data() {
    return {
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        displayName: '',
      },
    };
  },
  // on page load get user data according to the user id
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('users', ['UPDATE_user']),
    async refresh() {
      const { userId } = this;
      const response = await this.$http.get(`api/account/user/${userId}`);
      this.userData = response.data;
    },
    // method updates user information one field at a time but works for all fields
    async save() {
      const { userData } = this;

      await this.UPDATE_user(userData);
      await this.refresh();
      this.$root.$emit('profile-updated', this.userData);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
