import axios from 'axios';

const actions = {
  async CREATE_eventImage({ commit }, eventImage) {
    const response = await axios.post('/api/eventImages', eventImage);
    commit('SET_eventImage', response.data);
    return response.data;
  },
  async CREATE_eventImages({ commit }, eventImages) {
    const response = await axios.post('/api/eventImages/PostMultipleImages', eventImages);
    commit('SET_eventImage', response.data);
    return response.data;
  },

  async LOAD_eventImage({ commit }, eventImageId) {
    const response = await axios.get(`/api/eventImages/${eventImageId}`);
    commit('SET_eventImage', response.data);
    return response.data;
  },

  async LOAD_eventImagesByEvent({ commit }, eventId) {
    const response = await axios.get(`/api/eventImages/eventData/${eventId}`);
    commit('SET_eventImages', response.data);
    return response.data;
  },

  async LOAD_eventImages({ commit }) {
    const response = await axios.get('/api/eventImages');
    commit('SET_eventImages', response.data);
    return response.data;
  },

  async DELETE_eventImage({ commit }, eventImageId) {
    const response = await axios.delete(`/api/eventImages/${eventImageId}`);
    commit('SET_eventImage', null);
    return response.data;
  },
};

export default { ...actions };
