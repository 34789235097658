<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          data-cy="button-chat-consent"
          v-bind="attrs"
          v-on="on"
        >
          Click to start chatting
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Consent</v-toolbar-title>
        </v-toolbar>
        <div class="pa-5">
          <div v-html="html" />
          <div class="d-flex">
            <v-btn class="mr-2" color="default" @click="dialog = false">
              I decline
            </v-btn>
            <v-btn color="primary" @click="agree" data-cy="chat-consent-agree">
              I agree
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiClose } from '@mdi/js';
import showdown from 'showdown';
import ConsentText from './ConsentText.md';

showdown.setFlavor('original');

export default {
  name: 'ChatConsentDialog',
  data() {
    return {
      mdiClose,
      markdown: ConsentText,
      dialog: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    html() {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(this.markdown);
      return html;
    },
  },
  methods: {
    ...mapActions('auth', ['UPDATE_user']),
    async agree() {
      try {
        this.isLoading = true;
        const { user } = this;
        user.chatConsent = true;
        await this.UPDATE_user(user);
        this.dialog = false;
        await this.$nextTick();
        this.$emit('consent');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
