<template>
  <v-container>
    <v-row>
      <v-col
        cols="12" md="6" lg="4" xl="3"
        class="slide-in-top"
        v-for="post in articles"
        :key="post.id"
        >
        <router-link style="text-decoration: none;"
          :to="{ name: 'app-Article', query: { id: post.id } }">
          <v-card class="fill-height d-flex flex-column">
            <v-img
              :src="post.featuredImageUrl"
              height="200px"
              stretch
              class="grey lighten-2 flex-grow-0"
              style="width: 100%"
            ></v-img>
            <v-card-title>{{ post.title }}</v-card-title>
            <v-card-text class="flex-grow-1" v-html="post.summary" />
            <v-card-text>{{ articleTimePost(post.created) }}</v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

dayjs.extend(utc);

export default {
  name: 'ArticleSummary',
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    articleTimePost(created) {
      const oneHourLater = dayjs(created);
      const timeDiff = dayjs(oneHourLater).fromNow();
      return timeDiff;
    },
  },
};
</script>
