export default {
  currentAndNextScheduleEvent: (state) => {
    // get the current time
    const now = new Date();

    // filter out broadcasts that have already ended and sort by start time in ascending order
    const upcomingEvents = state.scheduledEvents
      .filter((event) => new Date(event.eventDateStart) > now)
      .sort((a, b) => new Date(a.eventDateStart) - new Date(b.eventDateStart));

    return upcomingEvents;
  },
};
