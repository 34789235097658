import axios from 'axios';

const actions = {
  async CREATE_broadcastSchedule({ commit }, broadcastSchedule) {
    const response = await axios.post('/api/broadcastSchedules', broadcastSchedule);
    commit('SET_broadcastSchedule', response.data);
    return response.data;
  },

  async LOAD_broadcastSchedule({ commit }, broadcastScheduleId) {
    const response = await axios.get(`/api/broadcastSchedules/${broadcastScheduleId}`);
    commit('SET_broadcastSchedule', response.data);
    return response.data;
  },

  async LOAD_broadcastSchedules({ commit }) {
    const response = await axios.get('/api/broadcastSchedules');
    commit('SET_broadcastSchedules', response.data);
    return response.data;
  },

  async DELETE_broadcastSchedule({ commit }, broadcastScheduleId) {
    const response = await axios.delete(`/api/broadcastSchedules/${broadcastScheduleId}`);
    commit('SET_broadcastSchedule', null);
    return response.data;
  },
  async UPDATE_broadcastSchedule({ commit }, broadcastSchedule) {
    const response = await axios.put(`/api/broadcastSchedules/${broadcastSchedule.id}`, broadcastSchedule);
    commit('SET_broadcastSchedule', response.data);
    return response.data;
  },
};

export default { ...actions };
