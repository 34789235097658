<template>
  <v-card class="h-100">
    <v-list shaped>
      <v-card-title>
      Online Users
    </v-card-title>
      <template v-for="(user, i) in allUsers">
        <v-list-item :key="`user-${i}`" :value="user" overflow="scroll">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.displayName }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                <v-icon>{{ mdiDotsVertical }}</v-icon>
              </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in ban"
                  :key="index"
                  @click="handleOptionClick({item, user})"
                >
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-list>
    <v-list shaped>
    <v-card-title>
      Banned Users
    </v-card-title>
        <template v-for="(user, i) in filteredUsers">
          <v-list-item :key="`user-${i}`" :value="user" overflow="scroll">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.displayName }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                  <v-icon>{{ mdiDotsVertical }}</v-icon>
                </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in unban"
                    :key="index"
                    @click="handleOptionClick({item, user})"
                  >
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
    </v-list>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UsersWidget',
  data() {
    return {
      mdiDotsVertical,
      bannedUserIds: [],
      ban: [
        { title: 'Ban user', action: 'banUser' },
      ],
      unban: [
        { title: 'Unban user', action: 'unbanUser' },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['userId', 'username']),
    ...mapState('users', ['allUsers']),
    ...mapState('bannedUsers', ['bannedUsers', 'bannedUser']),
    filteredUsers() {
      const bannedUserIds = this.bannedUsers.map((u) => u.userId);
      return [...this.allUsers].filter((u) => bannedUserIds.includes(u.id));
    },
    LoggedInUsers() {
      // const { isLoggedIn } = this;
      return [...this.allUsers].map((u) => u.userId);
      // return [...this.allUsers].map((u) => u.userId && isLoggedIn === true);
    },
  },
  async mounted() {
    await this.LOAD_bannedUsers();
  },
  methods: {
    ...mapActions('bannedUsers', ['LOAD_bannedUsers', 'CREATE_bannedUser', 'DELETE_bannedUser']),
    handleOptionClick({ item, user }) {
      switch (item.action) {
        case 'banUser':
          this.banUser(user);
          break;
        case 'unbanUser':
          this.unbanUser(user);
          break;
        case 'permaBanUser':
          this.permaBanUser();
          break;
        default:
          break;
      }
    },

    async banUser(user) {
      try {
        await this.CREATE_bannedUser(user);
      } catch (error) {
        throw new Error(error);
      }
    },

    async unbanUser(user) {
      try {
        await this.DELETE_bannedUser(user.id);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
