<template>
    <div class="galleryContainer" :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
      <div
        class="eventContainer mr-5 mb-5"
        v-for="event in playbackItems"
        :key="event.id"
      >
        <v-card min-height="250px" width="300px" class="card1">
            <a :href="event.youTubeUrl" target="blank">
              <YouTubeThumbnail
                :youTubeUrl="event.youTubeUrl"
                height="200px"
                width="300px"
                stretch
                position="top center"
                />
            </a>
            <v-card-title class="cardText text-truncate">{{ event.name }}</v-card-title>
          </v-card>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import YouTubeThumbnail from '../components/YouTubeThumbnail.vue';
import loading from '../mixins/loading';

export default {
  name: 'Playback',

  mixins: [loading],

  components: {
    YouTubeThumbnail,
  },

  computed: {
    ...mapState('playbackItems', ['playbackItems']),
  },

  async mounted() {
    /**
    * Refreshes the list of events on mount
    */
    await this.refresh();
  },

  methods: {
    ...mapActions('playbackItems', ['LOAD_playbackItems']),
    /**
    * Loads the list of events and sets the isLoading flag to true while loading
    *
    * @function
    */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_playbackItems();
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.event-page {
  background-image: url("../assets/image-38-copyright-min.jpg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0;
  padding: 0;

}
.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  padding: 12px;
}
.newEventButton {
  display: flex;
  margin: 5px;
  padding: 12px;
}

.cardText {
  font-size: 1.25em;
  color: #000000;
  font-weight: bold;
}
</style>
