<template>
    <v-data-table
    elevation-1
    :headers="headers"
    :items="images"
    :search="search"
    :items-per-page="10"
    :footer-props="{
      'items-per-page-options': [5, 10, 20, 30],
      'items-per-page-text': 'Rows per page'
    }"
    >
    <template v-slot:top>
        <v-btn color="primary" @click="create">Add Image</v-btn>
        <ImageModal
          :eventId="eventId"
          :show="showImageModal"
          @close="showImageModal = false"
          @refresh="refresh"
        />
      </template>
      <template v-slot:item.imageUrl="{ item }">
        <div style="padding: 5px">
          <v-img
            :src="item.imageUrl"
            width="100"
            height="100"
            contain
          ></v-img>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
      </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mdiDelete } from '@mdi/js';
import ImageModal from './ImageModal.vue';

export default {
  name: 'TableGallery',
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  components: {
    ImageModal,
  },
  computed: {
    ...mapState('eventImages', ['eventImages']),
  },
  data() {
    return {
      headers: [
        {
          text: 'Album Images',
          value: 'imageUrl',
          sortable: false,
          width: '60%',
          divider: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '10%',
          divider: true,
        },
      ],
      image: {},
      images: [],
      search: '',
      showImageModal: false,
      mdiDelete,
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /**
     * @type {import('vuex').Action}
     */
    ...mapActions('eventImages', ['LOAD_eventImagesByEvent', 'DELETE_eventImage']),
    /**
     * Uploads the image and creates an event image.
     * @param {File} file - The uploaded file.
     */
    create() {
      this.image = {
        imageUrl: '',
      };
      this.showImageModal = true;
    },
    async remove(eventImage) {
      const result = await this.$confirm('Are you sure you want to delete this Image?');

      if (!result) {
        // return if the user caneclled the delete
        return;
      }
      try {
        await this.DELETE_eventImage(eventImage.id);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      }
    },
    /**
     * Refreshes the list of event images.
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_eventImagesByEvent(this.eventId);
      this.images = [...this.eventImages];
      this.isLoading = false;
    },
  },
};
</script>
