<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row
          class="profiles"
          v-for="(presenter, i) in presenters"
          :key="i"
        >
          <v-col cols="12" xl="6" lg="6">
              <v-img
              class="profileImage"
              :src="presenter.featuredImageUrl">
              </v-img>
          </v-col>
          <v-col cols="12" xl="6" lg="6">
          <v-row>
            <v-col cols="12">
              <v-card-title class="nameEdit">
                  <p>
                      {{ presenter.name }}
                  </p>
              </v-card-title>
              <v-card-text class="detailsEdit">
                  <p>
                      {{ presenter.description }}
                  </p>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="profileSeparator"
            >
            <div class="buttonPosition">
              <v-btn
                v-if="presenter.faceBook"
                inline-block
                fab
                :href="presenter.faceBook"
                target="_blank"
                class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiFacebook }}
                  </v-icon>
              </v-btn>
              <v-btn
              v-if="presenter.instagram"
              inline-block
              fab
              :href="presenter.instagram"
              target="_blank"
              class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiInstagram }}
                  </v-icon>
              </v-btn>
              <v-btn
              v-if="presenter.twitter"
              inline-block
              fab
              :href="presenter.twitter"
              target="_blank"
              class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiTwitter }}
                  </v-icon>
              </v-btn>
              <v-btn
              v-if="presenter.youTube"
              inline-block
              fab
              :href="presenter.youTube"
              target="_blank"
              class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiYoutube }}
                  </v-icon>
              </v-btn>
              <v-btn
              v-if="presenter.linkedIn"
              inline-block
              fab
              :href="presenter.linkedIn"
              target="_blank"
              class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiLinkedin }}
                  </v-icon>
              </v-btn>
              <v-btn
              v-if="presenter.website"
              inline-block
              fab
              :href="presenter.website"
              target="_blank"
              class="buttonSingular"
              >
                  <v-icon>
                      {{ mdiWeb }}
                  </v-icon>
              </v-btn>
            </div>
            </v-col>
          </v-row>
          </v-col>
          <v-divider></v-divider>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiFacebook, mdiInstagram, mdiLinkedin, mdiTwitter, mdiWeb, mdiYoutube,
} from '@mdi/js';

export default {
  name: 'PresenterDj.vue',
  module: 'PresenterDj',
  components: {
  },
  computed: {
    ...mapState('presenters', ['presenters']),
  },
  data() {
    return {
      dialog: false,
      mdiFacebook,
      mdiInstagram,
      mdiLinkedin,
      mdiTwitter,
      mdiWeb,
      mdiYoutube,
    };
  },
  mounted() {
    this.LOAD_presenters();
  },
  methods: {
    ...mapActions('presenters', ['LOAD_presenters', 'DELETE_presenter']),
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
.profiles {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
}
.nameEdit {
  font-size: 25px !important;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
}
.profileImage {
  max-height: 250px !important;
  max-width: 200px !important;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
}
.buttonPosition{
  float: right;
}
.buttonSingular {
  margin-left: 15px !important;
}
}
// desktop view
.buttonPosition{
  float: right;
}
.buttonSingular {
  margin-left: 15px !important;
}
.profileImage {
  max-height: 500px;
  max-width: 500px;
}
.nameEdit {
  font-size: 45px;
}
.profileSeparator {
  border-bottom: 1.5px solid black;
}
.profiles {
  padding: 20px;
}
</style>
