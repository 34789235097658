<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-card-title class="headline">{{ formTitle }}</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-row>
            <v-col>
              <v-text-field v-model="form.name" label="Name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DatePicker v-model="form.startDate" label="Start Date" :rules="[rules.required]" />
            </v-col>
            <v-col>
              <TimePicker v-model="form.startTime" label="Start Time" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DatePicker v-model="form.endDate" label="End Date" :rules="[rules.required]" />
            </v-col>
            <v-col>
              <TimePicker v-model="form.endTime" label="End Time" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ImageUpload @input="featuredImageUploaded" />
                <v-img
                :src="form.featuredImageUrl"
                height="200px"
                contain
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="mode === 'Update'" color="error" @click="$emit('remove', form.id)">
            Delete
        </v-btn>
        <v-btn :loading="isSaving" color="primary" @click="submit">
          {{ mode }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import ImageUpload from './ImageUpload.vue';
import loading from '../mixins/loading';
import validation from './validation';

export default {
  name: 'BroadcastScheduleModal',
  components: {
    ImageUpload,
  },
  mixins: [loading, validation],
  props: {
    dialog: Boolean,
    formTitle: String,
    broadcastSchedule: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      form: {
        id: undefined,
        name: '',
        featuredImageUrl: '',
        iconImageUrl: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      },
    };
  },
  computed: {
    mode() {
      return this.broadcastSchedule?.id ? 'Update' : 'Create';
    },
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  watch: {
    // event() {
    //   this.form.id = this.broadcastSchedule?.id ?? undefined;
    //   this.form.name = this.event?.name;
    //   this.form.startDate = this.formatDate(this.event?.start ?? new Date());
    //   this.form.startTime = this.formatDate(this.event?.start ?? new Date());
    //   this.form.endDate = this.formatDate(this.event?.end ?? new Date());
    //   this.form.endTime = this.formatDate(this.event?.end ?? new Date());
    // },
    broadcastSchedule: {
      immediate: true,
      deep: true,
      handler() {
        this.updateLocalBroadcastSchedule();
      },
    },
  },
  mounted() {
    this.updateLocalBroadcastSchedule();
  },
  methods: {
    ...mapActions('broadcastSchedules', ['CREATE_broadcastSchedule', 'UPDATE_broadcastSchedule']),
    updateLocalBroadcastSchedule() {
      this.form.id = this.broadcastSchedule?.id ?? undefined;
      this.form.name = this.broadcastSchedule?.name ?? undefined;
      this.form.featuredImageUrl = this.broadcastSchedule?.featuredImageUrl ?? undefined;
      this.form.startDate = this.formatDate(this.broadcastSchedule?.start ?? new Date());
      this.form.startTime = this.formatTime(this.broadcastSchedule?.start ?? new Date());
      this.form.endDate = this.formatDate(this.broadcastSchedule?.end ?? new Date());
      this.form.endTime = this.formatTime(this.broadcastSchedule?.end ?? new Date());
    },
    formatTime(date) {
      return dayjs(date).format('HH:mm');
    },
    featuredImageUploaded(file) {
      this.form.featuredImageUrl = `${this.baseUrl}/api/gallery/${file.id}`;
    },
    async submit() {
      // validate form data
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        const { form } = this;
        const broadcastSchedule = {
          id: form.id,
          name: form.name,
          featuredImageUrl: form.featuredImageUrl,
          start: new Date(`${form.startDate} ${form.startTime}`),
          end: new Date(`${form.endDate} ${form.endTime}`),
        };
        if (this.form.id) {
        // Update the existing broadcast schedule
          await this.UPDATE_broadcastSchedule(broadcastSchedule);
        } else {
        // Create a new broadcast schedule
          await this.CREATE_broadcastSchedule(broadcastSchedule);
        }
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
