import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';

import appViews from '../views';
import guards from './guards';
import modules from '../modules';

const Init = () => import(/* webpackChunkName: "route-Init" */ '../views/Init.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */ '../views/LoginView.vue');
const Register = () => import(/* webpackChunkName: "route-Register" */ '../views/RegisterView.vue');
const Home = () => import(/* webpackChunkName: "route-Home" */ '../views/Home.vue');
const Article = () => import(/* webpackChunkName: "route-ContactUs" */ '../views/Article.vue');
const Articles = () => import(/* webpackChunkName: "route-ContactUs" */ '../views/Articles.vue');
const Gallery = () => import(/* webpackChunkName: "route-Gallery" */ '../views/Gallery.vue');
const Crew = () => import(/* webpackChunkName: "route-CrewInfo" */ '../views/CrewInfo.vue');
const ContactUs = () => import(/* webpackChunkName: "route-ContactUs" */ '../views/ContactUs.vue');
const FeaturedEvents = () => import(/* webpackChunkName: "route-FeaturedEvents" */ '../views/FeaturedEvents.vue');
const EventImages = () => import(/* webpackChunkName: "route-EventImages" */ '../views/EventImages.vue');
const Profile = () => import(/* webpackChunkName: "route-Profile" */ '../views/Profile.vue');
const EditArticle = () => import(/* webpackChunkName: "route-EditArticle" */ '../views/EditArticle.vue');
const Stream = () => import(/* webpackChunkName: "route-Stream" */ '../views/Stream.vue');
const BroadcastSchedule = () => import(/* webpackChunkName: "route-Stream" */ '../views/BroadcastSchedule.vue');
const EventsSchedule = () => import(/* webpackChunkName: "route-Stream" */ '../views/EventsSchedule.vue');
const AppAdmin = () => import(/* webpackChunkName: "route-AppAdmin" */ '../views/AppAdmin.vue');
const ResetPassword = () => import(/* webpackChunkName: "route-ResetPassword" */ '../views/ResetPassword.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "route-PrivacyPolicy" */ '../views/PrivacyPolicy.vue');
const Terms = () => import(/* webpackChunkName: "route-Terms" */ '../views/Terms.vue');
const PurchaseProduct = () => import(/* webpackChunkName: "route-PurchaseProduct" */ '../views/PurchaseProduct.vue');
const PayPerView = () => import(/* webpackChunkName: "route-PayPerView" */ '../views/PayPerView.vue');
const Playback = () => import(/* webpackChunkName: "route-Playback" */ '../views/Playback.vue');

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter((v) => views[v].name !== 'Home')
    .map((v) => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));
  return viewsArr;
};

const appRoutes = [
  {
    path: '/auth/login',
    alias: '/login',
    name: 'auth-Login',
    component: Login,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/auth/register',
    name: 'auth-Register',
    alias: '/register',
    component: Register,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/home',
    name: 'app-Home',
    component: Home,
  },
  {
    path: '/gallery',
    name: 'app-Gallery',
    component: Gallery,
  },
  {
    path: '/privacy-policy',
    name: 'app-privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-and-conditions',
    name: 'app-terms',
    component: Terms,
  },
  {
    path: '/featured-events',
    name: 'app-FeaturedEvents',
    component: FeaturedEvents,
  },
  {
    path: '/playback',
    name: 'app-Playback',
    component: Playback,
  },
  {
    path: '/events/images',
    name: 'app-EventImages',
    component: EventImages,
  },
  {
    path: '/articles',
    name: 'app-Articles',
    component: Articles,
  },
  {
    path: '/article/edit',
    name: 'app-EditArticle',
    component: EditArticle,
  },
  {
    path: '/article',
    name: 'app-Article',
    component: Article,
  },
  {
    path: '/stream',
    name: 'app-Stream',
    component: Stream,
    meta: {
      hideFooter: true,
    },
  },
  {
    path: '/paid/stream',
    name: 'app-PayPerView',
    component: PayPerView,
  },
  {
    path: '/crew',
    name: 'app-Crew',
    component: Crew,
  },
  {
    path: '/contactUs',
    name: 'app-ContactUs',
    component: ContactUs,
  },
  {
    path: '/broadcastSchedule',
    name: 'app-BroadcastSchedule',
    component: BroadcastSchedule,
  },
  {
    path: '/eventsSchedule',
    name: 'app-EventsSchedule',
    component: EventsSchedule,
  },
  {
    path: '/purchase/product',
    name: 'app-PurchaseProduct',
    component: PurchaseProduct,
  },
  {
    path: '/app-profile',
    name: 'app-Profile',
    component: Profile,
  },
  {
    path: '/admin',
    name: 'app-Admin',
    component: AppAdmin,
  },
  {
    path: '/reset-password',
    name: 'app-ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: appViews.UserManagementHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: appViews.FeedbackItems,
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    alias: '/',
    name: 'app-Welcome',
    component: appViews.Home,
    meta: { requiresAuth: true, requiresOrg: true },
  },
];

const moduleRoutes = modules.map((m) => mapViews(m.config.path, m.views)).flat();
const routes = [...appRoutes, ...moduleRoutes];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(guards);

export { routes };
export { router };
