import { odata } from '../conf';

const Actions = class {
  constructor(name, collection) {
    this[`LOAD_${collection}`] = async ({ commit, rootGetters }) => {
      const data = await odata.o(rootGetters)
        .get(collection)
        .query({ $filter: 'IsDeleted eq false' });
      commit(`SET_${collection}`, data);
    };
    this[`LOAD_${name}`] = async ({ commit, rootGetters }, id) => {
      const i = odata.parseId(id);
      const data = await odata.o(rootGetters)
        .get(`${collection}(${i})`)
        .query();
      commit(`SET_${name}`, data);
      return data;
    };
    this[`SAVE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const result = await odata.o(rootGetters)
        .post(collection, data)
        .query();
      const items = state[collection];
      items.push(result);
      commit(`SET_${collection}`, items);
    };
    this[`CREATE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const result = await odata.o(rootGetters)
        .post(collection, data)
        .query();
      const items = state[collection];
      items.push(result);
      commit(`SET_${collection}`, items);
      return result;
    };
    this[`UPDATE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const i = odata.parseId(data.id);
      await odata.o(rootGetters)
        .patch(`${collection}(${i})`, data)
        .query();

      const items = state[collection];
      if (items?.length > 0) {
        const updatedItem = items.find((l) => l.id === i);
        Object.assign(updatedItem, data);

        commit(`SET_${collection}`, items);
      }
      return data;
    };
    this[`DELETE_${name}`] = async ({ commit, state, rootGetters }, id) => {
      const i = odata.parseId(id);
      await odata.o(rootGetters)
        .delete(`${collection}(${i})`)
        .query();
      const items = state[collection].filter((item) => item.id !== i);
      commit(`SET_${collection}`, items);
    };
  }
};

export default Actions;

// actions[`save.${api.name}`] = async ({ commit, state, rootGetters }, data) => {
//   const result = await odata.o(rootGetters)
//     .post(api.endPoint, data)
//     .query();

//   const items = state[api.endPoint];
//   items.push(result);
//   commit(api.endPoint, items);
//   return result;
// };
// actions[`delete.${api.name}`] = async ({ commit, state, rootGetters }, id) => {
//   const i = odata.parseId(id);
//   await odata.o(rootGetters)
//     .delete(`${api.endPoint}(${i})`)
//     .query();
//   const items = state[api.endPoint].filter((item) => item.id !== i);
//   commit(api.endPoint, items);
// };
// export default actions;
