<template>
  <div>
    <v-row>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar size="48">
              <v-img v-if="userData.imageUrl" :src="userData.imageUrl" />
              <v-icon v-else>{{ mdiAccountBox }}</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar size="48">
                <v-img v-if="userData.imageUrl" :src="userData.imageUrl" />
                <v-icon v-else>{{ mdiAccountBox }}</v-icon>
              </v-avatar>
              <h3>{{ userData.userName }}</h3>
              <p class="text-caption mt-1">
                {{ userData.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <router-link :to="{ name: 'app-Profile' }">
                <v-btn depressed rounded text>
                  Profile Settings
                </v-btn>
              </router-link>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiAccountBox } from '@mdi/js';

export default {
  name: 'ProfileIcon',
  computed: {
    ...mapState('auth', ['userId']),
  },
  data() {
    return {
      userData: {},
      mdiAccountBox,
    };
  },
  async mounted() {
    this.$root.$on('profile-updated', (userData) => {
      this.userData = userData;
    });
    this.$root.$on('auth:login', () => {
      this.updateProfile();
    });
    this.$root.$on('auth:registerComplete', () => {
      this.updateProfile();
    });
    // on page load get user data according to the user id
    const { userId } = this;
    // if user is logged in
    if (userId) {
      await this.updateProfile();
    }
  },
  destroyed() {
    this.$root.$off('profile-updated');
    this.$root.$off('auth:login');
    this.$root.$off('auth:registerComplete');
  },
  methods: {
    // TODO: talk about setting up a global event
    // that triggers to update the profile icon from both register and login
    // update profile
    async updateProfile() {
      const { userId } = this;
      const response = await this.$http.get(`api/account/user/${userId}`);
      this.userData = response.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
