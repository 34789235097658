import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import authHub from '@codehq/aurora-app-core/src/authHub';

export default {
  data() {
    return {
      timer: undefined,
      network: {
        busy: false,
        retries: 0,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isExpired', 'getToken']),
    ...mapState('auth', ['token_expires', 'refresh_token', 'token']),
    ...mapState('organizations', ['organization']),
  },
  methods: {
    ...mapActions('auth', ['INIT_auth', 'LOGOUT_auth', 'REFRESH_auth']),
    async refreshToken() {
      this.$log.info('refreshing auth token');
      // refresh token expired
      await this.REFRESH_auth();
      this.$log.info('refreshed auth token');
    },
  },
  destroyed() {
    this.$root.$off('auth:token');
    this.$root.$off('auth:logoff');
  },
  async created() {
    this.$log.debug('setting up listeners');
    authHub.$on('network:unauthorized', async (n) => {
      this.$log.warn(n, this.network.busy);
      if (!this.network.busy) {
        if (this.network.retries > 3) {
          this.$root.$emit('auth:logoff');
        } else {
          // refresh token
          try {
            this.network.busy = true;
            this.network.retries += 1;
            this.$log.debug(`retries: ${this.network.retries}`);
            await this.REFRESH_auth();
            this.$root.$emit('page.refresh');
          } catch (error) {
            this.$log.error(error);
            setTimeout(() => {
              authHub.$emit('network:unauthorized', {
                source: 'retry',
              });
            }, 2000);
          } finally {
            this.network.busy = false;
          }
        }
      } else {
        this.$log.info('ignoring unauthorized request as the refresh token request is busy');
      }
    });
    this.$root.$on('auth:login', () => {
      this.network.retries = 0;
      this.$log.info('reset network retries');
    });
    this.$root.$on('auth:logoff', () => {
      this.$log.debug('stopping auth timer');
      clearTimeout(this.timer);
    });
    this.$root.$on('auth:logoff', () => {
      this.LOGOUT_auth();
      this.$log.info('logged off');
    });
    this.$log.debug('setting up axios');
    axios.interceptors.request.use((config) => {
      const { organization, token } = this;
      const result = { ...config };
      result.headers.Authorization = `Bearer ${token}`;
      result.headers.organizationid = `${organization?.id}`;
      return config;
    }, (error) => Promise.reject(error));
    axios.interceptors.response.use((response) => response, async (error) => {
      if (error) {
        if (error?.response?.status === 401) {
          authHub.$emit('network:unauthorized', {
            source: 'odata',
            data: error,
          });
        }
      } else {
        this.$log.error('Error not found');
        this.$root.$emit('auth:logoff');
      }
      return Promise.reject(error);
    });
    this.$log.debug('init auth');
    this.INIT_auth({
      url: process.env.VUE_APP_APIURL,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
    this.$log.debug('auth created');
    // this.$telemetry(navigator.userAgent);;
  },
};
