<template>
    <v-dialog v-model="show" fullscreen>
      <v-card>
        <v-card-title>{{ mode }} Event</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submit">
            <v-text-field
              v-model="form.name"
              label="Name"
              required
            ></v-text-field>
            <ImageUpload @input="featuredImageUploaded"/>
            <v-img
              v-if="form.featuredImageUrl && form.featuredImageUrl.length > 0"
              :src="form.featuredImageUrl"
              height="200px"
              contain
            />
            <v-card-actions>
              <v-btn text @click="$emit('close')">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="isSaving" color="primary" type="submit">
                {{ mode }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>

<script>
import { mapActions } from 'vuex';
import ImageUpload from './ImageUpload.vue';
import loading from '../mixins/loading';

/**
   * A Vue component that displays a modal for creating/editing a new event.
   */
export default {
  name: 'EditAlbum',
  mixins: [loading],
  components: {
    ImageUpload,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        featuredImageUrl: '',
      },
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    mode() {
      return this.event.id ? 'Update' : 'Create';
    },
  },
  watch: {
    event: {
      immediate: true,
      deep: true,
      handler() {
        this.form = this.event;
      },
    },
  },
  mounted() {
    this.form = this.event;
  },
  methods: {
    ...mapActions('events', ['CREATE_event', 'UPDATE_event']),
    featuredImageUploaded(file) {
      this.event.featuredImageUrl = `${this.baseUrl}/api/gallery/${file.id}`;
    },
    /**
       * Submit the form data to the backend.
       */
    async submit() {
      // validate form data
      if (this.$refs.form.validate() === false) {
        return;
      }
      this.isSaving = true;
      try {
        if (this.event.id) {
          // Update the existing event
          await this.UPDATE_event(this.event);
        } else {
          // Create a new event
          await this.CREATE_event(this.event);
        }
        this.$refs.form.reset();
        this.$emit('close');
        this.$emit('refresh');
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
