<template>
  <AdminCard label="Manage paid for events">
    <v-data-table
      :headers="headers"
      :items="playbackItems"
      :items-per-page="5"
    >
    <template v-slot:top>
      <v-btn color="primary" @click="create" small>Create PlaybackItem</v-btn>
      <ModalPlaybackItem
        :show="showEditDialog"
        :playbackItem="playbackItem"
        @close="showEditDialog = false"
        @refresh="refresh"
        @submit="savePlaybackItem"
      />
    </template>
      <template v-slot:item.image="{ item }">
        <YouTubeThumbnail :youTubeUrl="item.youTubeUrl" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
        <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
      </template>
    </v-data-table>
  </AdminCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPencil, mdiDelete } from '@mdi/js';
import ModalPlaybackItem from './ModalPlaybackItem.vue';
import YouTubeThumbnail from './YouTubeThumbnail.vue';
import loading from '../mixins/loading';

export default {
  name: 'AdminUsers',
  components: {
    ModalPlaybackItem,
    YouTubeThumbnail,
  },
  mixins: [loading],
  computed: {
    ...mapState('playbackItems', ['playbackItems']),
  },
  data() {
    return {
      headers: [
        { text: '', value: 'image', width: '96px' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'YouTube URL', value: 'youTubeUrl' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      playbackItem: null,
      showEditDialog: false,
      mdiDelete,
      mdiPencil,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('playbackItems', ['CREATE_playbackItem', 'UPDATE_playbackItem', 'LOAD_playbackItems', 'DELETE_playbackItem']),
    create() {
      this.playbackItem = {
        name: '',
        description: '',
        streamUrl: '',
      };
      this.showEditDialog = true;
    },
    /**
      * Refresh the playbackItem list
      */
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_playbackItems();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
      * Edit the playbackItem
      * @param {object} playbackItem - The user object to playbackItem
      */
    edit(playbackItem) {
      this.playbackItem = playbackItem; // Set the playbackItem to the provided playbackItem
      this.showEditDialog = true; // Show the edit dialog
    },

    /**
      * Remove a playbackItem
      * @param {object} playbackItem - The playbackItem object to remove
      */
    async remove(playbackItem) {
      this.isLoading = true;
      const res = await this.$confirm('Are you sure you want to delete this playbackItem?');

      if (res) {
        try {
          await this.DELETE_playbackItem(playbackItem);
          this.refresh();
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    /**
      * Handle playbackItem update
      * Called when the playbackItem is updated in the EditPlaybackItem component
      */
    async savePlaybackItem(playbackItem) {
      this.showEditDialog = false; // Hide the edit dialog
      this.isLoading = true;
      if (playbackItem.id) {
        await this.UPDATE_playbackItem(playbackItem);
      } else {
        await this.CREATE_playbackItem(playbackItem);
      }
      this.refresh(); // Fetch the updated playbackItem data
      this.playbackItem = null; // Clear the playbackItem
      this.isLoading = false;
    },
  },
};
</script>
