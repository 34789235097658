import axios from 'axios';

const actions = {
  async CREATE_presenter({ commit }, presenter) {
    const response = await axios.post('/api/presenters', presenter);
    commit('SET_presenter', response.data);
    return response.data;
  },

  async LOAD_presenter({ commit }, presenterId) {
    const response = await axios.get(`/api/presenters/${presenterId}`);
    commit('SET_presenter', response.data);
    return response.data;
  },

  async LOAD_presenters({ commit }) {
    const response = await axios.get('/api/presenters');
    commit('SET_presenters', response.data);
    return response.data;
  },

  async DELETE_presenter({ commit }, presenterId) {
    const response = await axios.delete(`/api/presenters/${presenterId}`);
    commit('SET_presenter', null);
    return response.data;
  },

  async UPDATE_presenter({ commit }, presenter) {
    const response = await axios.put(`/api/presenters/${presenter.id}`, presenter);
    commit('SET_presenter', response.data);
    return response.data;
  },
};

export default { ...actions };
