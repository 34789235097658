/* eslint-disable max-len */
export default {
  getToken: (state) => state.token,
  getUsername: (state) => state.username,
  isLoggedIn: (state) => state.token?.length > 0,
  isInRole: (state) => (role) => state.roles?.some((r) => r === role),
  // eslint-disable-next-line max-len
  hasClaim: (state) => (type, value) => state.claims?.some((r) => r.type === type && r.value === value),
  hasClaimType: (state) => (type) => state.claims?.some((r) => r.type === type),
  hasAdminClaim: (state, getters) => state.claims?.some((r) => getters.adminClaims.includes(r.type)),
  adminClaims: () => ['users', 'articles'],
  tokenRemaining: (state) => {
    const date1 = new Date(state.expires);
    const date2 = new Date();
    return Math.abs(date2 - date1);
  },
  isExpired: (state, getters) => {
    if (getters.tokenRemaining > 60000) {
      return false;
    }
    return true;
  },
};
