<template>
  <div class="split-layout">
    <div class="left-side">
      <slot name="left"></slot>
    </div>
    <div v-if="rightPanelVisible" class="right-side">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitLayout',
  props: {
    rightPanelVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.split-layout {
  display: flex;
  height: calc(100vh - 64px);
}

.left-side {
  flex: 3; /* 75% of the width */
}

.right-side {
  flex: 1; /* 25% of the width */
}

@media (max-width: 768px) {
  .split-layout {
    flex-direction: column;
    height: calc(100vh - 56px);
  }

  .left-side {
    flex: 1;
  }
  .right-side {
    flex: 2;
    overflow: auto;
  }
}
</style>
