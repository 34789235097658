<template>
  <div class="mainPage">
    <v-container fluid>
      <ArticleSummary :articles="articles" />
      <v-row>
        <v-col cols="12" md="6" lg="4">
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '../mixins/loading';
import ArticleSummary from './ArticleSummary.vue';

export default {
  name: 'Articles',

  /**
   * Child components used by this component.
   * @type {Object}
   */
  components: {
    ArticleSummary,
  },

  /**
   * Mixins used by this component.
   * @type {Array}
   */
  mixins: [loading],

  /**
   * Computed properties used by this component.
   * @type {Object}
   */
  computed: {
    ...mapState('articles', ['articles']),
  },

  /**
   * Called when the component is mounted.
   * @async
   */
  async mounted() {
    await this.refresh();
  },

  /**
   * Methods used by this component.
   * @type {Object}
   */
  methods: {
    ...mapActions('articles', ['LOAD_articles']),

    /**
     * Refreshes the list of articles.
     * @async
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_articles();
      this.isLoading = false;
    },
  },
};
</script>
<style>

.mainPage {
  background-image: url("../assets/ArticleBackground.jpg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
