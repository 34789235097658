export default {
  data() {
    return {
      about: [
        'Let us take you back to a time when music meant more than just notes and beats. When music meant making memories. When music had soul. When music made memories. When music defined us. When music united us.',
        'Music has the power to connect us. And that’s exactly what we at P-Fore are here to do. Connect us to each other, connect us to the past, connect us to our memories.',
        'In the 1970s, nightclubs were the place to be – it’s where deals were done, where friends could meet, where style and fashion were explored and expressed, where bodies writhed and feet stomped to the rhythm of live bands and DJs who carefully curated their music and playlists as meticulously as they applied their hair products.',
        'The likes of D Train, The Crusaders, Earth Wind & Fire, Michael Jackson, Bill Withers, Madonna, Incognito, Grover Washington Jr, and The Whispers shaped our youth, and provided the platform for young South African artists to find their voice and make themselves heard.',
        'The music of our past shapes our future. Our music was political, storied, personal, social, shared, encapsulating our very essence from our choice of clothing to our hairstyles, and even the way we spoke. Music was the base upon which we formed personal connections, helped us move together, laugh together, celebrate together, cry together, mourn together, heal together.',
        'Let us take you back. To Friday nights in the backyard, playing mixed tape cassettes out of boomboxes or blasting from the boot of a car, to the Saturday night clubs where we could experience songs for the first time, ending our night wrapped in each other’s arms slow dancing in the Blues Session, to Sunday afternoons around a braai, sharing stories of the past, reconnecting with our history, making memories.',
        'We at P-Fore have been at the forefront of the music scene in Cape Town; the band culture, bringing you live music that makes you feel alive, banding together, in concert, as one. We are one people; we are your people.',
        'We are part of your fabric. Part of your history. Part of your community. Part of your future.',
        'We bring your memories to life.',
      ],
    };
  },
};
