<template>
<div>
  <v-text-field
    @input="updateValue"
    v-bind="$props"
    v-mask="mask"
    @click:append="datePicker = true"
    :append-icon="mdiCalendar"
  />
    <v-menu
      v-model="datePicker"
      transition="scale-transition"
      offset-y
    >
      <v-date-picker
        v-model="currentValue"
        @input="updateValue"
        scrollable
        no-title
        header-color="primary"
        color="primary"
        :max="maxDate"
      />
    </v-menu>
  </div>
</template>

<script>
/**
 * A Vuetify component that includes a text field for inputting date and time
 * in the format "YYYY-MM-DD HH:MM", and a date picker for selecting values.
 *
 * @component
 * @prop {String} value - The value of the text field.
 * @prop {String} label - The label for the text field.
 * @prop {String} placeholder - The placeholder text for the text field.
 * @prop {Array} rules - An array of validation rules for the text field.
 * @prop {String} maxDate - The maximum date that can be selected in the date picker.
 */

import { VTextField } from 'vuetify/lib';
import { mdiCalendar } from '@mdi/js';

export default {
  name: 'DatePicker',
  extends: VTextField,
  props: {
    placeholder: String,
    rules: Array,
    maxDate: String,
  },
  data() {
    return {
      mdiCalendar,
      datePicker: false,
      currentValue: this.value,
    };
  },
  methods: {
    /**
     * Update the value of the text field and emit an `input` event with the new value.
     *
     * @param {String} newValue - The new value of the text field.
     */
    updateValue(newValue) {
      this.currentValue = newValue;
      this.$emit('input', this.currentValue);
    },
  },
  computed: {
    /**
     * The mask to use for the text field.
     *
     * @returns {String} The mask string.
     */
    mask() {
      return '####-##-##';
    },
  },
};
</script>
