<template>
  <v-card :loading="isLoading" class="h-100">
    <v-card-title>
      <slot name="title" />
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AdminCard',

  props: {
    /**
      * The label for the card to be used in the sub title.
     */
    label: {
      type: String,
      required: true,
    },
    /**
      * Whether or not the card is loading.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
