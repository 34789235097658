<template>
  <v-row class="mt-3" justify="center" no-gutters>
    <v-col cols="12">
      <v-card flat>
        <v-card-text>
          <Login>
            <template v-slot:forgotPassword>
              <ForgotPasswordModal
                :label="$t('common.forgotPasswordHeading')"
              />
            </template>
          </Login>
        </v-card-text>
        <v-card-text>
          <v-container>
              <v-row class="mt-0" justify="center">
                <h4 class="mb-5" justify="center">
                  <span>{{ $t('login.or') }}</span>
                </h4>
              </v-row>
              <v-row class="mt-0" justify="center">
                <v-btn text class="title" :to="{ name: 'auth-Register' }">
                  {{ $t('login.registerAccount') }}
                </v-btn>
              </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js';
import Login from '../components/Login.vue';
import ForgotPasswordModal from '../components/ForgotPasswordModal.vue';

export default {
  name: 'LoginView',
  components: {
    ForgotPasswordModal,
    Login,
  },
  data() {
    return {
      mdiOpenInNew,
    };
  },
};
</script>
