import axios from 'axios';

const actions = {
  async LOAD_mySubscription({ commit }) {
    const response = await axios.get('/api/subscriptions/my');
    commit('SET_subscription', response.data);
    return response.data;
  },

  async LOAD_subscriptions({ commit }) {
    const response = await axios.get('/api/subscriptions');
    commit('SET_subscriptions', response.data);
    return response.data;
  },
  async UPDATE_subscription({ commit }, subscription) {
    const response = await axios.put(`/api/subscriptions/${subscription.id}`, subscription);
    commit('SET_subscription', null);
    return response.data;
  },
  async DELETE_subscription({ commit }, subscriptionId) {
    const response = await axios.delete(`/api/subscriptions/${subscriptionId}`);
    commit('SET_subscription', null);
    return response.data;
  },
};

export default { ...actions };
