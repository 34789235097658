<template>
  <v-container>
    <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
    <v-row v-else-if="products.length === 0">
      <v-col cols="12">
        <h1>No products available</h1>
      </v-col>
    </v-row>
    <v-card v-for="product in products" :key="product.id">
      <v-card-text>
        <v-row>
          <v-col cols="12">
              <h1>{{ product.name }} - R{{ formatNumber(product.price) }}</h1>
              <h3>Starting at {{ product.start }}</h3>
          </v-col>
            <v-col cols="12">
              <h4>{{ product.description }}</h4>
            </v-col>
            <v-img
              v-if="product.image"
              :src="product.image"
              contain
              max-height="512"
              class="w-full"
            />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="buy(product)" :loading="isSaving">
          Buy Now
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '../mixins/loading';

export default {
  name: 'PurchaseProduct',
  module: 'Products',
  mixins: [loading],
  computed: {
    ...mapState('products', ['products']),
    ...mapState('auth', ['userId']),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('products', ['LOAD_products']),
    ...mapActions('purchases', ['CREATE_purchase']),
    async buy(product) {
      this.isSaving = true;
      try {
        const { userId } = this;
        await this.CREATE_purchase({ productId: product.id, userId });
        this.$router.push({ name: 'app-Profile', query: { tab: 2 } });
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isSaving = false;
      }
    },
    /**
      * Refresh the product list
      */
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_products();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.containerImage {
  object-fit: fill;
  height: 100%;
  background-image: url('../assets/ContactUsPic2.jpg') !important;
  background-size: 100% 100%;
}
@media screen and (max-width: 600px) {
  .form {
  display: block;
  margin: 0 auto;
  max-width: 400px;
  }
  .containerImage {
    min-width: 400px;
    min-height: 650px;
  }
}
</style>
