export default {
  currentAndNextBroadcastSchedule: (state) => {
    // get the current time
    const now = new Date();

    // filter out broadcasts that have already ended and sort by start time in ascending order
    const upcomingBroadcasts = state.broadcastSchedules
      .filter((broadcast) => new Date(broadcast.start) > now)
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    return upcomingBroadcasts;
  },
};
