<template>
  <div>
    <v-btn icon v-if="!$vuetify.theme.dark" @click="toggleTheme()">
      <v-icon class="mr-1" color="primary">{{ mdiLightbulb }}</v-icon>
    </v-btn>
    <v-btn icon v-if="$vuetify.theme.dark" @click="toggleTheme()">
      <v-icon color="primary">{{ mdiLightbulbOutline }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiLightbulb, mdiLightbulbOutline } from '@mdi/js';

export default {
  name: 'ThemeDarkLightSwitch',
  data() {
    return {
      mdiLightbulb,
      mdiLightbulbOutline,
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
