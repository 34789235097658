<template>
    <div class="galleryContainer" :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
      <div
        class="eventContainer mr-5 mb-5"
        v-for="event in events"
        :key="event.id"
      >
        <v-card min-height="250px" width="300px" class="card1">
            <router-link :to="{ name: 'app-EventImages', query: { eventId: event.id } }">
              <v-img
              :src="event.featuredImageUrl"
              height="200px"
              width="300px"
              stretch
              position="top center"
              >
              </v-img>
            </router-link>
            <v-card-title class="cardText text-truncate">{{ event.name }}</v-card-title>
          </v-card>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlusCircle } from '@mdi/js';
import loading from '../mixins/loading';

export default {
  name: 'FeaturedEvents',

  mixins: [loading],

  data() {
    return {
      /**
      * Icon for adding a new event
      *
      * @type {string}
      */
      mdiPlusCircle,

      /**
      * Flag to control whether the overlay is visible
      *
      * @type {boolean}
      */
      overlay: false,

      /**
      * Flag to control whether the dialog is visible
      *
      * @type {boolean}
      */
      dialog: false,
    };
  },

  computed: {
    ...mapState('events', {
      /**
      * List of events
      *
      * @type {Array}
      */
      events: (state) => state.events,
    }),
  },

  async mounted() {
    /**
    * Refreshes the list of events on mount
    */
    await this.refresh();
  },

  methods: {
    ...mapActions('events', ['LOAD_events', 'CREATE_event']),

    /**
    * Closes the dialog
    *
    * @function
    */
    closeDialog() {
      this.dialog = false;
    },
    /**
    * Loads the list of events and sets the isLoading flag to true while loading
    *
    * @function
    */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_events();
      this.isLoading = false;
    },

    /**
    * Saves a new event and sets the isSaving flag to true while saving
    *
    * @function
    * @param {Object} form - Data of the new event
    */
    async saveEvent(form) {
      this.isSaving = true;
      await this.CREATE_event(form);
      this.isSaving = false;
      /**
      * Adds the new event to the events array
      */
      this.events.push(form);

      /**
      * Closes the dialog
      */
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.event-page {
  background-image: url("../assets/image-38-copyright-min.jpg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0;
  padding: 0;

}
.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  padding: 12px;
}
.newEventButton {
  display: flex;
  margin: 5px;
  padding: 12px;
}

.cardText {
  font-size: 1.25em;
  color: #000000;
  font-weight: bold;
}
</style>
