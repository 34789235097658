<template>
  <v-card flat :loading="isLoading">
    <v-card-title v-if="subscription">
      <v-icon class="mr-2" :color="statusColor">{{ statusIcon }}</v-icon>
      Subscription:
      {{ getStatus(subscription.status) }}
    </v-card-title>
    <v-card-text>
      <v-list v-if="subscription">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle>{{ getStatus(subscription.status) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Trial Expiration Date</v-list-item-title>
            <v-list-item-subtitle>
              {{ formatDateTime(subscription.trialExpirationDate) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Last Payment Date</v-list-item-title>
            <v-list-item-subtitle>
              {{ subscription.lastPaymentDate ?? 'No payments yet.' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Last Payment Amount</v-list-item-title>
            <v-list-item-subtitle>
              {{ subscription.lastPaymentAmount ?? 'R 0.00' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-card-text>
    <v-card-actions v-if="subscription && subscription.status > 0">
      <v-btn color="error" @click="cancelSubscription">Cancel Subscription</v-btn>
    </v-card-actions>
    <v-card-actions v-if="subscription">
      <form :action="paymentUrl" method="POST" >
        <input type="hidden" name="MerchantID" :value="merchantKey">
        <input type="hidden" name="TransactionType" value="Payment">
        <input type="hidden" name="MerchantReference" :value="subscription.id">
        <input type="hidden" name="Amount" value="30">
        <input type="hidden" name="Currency" value="ZAR">
        <input type="hidden" name="Checksum" :value="checksum">
        <v-btn color="success" type="submit">Subscribe (R30 / month)</v-btn>
      </form>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import {
  mdiTimerSand,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiCancel,
  mdiInformation,
} from '@mdi/js';

export default {
  name: 'Subscription',
  mixins: [loading],
  data() {
    return {
      trialLength: 30, // Example data, you can fetch this from an API or store
      lastPaymentAmount: 9.99, // Example data
      renewalDate: '2023-09-24', // Example data
      checksum: 'TESTCHECK',
    };
  },
  computed: {
    ...mapState('subscriptions', ['subscription']),
    merchantKey() {
      return process.env.VUE_APP_ECENTRIC_KEY;
    },
    paymentUrl() {
      return process.env.VUE_APP_ECENTRIC_URL;
    },
    statusIcon() {
      switch (this.subscription.status) {
        case 0: return mdiTimerSand;
        case 1: return mdiCheckCircle;
        case 2: return mdiAlertCircle;
        case 3: return mdiCancel;
        default: return mdiInformation;
      }
    },
    statusColor() {
      switch (this.subscription.status) {
        case 0: return 'blue';
        case 1: return 'green';
        case 2: return 'orange';
        case 3: return 'red';
        default: return 'grey';
      }
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('subscriptions', ['LOAD_mySubscription']),
    cancelSubscription() {
      // Logic to cancel the subscription goes here
      // For example, make an API call to your backend to cancel the subscription
    },
    getStatus(status) {
      switch (status) {
        case 0: return `Trial (${this.trialLength} days)`;
        case 1: return `Subscribed (Renews on ${this.renewalDate})`;
        case 2: return `Payment Due (Last payment: $${this.lastPaymentAmount})`;
        case 3: return 'Cancelled';
        default: return 'Unknown'; // This should never happen
      }
    },
    async refresh() {
      try {
        this.isLoading = true;
        await this.LOAD_mySubscription();
        const { data } = this.$http.post('/api/subscriptions/checksum', {
          merchantID: this.merchantKey,
          transactionType: 'Payment',
          merchantReference: this.subscription.id,
          amount: 30,
        });
        this.checksum = data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$root.$emit('toast:error', 'Error loading subscription');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* You can add any additional styles here */
</style>
