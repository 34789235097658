<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title>{{ mode }} Product</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="form.name"
            label="Name"
            required
          ></v-text-field>
          <v-textarea
            v-model="form.description"
            label="Description"
            required
          ></v-textarea>
          <v-text-field
            v-model="form.price"
            label="price"
            type="number"
            required
          >
            <template v-slot:prepend>
              R
            </template>
          </v-text-field>

          <DatePicker v-model="form.start" label="Start Date" />

          <DatePicker v-model="form.end" label="End Date" />
          <v-text-field v-model="form.streamUrl" label="Stream Url" required></v-text-field>
          <v-img
            v-if="form.image && form.image?.length > 0"
            :src="form.image"
            height="200px"
            contain
          />
          <ImageUpload @input="featuredImageUploaded"/>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isSaving" color="primary" type="submit">
              {{ mode }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageUpload from './ImageUpload.vue';
import loading from '../mixins/loading';
import validation from './validation';

/**
 * A Vue component that displays a modal for creating/editing a new product.
 */
export default {
  name: 'ModalProduct',
  mixins: [loading, validation],
  components: {
    ImageUpload,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        image: '',
        streamUrl: '',
        start: undefined,
        end: undefined,
      },
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    mode() {
      return this.product?.id ? 'Update' : 'Create';
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler() {
        this.form = this.product ?? {};
      },
    },
  },
  mounted() {
    this.form = this.product ?? {};
  },
  methods: {
    featuredImageUploaded(file) {
      this.product.image = `${this.baseUrl}/api/gallery/${file.id}`;
    },
    /**
     * Submit the form data to the backend.
     */
    async submit() {
      this.isSaving = true;
      this.$emit('submit', this.form);
      this.$refs.form.reset();
      this.isSaving = false;
    },
  },
};
</script>
