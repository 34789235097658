<template>
  <AdminCard label="Manage the schedules for broadcasts" :isLoading="isLoading">
    <BroadcastCalendar />
    <template v-slot:title>
      <v-btn small :href="url" class="mr-2">Download</v-btn>
      <UploadSchedule @uploaded="LOAD_broadcastSchedules" />
      <v-btn small @click="duplicate" class="ml-2">Duplicate</v-btn>
    </template>
  </AdminCard>
</template>

<script>
/**
 * The AdminBroadcastSchedulesCard component.
 * @module AdminBroadcastSchedulesCard
 */

import { mapActions, mapGetters, mapState } from 'vuex';
import {
  mdiPencil,
  mdiDelete,
} from '@mdi/js';
import dayjs from 'dayjs';
import axios from 'axios';
import BroadcastCalendar from './BroadcastCalendar.vue';
import UploadSchedule from './UploadSchedule.vue';
import loading from '../mixins/loading';

export default {
  name: 'AdminBroadcastSchedulesCard',
  components: {
    BroadcastCalendar,
    UploadSchedule,
  },
  mixins: [loading],
  computed: {
    ...mapState('broadcastSchedules', ['broadcastSchedules']),
    ...mapGetters('broadcastSchedules', ['currentAndNextBroadcastSchedule']),
    currentBroadcast() {
      if (this.currentAndNextBroadcastSchedule.length === 0) return null;
      return this.currentAndNextBroadcastSchedule[0];
    },
    nextBroadcast() {
      if (this.currentAndNextBroadcastSchedule.length < 1) return null;
      return this.currentAndNextBroadcastSchedule[1];
    },
    url() {
      const startOfTheWeek = dayjs().startOf('week').format('YYYY-MM-DD');
      const endOfTheWeek = dayjs().endOf('week').format('YYYY-MM-DD');
      return `${process.env.VUE_APP_APIURL}/api/BroadcastSchedules/download?start=${startOfTheWeek}&end=${endOfTheWeek}`;
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          width: '15%',
          divider: true,
        },
        {
          text: 'Broadcast Start',
          value: 'start',
          sortable: false,
          width: '20%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: 'Broadcast End',
          value: 'end',
          sortable: false,
          width: '20%',
          align: 'center',
          filterable: false,
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '10%',
          align: 'right',
          filterable: false,
          divider: true,
        },
      ],
      showPresenterModal: false,
      search: '',
      broadcastSchedule: {},
      mdiPencil,
      mdiDelete,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_broadcastSchedules();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('broadcastSchedules', ['LOAD_broadcastSchedules']),
    create() {
      this.broadcastSchedule = {
        name: '',
        broadCastStart: '',
        broadCastEnd: '',
      };
      this.showPresenterModal = true;
    },
    async duplicate() {
      const res = await this.$confirm('This will duplicate the current week\'s schedule. Are you sure?');
      if (!res) return;
      this.isLoading = true;
      try {
        const startOfTheWeek = dayjs().startOf('week').format('YYYY-MM-DD');
        const endOfTheWeek = dayjs().endOf('week').format('YYYY-MM-DD');
        await axios.post(`${process.env.VUE_APP_APIURL}/api/BroadcastSchedules/duplicate?start=${startOfTheWeek}&end=${endOfTheWeek}`);
        this.refresh();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
    edit(broadcastSchedule) {
      this.broadcastSchedule = broadcastSchedule;
      this.showPresenterModal = true;
    },
    async remove() {
      return true;
    },
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_broadcastSchedules();
      } catch (error) {
        this.$root.$emit('network.error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
