<template>
    <div>
      <EventsCalendar/>
    </div>
</template>

<script>
import EventsCalendar from '../components/EventsCalendar.vue';

export default {
  name: 'EventsSchedule',
  module: 'EventsSchedule',
  components: {
    EventsCalendar,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
