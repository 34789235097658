<template>
  <observer @on-change="onChange">
      <section :class="{ 'show': visible, 'hidden': !visible }">
        <slot />
      </section>
    </observer>
</template>

<script>
import Observer from 'vue-intersection-observer';

export default {
  name: 'ObserverSection',
  components: {
    Observer,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onChange(entry, obv) {
      this.visible = entry.isIntersecting;
    },
  },
};
</script>
