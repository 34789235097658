<template>
  <component :is="resolveLayout">
    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="error" />
    <BasicToasts />
  </component>
</template>
<script>
import { mapActions } from 'vuex';
import { components as auroraAppCore, authHub, errorHub } from '@codehq/aurora-app-core/src';
import Blank from './layouts/Blank.vue';
import Content from './layouts/Content.vue';

const {
  BasicToasts, Toasts,
} = auroraAppCore;

export default {
  name: 'App',
  components: {
    Blank,
    Content,
    BasicToasts,
    Toasts,
  },
  data() {
    return {
      message: '',
      error: '',
    };
  },
  computed: {
    resolveLayout() {
      if (this.$route.meta?.blankLayout) {
        this.$log.info('loading empty layout');
        return 'Blank';
      }
      return 'Content';
    },
  },
  created() {
    this.$log.info('Setting up event handlers');
    this.$root.$on('organizations.loaded', (organizations) => {
      if (organizations.length === 0 && this.isLoggedIn) {
        this.$router.push({
          name: 'app-Onboard',
        });
      }
    });
    errorHub.$on('network.error', () => {
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', () => {
      this.LOGOUT_auth();
      this.$router.push('/auth/login');
    });
    this.$root.$on('toast:error', (err) => {
      this.error = '';
      this.error = err;
    });
    this.$root.$on('auth:login', () => {
      this.$log.info('user logged in');
      this.$router.push({ name: 'app-Home' });
      this.$root.$emit('toast:notify', 'Logged in successfully');
    });
    this.$root.$on('auth:logoff', () => {
      this.$router.push('/auth/login');
      this.LOGOUT_auth();
      this.$root.$emit('toast:notify', 'Logged out successfully');
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    this.$root.$off('auth:login');
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth']),
  },
};
</script>
