<template>
  <SplitLayout>
    <template v-slot:left>
      <div style="background-color: purple; height: 100%;" />
    </template>
    <template v-slot:right>
      <div v-for="index in 100" :key="index" class="lorem-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Sed euismod, nisl id consectetur tincidunt, nunc nunc ultrices nunc,
      </div>
    </template>
  </SplitLayout>
</template>

<script>
import SplitLayout from '../layouts/SplitLayout.vue';

export default {
  name: 'StreamNew',
  components: {
    SplitLayout,
  },
};
</script>
