<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      dark
      color="black"
      :expand-on-hover="isDesktop"
      :mini-variant="isDesktop"
      app
    >
      <v-divider />
      <MenuDrawer />
      <v-divider />
      <v-spacer></v-spacer>
    </v-navigation-drawer>
    <v-app-bar
      app
      align-start
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="primary"
      />
      <div v-if="isDesktop">
        <v-btn
          v-for="(item, index) in topMenuItems"
          @click="menuClick(item)"
          :class="getMenuClass(item, index)"
          :key="index"
          text
          color="primary"
          :to="{ name: item.pathName }"
        >
          <v-icon class="mr-1">{{ item.icon }}</v-icon>
          {{ $t(item.name) }}
        </v-btn>
      </div>
      <div class="mr-2" />
      <Player />
      <v-spacer></v-spacer>
      <ProfileIcon v-if="isLoggedIn" class="mr-4"/>
      <router-link class="slide-in-top delay-1" :to="{ name: 'app-Home' }">
        <v-img
        src="../assets/logo.png" contain :height="100" :width="100" alt="logo"
        />
      </router-link>
      <Feedback v-if="isLoggedIn" />
      <ThemeDarkLightSwitch />
      <!-- <OrganizationMenu v-if="organization" /> -->
      <v-menu v-if="isInRole('Administrators')" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" text outlined v-bind="attrs" v-on="on">
            <v-icon color="primary">{{ mdiCog }}</v-icon>
          </v-btn>
        </template>
        <AdminMenuDrawer />
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view>
      </router-view>
      <InstallModal />
    </v-main>
    <v-footer v-if="!hideFooter" small class="d-flex justify-end">
        <v-btn text small :to="{ name: 'app-privacyPolicy' }">
          Privacy Policy
        </v-btn>
    </v-footer>
    <slot></slot>
  </v-app>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiHome, mdiCog, mdiPuzzleOutline } from '@mdi/js';
import Account from '../components/Account.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue';
import Feedback from '../components/Feedback.vue';
import MenuDrawer from '../components/MenuDrawer.vue';
import InstallModal from '../components/InstallModal.vue';
import AdminMenuDrawer from '../components/AdminMenuDrawer.vue';
import HelpButton from '../components/HelpButton.vue';
import Player from '../components/Player.vue';
import ThemeDarkLightSwitch from '../components/ThemeDarkLightSwitch.vue';
import ProfileIcon from '../components/ProfileIcon.vue';
// import OrganizationMenu from './components/OrganizationMenu.vue';
import menuItems from '../menuItems';
import '../site.scss';

export default {
  name: 'App',
  components: {
    InstallModal,
    // eslint-disable-next-line vue/no-unused-components
    Account,
    AdminMenuDrawer,
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumbs,
    Feedback,
    // eslint-disable-next-line vue/no-unused-components
    HelpButton,
    MenuDrawer,
    // OrganizationMenu,
    // OrganizationSelect,
    // eslint-disable-next-line vue/no-unused-components
    Player,
    ThemeDarkLightSwitch,
    ProfileIcon,
  },
  mixins: [menuItems],
  data() {
    return {
      drawer: true,
      mdiHome,
      mdiCog,
      mdiPuzzleOutline,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('auth', ['token']),
    ...mapState('organizations', ['organization']),
    appVersion() {
      return process.env.PACKAGE_VERSION.version;
    },
    hideFooter() {
      return this.$route.meta?.hideFooter;
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
    topMenuItems() {
      return this.menuItems.filter((m) => m.topMenu === true);
    },
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth']),
    ...mapMutations('organizations', ['SET_organization']),
    getMenuClass(item, index) {
      if (this.isDesktop) {
        return `slide-in-top delay-${index + 1}`;
      }
      return '';
    },
    menuClick(item) {
      if (item.cmd) {
        item.cmd();
      }
    },
    logout() {
      this.LOGOUT_auth();
      this.$router.push('/auth/login');
    },
  },
};
</script>
<style>
html, body, #app {
  height: 100%;
  margin: 0;
}

.v-application {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.v-main {
  flex-grow: 1;
}
</style>
