<template>
  <v-app>
    <v-main>
        <v-container class="fill-height" fluid>
          <v-img
            v-if="$vuetify.theme.dark"
            contain
            src="../assets/logo.png"
            class="ml-auto mr-auto"
            width="229"
          />
          <v-img
            v-else
            contain
            src="../assets/logo-light.png"
            height="229"
            width="229"
            class="ml-auto mr-auto"
          />
          <v-row class="fill-height" align="center" justify="center">
            <v-col class="fill-height d-flex flex-column align-stretch" cols="12" md="4">
              <router-view/>
            </v-col>
          </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import '../site.scss';

export default {
  name: 'Blank',
};
</script>
