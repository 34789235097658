import axios from 'axios';

const customActions = {
  // eslint-disable-next-line no-unused-vars
  async LOAD_users({ commit }) {
    const { data } = await axios.get('api/users/manage/all');
    commit('SET_users', data);
    return data;
  },

  async DELETE_user({ dispatch }, userId) {
    await axios.delete(`api/users/manage/${userId}`);
    dispatch('LOAD_users');
  },

  async LOAD_claims({ commit }) {
    const { data } = await axios.get('api/users/manage/claims');
    commit('SET_claims', data);
    return data;
  },

  async CREATE_claim({ dispatch }, claim) {
    const { data } = await axios.post('api/users/manage/claims/add', claim);
    dispatch('LOAD_users');
    return data;
  },

  async DELETE_claim({ dispatch }, claim) {
    const { data } = await axios.delete(`api/users/manage/claims/remove/${claim.id}`);
    dispatch('LOAD_users');
    return data;
  },
};

export default { ...customActions };
