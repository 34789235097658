<template>
  <v-btn
    class="mx-2"
    fab
    x-small
    @click="$emit('click')"
    :color="color">
    <v-icon color="white">{{ mdiHeart }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiHeart } from '@mdi/js';

export default {
  name: 'LikeButton',
  data() {
    return {
      mdiHeart,
    };
  },
  props: {
    likes: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    color() {
      return this.likes > 0 ? 'red' : 'grey';
    },
  },
};
</script>
