<template>
  <v-form
    v-model="valid"
    class="w-full d-flex flex-column align-center mb-2"
    data-cy="form-chat-registration"
    @submit.prevent="register" ref="form"
  >
    <v-text-field
      v-model="displayName"
      data-cy="input-display-name"
      label="Your name"
      placeholder="Your real name how people will see you"
      :rules="[rules.required]"
      class="w-full"
      required
    />
    <v-text-field
      v-model="username"
      data-cy="input-username"
      label="Your username for mentions and likes"
      :rules="[rules.required]"
      class="w-full"
      required
    />
    <v-alert type="error" v-if="usernameError">{{ usernameError }}</v-alert>
    <v-btn
      :loading="isSaving"
      type="submit"
      color="primary"
      block
    >
      Start
    </v-btn>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import validation from '@codehq/aurora-app-core/src/components/validation';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ChatRegistration',
  mixins: [loading, validation],
  data() {
    return {
      displayName: '',
      username: '',
      valid: false,
      usernameError: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  mounted() {
    this.displayName = this.user.displayName ?? '';
    this.username = this.user.chatUsername ?? '';
  },
  methods: {
    ...mapActions('auth', ['CHECK_chatUsername', 'UPDATE_user']),
    async register() {
      this.$refs.form.validate();
      if (!this.valid) return;

      try {
        this.isSaving = true;
        this.usernameError = null;
        const validUsername = await this.CHECK_chatUsername(this.username);
        if (!validUsername) {
          this.usernameError = 'Username is already taken';
        } else {
          const { user } = this;
          user.chatUsername = this.username;
          user.displayName = this.displayName;
          await this.UPDATE_user(user);
        }
      } catch (error) {
        this.usernameError = 'Username is already taken';
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
